import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AiOutlineBank, AiOutlineClose } from 'react-icons/ai'
import { FaWhatsapp } from 'react-icons/fa'
import { IoMdWallet } from 'react-icons/io'
import { IoArrowBackOutline } from 'react-icons/io5'
import { MdCall } from 'react-icons/md'
import { userActions } from '../../_actions'
import { users } from '../../_reducers/users.reducer'
import Loader from '../../components/Loader/Loader'
import RuleModal from './RuleModal'

function Withdraw() {
    const selector = useSelector(state => state)
    const dispatch = useDispatch()
    const [amount, setCashValue] = useState('')
    const [error, setError] = useState({});
    const { imgUrl, loading } = users || {};
    const [rulesModal, setRulesModal] = useState(true)

    useEffect(() => {
        dispatch(userActions.getProfile())
    }, [])

    const handleValid = () => {
        let isValid = true;
        let error = {};
        if (!amount) {
            isValid = false;
            error.amount = "amount is required";
        }
        if (amount && amount.includes('.')) {
            isValid = false;
            error.amount = "Decimal is not allowed";
        }
        setError(error);
        return isValid;
    };
    const handleCashAdd = (e) => {
        e.preventDefault();
        if (handleValid()) {
            const data = {
                amount,
                parentId: selector?.users?.getProfile?.parentId || null,
            };
            dispatch(userActions.withdrawPaymentRequest(data));
            setCashValue('');
        }
    }
    const handleBack = () => {
        window.history.back()
    }
    const handleCloseModal = () => {
        setRulesModal(false);
    };
    return (
        <div>
            <Loader loading={selector?.users?.loading} />
            {rulesModal && (
                <>
                    {/* Blurred background */}
                    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm z-40"></div>

                    {/* Modal */}
                    <div className="fixed inset-0 z-50 flex items-center justify-center">
                        <div className="relative bg-white rounded-lg shadow-lg p-6 lg:w-[20%] max-w-md">
                       
                            <button
                                className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
                                onClick={handleCloseModal}
                            >
                                <AiOutlineClose className="text-2xl" />
                            </button>
                            <RuleModal />
                        </div>
                    </div>
                </>
            )}

            <div className='bg-white text-black font-semibold'>
                <div className='h-12 w-full border bg-[#024CAA] rounded-b-3xl flex justify-between place-items-center px-4 gap-3'>
                    <div className='flex justify-start place-items-center gap-2'>
                        <IoArrowBackOutline className='text-white mb-1 text-2xl cursor-pointer' onClick={handleBack} />
                        <div className='  font-semibold text-white cursor-pointer' >Withdraw Funds</div>
                    </div>
                    <div className='pb-1 flex justify-start place-items-center text-white gap-1'>
                        <IoMdWallet className='text-white text-lg' /> {selector?.users?.getProfile?.wallet ? selector?.users?.getProfile?.wallet.toFixed(2) : '-'}
                    </div>
                </div>
                <div className='h-screen'>
                    <div className="flex justify-center mt-10  ">
                        <div className="w-full max-w-sm p-5 border bg-white rounded-lg shadow-md">
                            <div className="  rounded-lg mb-4 shadow-lg py-4 border">
                                <div className="text-center mb-2 bg-black text-white py-1">
                                    <div className="text-sm  font-medium capitalize">{selector?.users?.getProfile?.userName ? selector?.users?.getProfile?.userName : '-'}</div>
                                    <div className="text-xs ">{
                                        selector?.users?.getProfile?.mobNo ? selector?.users?.getProfile?.mobNo : '-'}</div>
                                </div>
                                <div className="flex justify-start items-center bg-white p-3 rounded-lg ">
                                    <div className="flex items-center">
                                        <IoMdWallet className='text-[#C11C84] text-5xl' />
                                        <div className="ml-3 text-left">
                                            <div className=" text-[#800048]">₹ {selector?.users?.getProfile?.wallet ? selector?.users?.getProfile?.wallet.toFixed(2) : '-'}</div>
                                            <div className="text-sm text-gray-500 font-normal">Current Balance</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center items-center text-center text-sm  mb-4 border-t-2 border-black ">
                                <span className='pt-1'>For Fund Query's please Call Or Whatsapp</span>
                            </div>
                            <div className="flex justify-center place-items-center gap-2 mb-4 border-b-2 border-black pb-2">
                                <button className="flex items-center px-3 py-1  rounded-full gap-1 shadow-md text-black border">
                                    <FaWhatsapp className='text-lg' />

                                    Whatsapp
                                </button>
                                <button className="flex items-center px-6 py-1 shadow-md gap-1 rounded-full text-black border">
                                    <MdCall className='text-lg' />

                                    Call
                                </button>
                            </div>
                            <div className="relative mb-4">
                                <input
                                    type="number"
                                    placeholder="Enter Amount"
                                    value={amount}
                                    onChange={(e) => {
                                        setCashValue(e.target.value);

                                    }}

                                    className="w-full px-12 py-2 border border-gray-300 rounded-full focus:outline-none focus:border-[#800048]"
                                />
                                <div className='absolute top-1 left-3 bg-[#024CAA] rounded-full h-8 w-8 flex justify-center place-items-center text-center'>
                                    <AiOutlineBank className='text-xl  text-white ' />
                                </div>
                                {error.amount && <div className='text-red-500 capitalize text-xs font-normal pb-2'>{error.amount}</div>}


                            </div>
                            <div className='flex justify-center place-items-center'>
                                <button className=" py-1 px-3 font-semibold text-white bg-[#024CAA] rounded-full hover:bg-[#a3005e]" onClick={handleCashAdd}>Withdraw Fund</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Withdraw
