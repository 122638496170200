import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { userService } from '../../_services';
import { IoArrowBackOutline } from 'react-icons/io5'
import { gameResultAction } from '../../_actions';

function GameResult() {
    const selector = useSelector(state => state)
    const dispatch = useDispatch()
    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);
    const dateString = `${year}-${month}-${day}`;
    const [selectedDate, setSelectedDate] = useState(dateString);

    useEffect(() => {
        let data = {
            filterDate: dateString
        }
        dispatch(gameResultAction.gameResults(data))
    }, [dateString])

    const handleDateChange = (event) => {
        const selectedDate = event.target.value; 
        setSelectedDate(selectedDate);
        let data = {
            filterDate: selectedDate,
            JodiGame: '',
            brandGame: ''
        }
        dispatch(gameResultAction.gameResults(data))
    };

    const goBack = () => {
        window.history.back()
    }


    const formatJodiNumber = (jodiNumber) => {
        if (jodiNumber && jodiNumber.length === 2) {
            return jodiNumber.split('').join(' ');
        } else if (jodiNumber && jodiNumber.length === 1) {
            return `${jodiNumber} *`;
        } else {
            return '* *';
        }
    };


    return (
        <div className='bg-white text-black min-h-screen'>
            <div className='h-12 w-full border bg-[#024CAA] rounded-b-3xl flex justify-start place-items-center px-4 gap-3'>
                <IoArrowBackOutline className='text-white mb-1 text-2xl cursor-pointer' onClick={goBack} />
                <div className='  font-semibold text-white cursor-pointer uppercase' > Market Result History</div>
            </div>
            <div className='flex justify-between place-items-center  p-3 text-sm'>
                <p>Select Date</p>
                <div>
                    <input type='date' value={selectedDate} onChange={handleDateChange} className='bg-white px-3 py-1 uppercase rounded-full shadow' />
                </div>
            </div>
            <div>
                <div className='data p-3 h-screen overflow-y-auto'>
                    {selector?.gameOfResult?.gameResultData?.gameResults?.data && selector?.gameOfResult?.gameResultData?.gameResults?.data.length > 0 ? selector?.gameOfResult?.gameResultData?.gameResults?.data.map((ele, id) => (
                        <div key={id} className="border p-4 mb-4 rounded-lg shadow-md flex justify-between place-items-center">
                            <h3 className=" font-semibold text-[#C11C84] mb-2 capitalize">{ele.gameId.name}</h3>
                            <div>

                                {ele.openNumber || "***"}-{formatJodiNumber(ele?.jodiNumber)}-{ele.closeNumber || '***'}
                            </div>

                        </div>
                    )) : (<div>No Data Found</div>)}
                </div>
            </div>
        </div>
    )
}

export default GameResult
