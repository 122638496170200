import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
import { useHistory } from 'react-router-dom';
import { IoArrowBackOutline, IoCaretBackCircleSharp } from "react-icons/io5";
import { BsKeyFill } from "react-icons/bs";

const ChangePassword = ({ dispatch, props }) => {
  const [fieldslogin, setFieldsLogin] = useState({});

  const [errorslogin, setErrorsLogin] = useState({});


  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsLogin((prevState) => ({ ...prevState, [name]: value }));
    setErrorsLogin((prevState) => ({ ...prevState, [name]: '' }));
  };

  const history = useHistory();

  const changePassword = () => {

    if (handleValidationLogin()) {
      const data = {
        oldPassword: fieldslogin.oldPassword,
        newPassword: fieldslogin.password,
        confirmPassword: fieldslogin.confirmPassword,
      };
      dispatch(userActions.changePassword(data, history));
    }
  };

  const handleValidationLogin = () => {
    let errorslogin = {};
    let formIsValid = true;
    if (!fieldslogin.oldPassword || fieldslogin.oldPassword === '') {
      formIsValid = false;
      errorslogin.oldPassword = 'Old Password Cannot Be Blank.';
    }

    // Check New Password
    if (!fieldslogin.password || fieldslogin.password === '') {
      formIsValid = false;
      errorslogin.password = 'New Password Cannot Be Blank.';
    }

    // Check Confirm Password
    if (!fieldslogin.confirmPassword || fieldslogin.confirmPassword === '') {
      formIsValid = false;
      errorslogin.confirmPassword = 'Confirm Password Cannot Be Blank.';
    } else if (fieldslogin.password !== fieldslogin.confirmPassword) {
      formIsValid = false;
      errorslogin.confirmPassword = 'Passwords and Confirm Password do not match.';
    }

    // Clear Confirm Password error if everything else is valid
    if (formIsValid) {
      errorslogin.confirmPassword = '';
    }

    setErrorsLogin(errorslogin);
    return formIsValid;
  };

  // console.log('usersusersusersusersusers', users);

  return (
    <>
      <div className='bg-[#EEEEEE] w-full min-h-screen'>
        <div className='flex justify-between place-items-center h-12 w-full border bg-[#024CAA] rounded-b-3xl'>
          <div className=" flex justify-start place-items-center px-4 gap-3">
            <IoArrowBackOutline
              className="text-white mb-1 text-2xl"
              onClick={() => window.history.back()}
            />
            <div className="  font-semibold text-white cursor-pointer">

              Update Password
            </div>
          </div>
        </div>

        <div className='flex space-x-1 p-1'>
          <Sidebar
            open={true}
            props={props}
            showSport={true}
          />
          <div className='w-full'>


            <div className='w-full p-4 pt-8 rounded-[4px]'>
              <div className=' flex gap-4  flex-col'>

                <div>

                  <div className="form-group relative">
                    {/* <label className="text-[#363063]  text-sm" for="oldpassword">Old Password *</label> */}
                    <input id="oldpassword"
                      className='w-full border-2 border-black p-4 px-12 focus:outline-none text-xs md:text-sm font-medium text-gray-400 rounded-full'
                      type="password"
                      name="oldPassword"
                      value={fieldslogin.oldPassword}
                      placeholder='Old Password'
                      onChange={inputChange}
                    />
                    <div className='absolute top-2.5 left-2'>
                      <div className='flex justify-center place-items-center bg-[#024CAA] rounded-full px-2 py-2'>
                        <BsKeyFill className='text-white text-lg' />

                      </div>

                    </div>
                  </div>
                  {errorslogin && errorslogin["oldPassword"] ?
                    <div className="flex justify-center">
                      <div className="text-red-700 w-full ">
                        {errorslogin["oldPassword"]}
                      </div>
                    </div>
                    : null}
                </div>

                <div className=''>

                  <div className="form-group relative">
                    {/* <label className="text-[#363063]  text-sm" for="newPassword">New Password *</label> */}
                    <input className='w-full border-2 border-black p-4 px-12 focus:outline-none md:text-sm text-xs font-medium text-gray-400 rounded-full'
                      type="password"
                      name="password"
                      id="password"
                      placeholder='New Password'
                      value={fieldslogin.password}
                      onChange={inputChange}
                      
                    />
                    <div className='absolute top-2.5 left-2'>
                      <div className='flex justify-center place-items-center bg-[#024CAA] rounded-full px-2 py-2'>
                        <BsKeyFill className='text-white text-lg' />

                      </div>

                    </div>
                  </div>
                  {errorslogin && errorslogin["password"] ?
                    <div className="flex justify-center">
                      <div className="text-red-700 w-full ">
                        {errorslogin["password"]}
                      </div>
                    </div>
                    : null}
                </div>
                <div>

                  <div className="form-group relative">
                    {/* <label className="text-[#363063]  text-sm" for="confirmpassword">Confirm New Password *</label> */}
                    <input className='w-full border-2 border-black p-4 px-12 focus:outline-none text-xs md:text-sm font-medium text-gray-400 rounded-full'
                      type="password"
                      name="confirmPassword"
                      placeholder='confirm New Password'

                      id="confirmPassword"
                      value={fieldslogin.confirmPassword}
                      onChange={inputChange}
                    />
                     <div className='absolute top-2.5 left-2'>
                      <div className='flex justify-center place-items-center bg-[#024CAA] rounded-full px-2 py-2'>
                      <BsKeyFill className='text-white text-lg'/>

                      </div>

                    </div>
                  </div>
                  {errorslogin && errorslogin["confirmPassword"] ?
                    <div className="flex justify-center">
                      <div className="text-red-700 w-full ">
                        {errorslogin["confirmPassword"]}
                      </div>
                    </div>
                    : null}
                </div>
              </div>
              <div className="form-group flex justify-center space-x-2 py-3">
                <button onClick={changePassword} type="button" className="bg-[#024CAA] text-white text-sm font-semibold px-4 py-2 rounded-full tracking-widest">Update Password</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default withRouter(connect(mapStateToProps)(ChangePassword));