import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { userActions } from '../../_actions';
import { connect, useSelector } from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
import { useHistory } from 'react-router-dom';
import { IoArrowBackOutline, IoCaretBackCircleSharp } from "react-icons/io5";
const HowToPlay = ({ dispatch, props }) => {
  const history = useHistory();
  let selector = useSelector(state => state)
  let { users } = selector ? selector : {}
  let { appSettingData } = users;

  console.log("appSettingDataappSettingData", appSettingData);


  useEffect(() => {
    dispatch(userActions.getAllAppSetting());
  }, [])

  const handleGoBack = () => {
    window.history.back()
  }


  return (
    <>
      <div className='bg-[#EEEEEE] h-screen  w-full '>
        <div className='h-12 w-full border bg-[#024CAA] rounded-b-3xl flex justify-between place-items-center '>
          <div className='flex justify-start items-center px-4 gap-3'>
            <IoArrowBackOutline className='text-white mb-1 text-2xl' onClick={handleGoBack} />
            <div className='  font-semibold text-white cursor-pointer text-center' > How To Play</div>

          </div>

        </div>
        <div className='flex space-x-1 p-1 h-[90vh]  overflow-y-scroll'>
          <Sidebar
            open={true}
            props={props}
            showSport={true}
          />
          <div className='w-full mt-4'>
            <div className='bg-white p-6 rounded-lg shadow-md'>
              {appSettingData && appSettingData.length > 0 && appSettingData.map((e) => {
                if (e && e.key === "HowToPlay") {

                  // Modify the value to add numbering before <strong> tags and leave the rest of the list intact
                  let sectionNumber = 1; // Counter for <strong> elements

                  const modifiedValue = e.value
                    .replace(/<strong>/g, (match) => { // Add numbering before each <strong> tag
                      return `<strong>${sectionNumber++}. `;
                    })
                    .replace(/<li class="ql-indent-1">/g, '<li class="ql-indent-1">● '); // Adding bullet points to nested <li>

                  return (
                    <div
                      key={e.key}
                      className='prose prose-lg max-w-none text-gray-800 text-start space-y-4 list-decimal' // Ensure it's a numbered list
                      dangerouslySetInnerHTML={{ __html: modifiedValue }} // Use modified value here
                    />
                  );
                }
                return null;
              })}


            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default withRouter(connect(mapStateToProps)(HowToPlay));