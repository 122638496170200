import React,{useEffect} from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { userActions } from '../../_actions';

const RuleModal = () => {
    const dispatch = useDispatch()
    let selector = useSelector(state => state)
    let { users } = selector ? selector : {}
    let { appSettingData } = users;
    useEffect(() => {
        dispatch(userActions.getAllAppSetting());
    }, [])
    console.log(appSettingData);
    
  return (
    <div>
         <section>
                <div className="mt-8 mx-4 text-center">

                    <div className=' bg-white p-5 rounded-md mb-10 text-xs'>
                        {appSettingData && appSettingData.length > 0 && appSettingData.map((e) => {

                            if (e && e.key === "NOTICE") {
                                return (
                                    <div dangerouslySetInnerHTML={{ __html: e.value }} />
                                )
                            }
                        })}

                    </div>

                </div>

            </section >

    </div>
  )
}


function mapStateToProps(state) {
    const { users } = state;
    return {
        users
    };
}

export default withRouter(connect(mapStateToProps)(RuleModal)) 

