import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { TfiReload } from "react-icons/tfi";
import { useDispatch } from "react-redux";
import { userActions } from "../../_actions";
import { BsWallet } from "react-icons/bs";
import { IoMenuOutline } from "react-icons/io5";
// import logo from '../../../public/logo.png'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GiWallet } from "react-icons/gi";
const Header = (props) => {
  let { opensizebar, setopensizebar } = props;
  const [navbarOpen, setNavbarOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory()
  const onClickMenutwo = (url) => {
    localStorage.removeItem("user");
    props.history.push(url);
  };

  const navigate = (url) => {
    props.history.push(url);
    setOpenModal(!openModal);
  };

  const [clickedOutside, setClickedOutside] = useState(false);
  const myRef = useRef();

  useEffect(() => {
    dispatch(userActions.getProfile());

    // Handle click outside
    const handleClickOutside = (event) => {
      if (myRef.current && !myRef.current.contains(event.target)) {
        setClickedOutside(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  const [openModal, setOpenModal] = useState(false);
  let user = JSON.parse(localStorage.getItem("spuser"));

  const { users } = props;
  const { getProfile } = users;
  let handleRefresh = () => {
    dispatch(userActions.getProfile());
  };

  const navigateWallet = () => {
    history.push(`/app/add-fund`)
  }

  return (
    <div>
      <div className="bg-[#024CAA] p-1 w-full h-[20vh]">
        <div className="flex items-center justify-between  md:sticky top-0 z-40 h-[50px] lg:h-[76px] px-5 relative w-full">
          <div className="flex  justify-start   items-center lg:pl-10">


            <h2 className="text-xl font-semibold text-center text-white"> MATKA BOOKIE</h2>

            <IoMenuOutline
              onClick={() => setopensizebar((prev) => !prev)}
              className=" text-white absolute cursor-pointer left-1 md:left-4 text-2xl font-bold"
            />
          </div>
          <div className="flex space-x-1 items-center">
            <div className="text-white md:relative">
              <div className="flex items-center lg:space-x-20 space-x-5">
                <div className="flex  gap-2  items-center text-xl lg:text-sm text-black space-x-1 leading-none rounded cursor-pointer">
                  <div className="flex gap-2 text-xl  bg-white  justify-center items-center rounded-lg px-2 py-1 " onClick={navigateWallet}>
                    <GiWallet className="" />
                    <span className="font-semibold text-sm">
                      {getProfile && getProfile.wallet
                        ? getProfile.wallet.toFixed(2)
                        : "0"}
                    </span>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(Header);
