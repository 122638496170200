import React, { useEffect } from 'react'
import { IoArrowBackOutline } from 'react-icons/io5'
import { useParams } from 'react-router';
import { bankActions } from '../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader';

function JodiChart() {
    const dispatch = useDispatch();
    let { id, name } = useParams
    let parmas = useParams()

    let selector = useSelector(state => state)
    let { users } = selector ? selector : {}
    let { appSettingData, gameList, chartdata, loading } = users;

    useEffect(() => {

        dispatch(bankActions.calander({ gameId: parmas?.gameId }));
    }, [parmas])

    // console.log(chartdata);


    return (
        <>
            <Loader loading={loading} />
            <div className='bg-white text-black h-screen'>
                <div className='flex justify-between place-items-center h-12 w-full border bg-[#024CAA] rounded-b-3xl'>
                    <div className=" flex justify-start place-items-end px-4 gap-3">
                        <IoArrowBackOutline
                            className="text-white mb-1 text-lg"
                            onClick={() => window.history.back()}
                        />
                        <div className="  font-semibold text-white cursor-pointer capitalize">
                            {`${parmas?.gameName} Chart`}

                        </div>
                    </div>
                </div>
                <div className="w-full mt-6">
                    <div>
                        <div className="overflow-x-auto px-1 w-full">
                            <table className="min-w-full  md:text-base divide-y divide-gray-200 w-full">
                                <thead>
                                    <tr className="bg-yellow-400 font-semibold text-[12px] border-b-2 border-black">
                                        {["Date", "MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"].map((day) => (
                                            <th
                                                key={day}
                                                className="text-center border text-[#17273B] uppercase tracking-wider px-2 py-1 md:px-2 md:py-2"
                                            >
                                                {day}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {chartdata && chartdata.length > 0 ? (
                                        chartdata.map((week, index) => {
                                            const days = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
                                            return (
                                                <tr key={index} className="bg-white border text-[14px] ">
                                                    <div className='p-0.5 text-xs'>

                                                    {week?.rWeek ? week?.rWeek : '-'}
                                                    </div>
                                                    {days.map((day) => (
                                                        <>
                                                            {/* <td>{week?.rWeek?week?.rWeek:}</td> */}
                                                            <td key={day} className="px-1 whitespace-nowrap border">
                                                                <div className="flex md:gap-3 gap-1 justify-center items-center">
                                                                    {/* <div>
                                                                    <div>{week[day]?.openNumber?.toString()[0] || "*"}</div>
                                                                    <div>{week[day]?.openNumber?.toString()[1] || "*"}</div>
                                                                    <div>{week[day]?.openNumber?.toString()[2] || "*"}</div>
                                                                </div> */}


                                                                    <div className="">
                                                                        {week[day]?.jodiNumber?.toString()[0] || "*"}{" "}
                                                                        {week[day]?.jodiNumber?.toString()[1] || "*"}
                                                                    </div>
                                                                    {/* <div>
                                                                    <div>{week[day]?.closeNumber?.toString()[0] || "*"}</div>
                                                                    <div>{week[day]?.closeNumber?.toString()[1] || "*"}</div>
                                                                    <div>{week[day]?.closeNumber?.toString()[2] || "*"}</div>
                                                                </div> */}
                                                                </div>
                                                            </td>
                                                        </>

                                                    ))}
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={7} className="text-center py-4">
                                                No Data Found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default JodiChart
