import React from "react";
import { connect } from "react-redux";

import { FaCheckCircle, FaDollarSign, FaHashtag, FaHourglassHalf, FaRegCalendar, FaTags, FaTimesCircle } from "react-icons/fa";
import { HiOutlineChevronRight } from "react-icons/hi2";
import { RxCountdownTimer } from "react-icons/rx";
import { betActions, userActions } from "../../../_actions";
import Loader from "../../../components/Loader/Loader";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { IoArrowBackOutline } from "react-icons/io5";
import { RiFilterFill } from "react-icons/ri";
import { IoCloseOutline } from "react-icons/io5";




class BidHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      declareMatchModal: false,
      isOpen: false,
      setFmatchId: false,
      declareMatchDetails: {},
      currentCount: "Sport",
      noOfRecords: 20,
      index: 0,
      betType: 1,
      size: 10,
      optionRadioName: "MATCHED",
      endDate: "",
      startDate: "",
      sportsId: "",
      limit: "",
      offset: 0,
      pageNo: 1,
      selectedValues: [],
      openGame: false,
      closeGame: false,
      result: '',
      selectedGame: ''

    };
  }

  componentDidMount() {
    let data = {
      // "fromDate": moment(this.state.startDate).startOf('day').unix(),
      // "toDate": moment(this.state.endDate).endOf('day').unix(),
      // "keyWord": "",
      sortBy: "",
      sortOrder: "",
      pageNo: this.state.pageNo,
      size: this.state.size,
    };

    this.props.dispatch(betActions.betHistory(data));
    // this.props.dispatch(userActions.getBetsList(data));

    let gameReqData = {
      // "keyWord": "",
      pageNo: this.state.pageNo,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: "",
      toDate: "",
      size: 200,
    };

    this.props.dispatch(userActions.getGamesList(gameReqData));
  }



  inputChangeOpen = (event) => {
    const { checked } = event.target;
    this.setState(
      { openGame: checked },
      this.updateResult
    );
  };


  inputChangeClose = (event) => {
    const { checked } = event.target;
    this.setState(
      { closeGame: checked },
      this.updateResult
    );
  };
  updateResult = () => {
    const { openGame, closeGame } = this.state;

    let result = '';
    if (openGame && !closeGame) {
      result = true;
    } else if (!openGame && closeGame) {
      result = false;
    }

    this.setState({ result });

  };
  handleChangeWinLossPending = (e) => {
    const value = e.target.checked ? "Close" : "";
    this.setState({ closeGame: value });

  };

  inputChangeCurrentCount = (event) => {
    this.setState({ currentCount: event });
  };

  handlePageClick = (data) => {
    console.log("data  ", data);
    this.setState({ index: data.selected });

    let offset = Math.ceil(data.selected * this.state.size);

    this.setState({ offset: offset });

    this.setState({ pageNo: data.selected + 1 }, () => {
      let pagination = {
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
        // "keyWord": "",
        gameId: this.state.sportsId,
        sortBy: "",
        sortOrder: "",
        pageNo: this.state.pageNo,
        size: this.state.size,
      };
      this.props.dispatch(betActions.betHistory(pagination));
    });
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  goBack = () => {
    window.history.back();
  };



  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    let { selectedValues } = this.state;
    const valueMap = {
      win: 1,
      loss: 2,
      pending: 0,
    };

    if (checked) {
      if (!selectedValues.includes(valueMap[name])) {
        selectedValues = [...selectedValues, valueMap[name]];
      }
    } else {
      selectedValues = selectedValues.filter((val) => val !== valueMap[name]);
    }
    this.setState({ selectedValues });

  };

  handleChangeSelect = (event) => {
    this.setState({ selectedGame: event.target.value })



  }


  getBatHistory = () => {
    this.setState({ pageNo: 1 }, () => {

      let data = {
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
        gameId: this.state.selectedGame,
        sortBy: "",
        sortOrder: "",
        type: this.state.result,
        status: this.state.selectedValues,
        pageNo: this.state.pageNo,
        size: this.state.size,
      };
      this.props.dispatch(betActions.betHistory(data));
      this.setState((prevState) => ({
        isOpen: !prevState.isOpen,
      }));
    })
  };




  render() {
    let { bet, users } = this.props;
    let { betList, loading, betLoading, totalBet } = bet;
    let { totalGame, gameList } = users;
    const { isOpen } = this.state;
    const today = new Date().toISOString().split("T")[0];
    // const navigate = useNavigate();
    const handleNavigate = () => {
      this.props.history.push("/app/bidhistory");
    };

    console.log("betList", betList);

    return (
      <>
        <div>
          <Loader loading={loading} />
        </div>
        <div className="bg-gray-200 w-full min-h-screen   ">
          <div className="flex justify-between place-items-center h-12 w-full border bg-[#024CAA] rounded-b-3xl">
            <div className=" flex justify-start place-items-center px-4 gap-3">
              <IoArrowBackOutline
                className="text-white mb-1 text-2xl"
                onClick={this.goBack}
              />
              <div className="  font-semibold text-white cursor-pointer">
                {" "}
                Bid History
              </div>
            </div>
            <div className="flex justify-start place-items-center text-white px-2 gap-2">
              <span className=" cursor-pointer text-sm">Filter By</span>
              <span className="relative inline-block">
                <RiFilterFill
                  className="cursor-pointer"
                  onClick={this.toggleDropdown}
                />
                {isOpen && (
                  <div className="fixed inset-0 flex items-center justify-center z-50">
                    {/* Background Overlay */}
                    <div
                      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
                      onClick={this.toggleDropdown} // Close modal when clicking outside
                    ></div>

                    {/* Modal Content */}
                    <div className="relative w-80 bg-white border text-black border-gray-300 rounded-lg shadow-lg z-50">

                      <div className="text-center font-semibold mb-4">
                        <h4>Filter Type</h4>
                      </div>
                      <div className="border-t border-gray-300 p-4 space-y-4">
                        <div className="flex justify-center gap-4 items-center ">
                          <div className="flex items-center gap-2">
                            <label className="text-sm">Open</label>
                            <input
                              type="checkbox"
                              className="border border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
                              name="open"
                              id="openCheckbox"
                              checked={this.state.openGame}
                              onChange={this.inputChangeOpen}
                            />
                          </div>
                          <div className="flex items-center gap-2">
                            <label className="text-sm">Close</label>
                            <input
                              type="checkbox"
                              className="border border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
                              name="close"
                              id="closeCheckbox"
                              checked={this.state.closeGame}
                              onChange={this.inputChangeClose}
                            />
                          </div>
                        </div>

                        <div className="flex justify-between">
                          <div className="flex items-center gap-2">
                            <label className="text-sm">Win</label>
                            <input
                              type="checkbox"
                              className="border border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
                              name="win"
                              id="winCheckbox"
                              onChange={this.handleCheckboxChange}
                            />
                          </div>
                          <div className="flex items-center gap-2">
                            <label className="text-sm">Loss</label>
                            <input
                              type="checkbox"
                              className="border border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
                              name="loss"
                              id="lossCheckbox"
                              onChange={this.handleCheckboxChange}
                            />
                          </div>
                          <div className="flex items-center gap-2">
                            <label className="text-sm">Pending</label>
                            <input
                              type="checkbox"
                              className="border border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
                              name="pending"
                              id="pendingCheckbox"
                              onChange={this.handleCheckboxChange}
                            />
                          </div>
                        </div>
                        <div className="">
                          <label className="text-sm">Game Name</label>
                          <select
                            onChange={this.handleChangeSelect}
                            name="sports_id"
                            className="w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                          >
                            <option value="">All</option>
                            {gameList && gameList.length > 0
                              ? gameList.map((element, index) => (
                                <option key={index} value={element && element._id} className="capitalize">
                                  {element && element.name}
                                </option>
                              ))
                              : null}
                          </select>
                        </div>
                        <div className="flex justify-center items-center mt-4 gap-4">
                          <button
                            onClick={this.getBatHistory}
                            className="py-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-700"
                          >
                            Submit
                          </button>

                          <button className="py-2 px-4 bg-red-600 text-white rounded hover:bg-red-700" onClick={this.toggleDropdown} >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              </span>
            </div>
          </div>

          {/* <div className='bg-white w-full min-h-screen flex py-10' style={{ backgroundImage: "url(/images/mw-ban.png)" }}> */}
          <div className="p-4  overflow-hidden overflow-y-auto h-[90vh]">
            <div className="">
              <div className="flex flex-col gap-5  h-full w-full">
                {betList && betList.length > 0 ? (
                  betList.map((element, index) => {
                    return (
                      <div
                        key={index} // Always add a key prop when mapping over an array
                        className="mx-auto bg-white shadow-lg rounded-lg overflow-hidden border border-gray-300"
                      >
                        <div className="bg-[#024CAA] text-white text-center py-2">
                          <p className="text-base font-semibold">
                            {moment(new Date(parseInt(element.createdAt)))
                              .utcOffset("+05:30")
                              .format("hh:mm A")}
                            ({element?.marketId?.name || "-"}, {element?.isOpen ? "Open" : "Close"})
                          </p>
                        </div>

                        <div className="p-8">
                          <div className="flex justify-center items-center gap-4">
                            <p>Game Name:</p>
                            <p className="capitalize">{element?.gameId?.name}</p>
                          </div>

                          <div className="mt-4 grid grid-cols-2 text-center gap-2">
                            <div>
                              <p className="text-xs text-gray-500">Point</p>
                              <p className="text-lg font-semibold text-blue-600">
                                {element?.amount || "-"}
                              </p>
                            </div>
                            <div>
                              <p className="text-xs text-gray-500">Digit</p>
                              <p className="text-lg font-semibold text-blue-600">
                                {element?.betNumber || "-"}
                              </p>
                            </div>
                          </div>

                          <p className="text-sm text-gray-700 mt-4">
                            <span className="text-black">Transaction Time:</span>
                            <span className="text-gray-400 px-2">
                              {moment(new Date(parseInt(element.createdAt)))
                                .utcOffset("+05:30")
                                .format("DD-MM-YYYY (ddd)")}
                            </span>
                          </p>

                          <p className="text-green-600 text-center mt-4">
                            {element?.betStatus === 0 && (
                              <div className="text-yellow-500">Pending</div>
                            )}
                            {element?.betStatus === 1 && (
                              <div className="text-green-800">
                                You Won Rs. +{element?.winAmount} 🤩
                              </div>
                            )}
                            {element?.betStatus === 2 && (
                              <div className="text-red-700">
                                Better Luck Next Time!!! 😔 -{element?.lossAmount}
                              </div>
                            )}
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center text-gray-500 mt-4">No data found</div>
                )}


                <nav className="flex justify-end mt-4 w-full">
                  {totalBet && totalBet > this.state.size ? (
                    <ReactPaginate
                      previousLabel={"Prev"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={totalBet / this.state.size}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"page-cls"}
                      activeClassName={"active"}
                    />
                  ) : null}
                </nav>
              </div>
            </div>
          </div>

          <div className="">

          </div>

        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users, bet } = state;
  return {
    users,
    bet,
  };
}

export default connect(mapStateToProps)(BidHistory);
