import React, { useEffect } from 'react'
import { IoArrowBackOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { useSelect } from 'react-select-search'
import { userActions } from '../../_actions'
import moment from 'moment'

function TimeTable() {
    const dispatch = useDispatch()
    const selector = useSelector(state => state)
    const handleBack = () => {
        window.history.back()
    }

    let { users } = selector ? selector : {}
    let { appSettingData, gameList, loading } = users;
    // console.log("gameList_____",gameList);


    useEffect(() => {
        let data =
        {
            "keyWord": "",
            "pageNo": 1,
            "sortBy": "slug",
            "sortOrder": "asc",
            "fromDate": "",
            "toDate": "",
            "size": 100
        }
        // dispatch(userActions.getGamesList(data));
        dispatch(userActions.getGamesList(data));
    }, [])
    return (
        <div className='bg-white text-black h-screen'>
            <div className='h-12 w-full border bg-[#024CAA] rounded-b-3xl flex justify-between place-items-center px-4 gap-3'>
                <div className='flex justify-start place-items-center gap-2'>
                    <IoArrowBackOutline className='text-white mb-1 text-2xl cursor-pointer' onClick={handleBack} />
                    <div className='  font-semibold text-white cursor-pointer' >Time Table</div>
                </div>
            </div>
            <div className='px-3 pt-2'>
                <div className='flex justify-between place-items-center bg-[#024CAA] rounded text-white p-2.5 py-1'>
                    <p className='font-semibold'>Game Name</p>
                    <p>Open Time</p>
                    <p>Close Time</p>

                </div>
            </div>
            <div className='p-3'>
                {gameList && gameList.length > 0 && gameList.map((ele, id) => {

                    const openingTime = moment()
                        .hours(parseInt(ele && ele.openTime / 3600))
                        .minutes(
                            parseInt(
                                (ele && ele.openTime % 3600) / 60,
                            ),
                        )
                        .seconds(parseInt(ele && ele.openTime % 60))
                        .milliseconds(0);

                    const closingTime = moment()
                        .hours(
                            parseInt(ele && ele.closingTime / 3600),
                        )
                        .minutes(
                            parseInt(
                                (ele && ele.closingTime % 3600) / 60,
                            ),
                        )
                        .seconds(
                            parseInt(ele && ele.closingTime % 60),
                        )
                        .milliseconds(0);

                    const OpenBetTime = openingTime.format("hh:mm A");
                    const CloseBetTime = closingTime.format("hh:mm A");
                    return (
                        <>
                            <div className='grid grid-cols-3 bg-white shadow-md text-sm border rounded-md text-black p-2.5 my-1' key={id}>
                                <p className='text-sm  capitalize  '>{ele ? ele?.name : '-'}</p>
                                {/* <p className='px-2'>{moment(ele?.openTime).format('hh:mm A')}</p> */}
                                <p className='text-center'>{OpenBetTime}</p>
                                {/* <p>{moment(ele?.closingTime).format('hh:mm A')}</p>
                                 */}
                                <p className='text-center'>{CloseBetTime}</p>
                            </div>
                        </>
                    )
                })}

            </div>
        </div>
    )
}

export default TimeTable
