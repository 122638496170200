import React from 'react'


const BetPlaceModal = (props) => {
    let { loading } = props
    return (
        <>
            {
                loading ?
                    <>
                        <div
                            className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-[99999999]"

                        >
                            <div className="bg-white p-10 rounded-lg shadow-lg text-center flex flex-col border-2 border-[#C11C84]  justify-center place-items-center" >
                                <img src='/Group 6.png' alt='check' className='w-32' />
                                <p className="text-lg font-semibold">Bet placed successfully</p>
                            </div>
                        </div>
                    </> :
                    null
            }
        </>
    )
}

export default BetPlaceModal