import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
// import logofrom from "./pwtw.png"
import { FaKey, FaMobileAlt, FaRegEyeSlash, FaWhatsappSquare } from "react-icons/fa";
import { MdCall, MdOutlineRemoveRedEye } from "react-icons/md";
import Loader from "../../components/Loader/Loader";
// import { MdOutlineWifiCalling3 } from "react-icons/md";


import { Link } from "react-router-dom/cjs/react-router-dom.min";





class Login extends Component {

  constructor(props) {
    super(props);
    this.props.dispatch(userActions.logout());
    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      passtype: "password",
      visible: false,
    };
  }

  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    // console.log(name, value);
    this.setState({ fieldslogin, errorslogin });
  }

  loginSubmit(e) {
    e.preventDefault();
    if (this.handleValidationLogin()) {
      let data = {
        userName: this.state.fieldslogin.username,
        password: this.state.fieldslogin.password,
      };
      this.props.dispatch(userActions.login(data, this.props));
    }
  }
  handleNavigate(e) {
    this.props.history.push("/ForgotPassword")
  }

  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    });
    this.hideErrorMessage();
  };

  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //User Name
    if (!fieldslogin["username"] || fieldslogin["username"].trim() == "") {
      formIsValid = false;
      errorslogin["username"] = "Username is required.";
    } else if (fieldslogin["username"].includes(" ")) {
      formIsValid = false;
      errorslogin["username"] = "Spaces are not allowed in the  Name.";
    }
    //password
    if (!fieldslogin["password"] || fieldslogin["password"].trim() == "") {
      formIsValid = false;
      errorslogin["password"] = "Password is required.";
    }
    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  };

  handleVisible = () => {
    this.setState((prevState) => ({
      visible: !prevState.visible
    }));
  }



  render() {
    const { authentication } = this.props
    let { loading } = authentication ? authentication : {}

    return (
      <div className="bg-black/80 ">
        <Loader loading={loading} />
        <div className="relative">
          <div className="min-h-screen  flex flex-col justify-center  ">
            <div className="mx-auto w-full  bg-no-repeat rounded-lg  max-w-lg">
              <div className=" mx-auto h-screen  flex justify-center items-center flex-col bg-no-repeat py-0  rounded-xl relative overflow-hidden ">
                <div className="w-full bg-white ">
                  <div className="border-l-8  uppercase bg-gray-200 border-[#e2a913]  mt-4 py-2 text-black   font-semibold">
                    <p className="px-4 w-full">Enter Your Password</p>
                    <p className="px-4 w-full">Enter In game Zone</p>
                  </div>
                </div>
                <div className=" h-screen w-full place-items-center bg-white   ">
                  <img src="/matkabookielogo.webp" className="w-44 h-32 mx-auto mt-8 rounded-md drop-shadow shadow-[#e2a913]" alt="" />
                  <div className="mx-10 mt-20">
                    <div className=" w-full flex flex-col relative">
                      <label className="block mb-2 text-sm font-medium text-gray-900">Enter Mobile No</label>
                      <input
                        type="number"
                        name="username"
                        id="username"
                        value={this.state.fieldslogin.username}
                        className="px-12 py-5 placeholder-blueGray-400  rounded-lg bg-[#EEEEEE]  text-sm shadow-md  w-full ease-linear transition-all duration-150"
                        placeholder="Number"
                        onChange={this.inputChange}
                      />
                      {this.state.errorslogin &&
                        this.state.errorslogin["username"] ? (
                        <div className="text-red-500 text-left text-xs">
                          {this.state.errorslogin["username"]}
                        </div>
                      ) : null}
                      <span className="absolute top-11 left-4 w-7 h-8 bg-[#024CAA] flex justify-center place-items-center rounded-lg">
                        <FaMobileAlt className="text-white" />
                      </span>
                    </div>
                    <div className="w-full mt-3 relative">
                      <label className="block mb-2 text-sm font-medium text-gray-900">Enter Password</label>

                      <input
                        type={this.state.visible ? "text" : "password"}
                        name="password"
                        id="password"
                        value={this.state.fieldslogin.password}
                        className="px-12 py-5 placeholder-blueGray-400 border border-gray-200 text-blueGray-600 rounded-lg bg-[#EEEEEE]  text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="Password"
                        onChange={this.inputChange}
                      />
                      {this.state.visible ? <MdOutlineRemoveRedEye onClick={this.handleVisible} className=" absolute text-[#e2a913] top-12 cursor-pointer right-2" /> : <FaRegEyeSlash onClick={this.handleVisible} className=" absolute top-12 text-[#e2a913] cursor-pointer right-2" />}
                      {this.state.errorslogin &&
                        this.state.errorslogin["password"] ? (
                        <div className="text-red-500 whitespace-nowrap text-left text-xs">
                          {this.state.errorslogin["password"]}
                        </div>
                      ) : null}
                      <span className="absolute top-11 left-4 w-7 h-8 bg-[#024CAA] flex justify-center place-items-center rounded-lg">
                        <FaKey className="text-white" />
                      </span>
                    </div>

                    <div className="w-full flex justify-center items-center ">
                      <button type="button" onClick={this.loginSubmit}
                        className="w-full py-2 bg-[#024CAA] text-white font-semibold text-lg rounded-full  mt-5">
                        <span className="tracking-wider ">Login</span>
                      </button>
                    </div>
                    <div className="text-end flex justify-center border-b ">
                      <button className="py-4" onClick={(e) => this.handleNavigate(e)} >Forgot password ?</button>
                    </div>
                    {/* <div className="flex justify-center gap-4 place-items-center my-2">
                      <button className="px-4 py-1 rounded-full bg-[#EEEEEE] shadow-lg"><IoLogoWhatsapp className="text-3xl" /></button>
                      <button className="px-4 py-1 rounded-full bg-[#EEEEEE] shadow-lg"><MdOutlineWifiCalling3 className="text-3xl" /></button>
                    </div> */}
                    <div className="text-center py-3">
                      <div>
                        <div className="flex justify-center place-items-center gap-28 mb-4  pb-2">
                          <button className="flex items-center px-3 py-1  gap-1">
                            <FaWhatsappSquare className='text-4xl text-green-500'/>
         
                          </button>
                          <button className="flex items-center px-2 py-2 shadow-md gap-1 rounded text-white bg-sky-500 border">
                            <MdCall className='text-lg' />
                          </button>
                        </div>
                      </div>
                      <p>Don't have an account yet? <Link to={"/Signup"} className='text-sky-600'>Sign Up</Link></p>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>



        {/* </div> */}
        {/* <Footer /> */}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSent, } = state.authentication;
  const { users, authentication } = state;
  return {
    loggingIn,
    otpSent,
    user,
    users,
    authentication
  };
}
export default connect(mapStateToProps)(Login);
