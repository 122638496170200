
import React, { useEffect } from 'react'
import { IoArrowBackOutline } from 'react-icons/io5'
import { useParams } from 'react-router';
import { bankActions, jackportchartAction, starlineAction } from '../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader';

function JackpotChart() {
    const dispatch = useDispatch();
    let { id, name } = useParams
    let parmas = useParams()

    let selector = useSelector(state => state)
    let { users } = selector ? selector : {}
    let { appSettingData, gameList, chartdata, loading } = users;

    useEffect(() => {

        dispatch(jackportchartAction.calanderForJackpot())
    }, [])

    let {jackpotchart} = selector?selector:{};
    let {getjackpotChartList} = jackpotchart?jackpotchart:{};
    
    let { gameResults, timingarray } = getjackpotChartList ? getjackpotChartList : {};

    return (
        <>
            <Loader loading={loading} />
            <div className='bg-white text-black h-screen'>
                <div className='flex justify-between place-items-center h-12 w-full border bg-[#024CAA] rounded-b-3xl'>
                    <div className=" flex justify-start place-items-center px-1 gap-4">
                        <IoArrowBackOutline
                            className="text-white mb-1 text-2xl"
                            onClick={() => window.history.back()}
                        />
                        <div className="  font-semibold text-white cursor-pointer capitalize">
                            {`Jackpot Chart`}

                        </div>
                    </div>
                </div>
                <div className="w-full mt-6">
                    <div>
                        <div className="overflow-x-auto text-xs px-1 w-full">
                            <div className="overflow-x-auto text-xs px-1 w-full">
                                <table className="border-collapse whitespace-nowrap border border-gray-300 p-1" cellSpacing="0">
                                    {/* Table header */}
                                    <thead>
                                        <tr>
                                            <th className="border border-gray-300 p-1 bg-[#FACC15]">Date</th>
                                            {timingarray && timingarray.length > 0 && timingarray.map((time, index) => (
                                                <th key={index} className="border border-gray-300 p-1 bg-[#FACC15]">{time}</th>
                                            ))}
                                        </tr>
                                    </thead>

                                    {/* Table body */}
                                    <tbody>
                                        {gameResults && gameResults.length > 0 && gameResults.map((gameDay, index) => (
                                            <tr key={index}>
                                                <td className="border border-gray-300 p-1">{gameDay.date}</td>
                                                {timingarray && timingarray.length > 0 && timingarray.map((time, idx) => {
                                                    const result = gameDay.results.find(game => game.gameTime === time);
                                                    return (
                                                        <td className="whitespace-nowrap  border border-gray-300 p-1" key={idx}>
                                                            {result
                                                                ? `${result?.jodiNumber}`
                                                                : "**"}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default JackpotChart



