import React from 'react';
import moment from 'moment';
import Loader from './Loader/Loader';
export default function ConfirmationModal(props) {
    let { isModelOpen, isModalOpenClose, arrayOfBets, walletAmount, letOpenModelClose, placeBet, type } = props;
    const totalAmount = arrayOfBets && arrayOfBets.length > 0
        ? arrayOfBets.reduce((total, bet) => total + parseInt(bet.amount), 0)
        : 0;

    const currentDate = moment();
    const formattedDate = currentDate.format('DD-MMM-YYYY HH:mm');


    return (
        <>

            <div className={isModelOpen ? "fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50" : "hidden"} onClick={isModalOpenClose}>
                <div className="bg-white rounded-lg border-[#C11C84] border-4 shadow-lg max-w-[24rem] w-full relative">
                    <h2 className='text-xl font-bold p-3 text-white text-center bg-[#024CAA] rounded-t-lg'>
                        {formattedDate}
                    </h2>
                    <div className='bg-white px-6 py-4'>
                        {/* Header Row */}
                        <div className='flex justify-between items-center bg-[#024CAA] text-white p-2 rounded-md mb-2'>
                            <h6 className='text-base font-semibold'>Digit</h6>
                            <h6 className='text-base font-semibold'>Amount</h6>
                            <h6 className='text-base font-semibold'>Type</h6>
                        </div>
                        {/* Betting Entries */}
                        <div className='border-b border-black max-h-64 overflow-y-auto overflow-x-hidden p-1 mb-4 '>
                            {arrayOfBets && arrayOfBets.length > 0 && arrayOfBets.map((e, index) => (
                                <div key={index} className='flex justify-between items-center border px-3 py-2 shadow rounded mb-2 bg-gray-50'>
                                    <div>{e?.betNumber}</div>
                                    <div>₹{Number(e?.amount)?.toFixed(2)}</div>
                                    <div>{type}</div>
                                </div>
                            ))}
                        </div>

                        {/* Totals Section */}
                        <div className='space-y-2 text-gray-800'>
                            <div className='flex justify-between'>
                                <div className='font-semibold'>Total Bids Amount:</div>
                                <div>₹{totalAmount || "0.00"}</div>
                            </div>
                            <div className='flex justify-between'>
                                <div className='font-semibold'>Wallet Before Deduction:</div>
                                <div>₹{Number(walletAmount)?.toFixed(2) || "0.00"}</div>
                            </div>
                            <div className='flex justify-between'>
                                <div className='font-semibold'>Wallet After Deduction:</div>
                                <div>₹{Number(walletAmount - totalAmount)?.toFixed(2) || "0.00"}</div>
                            </div>
                        </div>

                        {/* Footer Section */}
                        <div className='flex flex-col mt-6 space-y-4 border-t border-black pt-4'>
                            <p className='text-xs text-red-600'>
                                *Note: Bids once placed cannot be cancelled.
                            </p>
                            <div className='flex space-x-4'>
                                <button
                                    onClick={letOpenModelClose}
                                    className='bg-gray-300 text-gray-800 font-semibold px-4 py-2 rounded-lg hover:bg-gray-400 transition-all w-full'>
                                    Cancel
                                </button>
                                <button
                                    onClick={placeBet}
                                    className='bg-[#024CAA] text-white font-semibold px-4 py-2 rounded-lg hover:bg-[#a50d68] transition-all w-full'>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}
