// import React from 'react'
// import  { Redirect } from 'react-router-dom'
// return <Redirect to='/login'  />
import { Redirect } from 'react-router-dom';
import { history } from '../_helpers';


export function authHeader() {
   
    
    let user = JSON.parse(localStorage.getItem('spuser'));
   

    if (user && user.data && user.data.token) {
        return { 'Authorization': 'Bearer ' + user.data.token };
    } else {
      
        return {
            
        };
    }
}


// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOnsiaWQiOiIxODI0IiwidXNlcl90eXBlX2lkIjoiNiJ9LCJpYXQiOjE2ODQyOTkyMTN9.L7_RUQ6wSvIKRS45YhwtOSH-vCoZR7XwFY37C99a9rg