import React, { useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { starlineAction } from "../../_actions";
import moment from "moment";
import Loader from "../../components/Loader/Loader";

function IndianStarlineResultHistory() {
    const selector = useSelector((state) => state);
    const dispatch = useDispatch();
    const currentDate = new Date().toISOString().split('T')[0];
    const [datedata, setdatedata] = useState(currentDate);

    useEffect(() => {
        let data = {
            filterDate: currentDate || '',
        };
        dispatch(starlineAction.calanderForStarLine(data));
    }, [currentDate]);

    const handleOnChange = (e) => {
        const selectedDate = e.target.value;  // directly use e.target.value
        setdatedata(selectedDate);
        let data = {
            filterDate: selectedDate || '',
        };
        dispatch(starlineAction.calanderForStarLine(data));
    }

    const goBack = () => {
        window.history.back();
    };

    let { starline } = selector ? selector : {};
    let { getStarlineChartList, loading } = starline ? starline : {};
    let { gameResults, timingarray } = getStarlineChartList ? getStarlineChartList : {};

    return (
        <>
            <Loader loading={loading} />
            <div className="bg-white text-black min-h-screen">
                {/* Header */}
                <div className="h-12 w-auto border bg-[#024CAA] rounded-b-3xl flex justify-start items-center px-4 gap-3">
                    <IoArrowBackOutline
                        className="text-white mb-1 text-2xl cursor-pointer"
                        onClick={goBack}
                    />
                    <div className="font-semibold text-white cursor-pointer uppercase">
                        Starline Result History
                    </div>
                </div>

                {/* Date Selection */}
                <div className="flex flex-col sm:flex-row justify-between items-center p-3 text-sm">
                    <p className="mb-2 sm:mb-0">Select Date</p>
                    <div>
                        <input
                            type="date"
                            max={currentDate}
                            onChange={(e) => handleOnChange(e)}
                            value={datedata}
                            className="bg-white px-3 py-1 uppercase rounded-full shadow w-full sm:w-auto"
                        />
                    </div>
                </div>

                {/* Results Section */}
                <div className="p-4 h-auto sm:h-[90vh] overflow-y-auto">
                    {gameResults && gameResults.length > 0 ? gameResults.map((item, index) => (
                        <div key={index} className="mb-4 p-4 border rounded-md shadow-md text-xs">
                            <div className="mb-2">
                                <strong>Date: </strong>{item.date}
                            </div>
                            <div className="grid grid-cols-1">
                                {item.results.map((result, idx) => (
                                    <div key={idx} className="p-2 border flex justify-between rounded-md shadow-sm my-1">
                                        <div>
                                            {moment(result.gameTime, "HH:mm").format("hh:mm A")}
                                        </div>
                                        <div>{result?.jodiNumber}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )) : (
                        <div className="text-center w-full">No Data Found</div>
                    )}
                </div>
            </div>
        </>

    );
}

export default IndianStarlineResultHistory;
