import React, { useState } from 'react';
import { Route, Switch, withRouter, } from "react-router-dom";
import Header from "../Header";
import LiveGames from "../../pages/LiveGames/LiveGames";
import AccountStatement from "../../pages/AccountStatement/AccountStatement";
import UserHistory from "../../pages/UserHistory/UserHistory";
import BetHistory from "../../pages/BetHistory/BetHistory";
import ProfitAndLoss from "../../pages/ProfitAndLoss/ProfitAndLoss";
import Dashboard from "../../pages/Dashboard/Dashboard";
// import ChangePasswordModal from "./Component/ChangePassword";
import MatchOdds from '../../pages/MatchOdds/MatchOdds';
import Casino from '../../pages/Casino/casino';
import UnsatteledBet from '../../pages/UnsatteledBet/UnsatteledBet'
import changepassword from '../../pages/ChangePassword/changepassword';
import cricketdetails from '../../pages/CricketDetails/cricketdetails';
import matches from '../../pages/Matches/matches';
import MyLedger from '../../pages/MyLedger/MyLedger';
import SattaEvents from '../../pages/SattaEvents/SattaEvents';
// import Footer from '../../components/Footer';npm
import SattaEventId from '../../pages/SattaEventId/SattaEventId';
import Events from '../../pages/Events/Events';
import gameDetails from '../../pages/gameDetails/gameDetails';
import MatkaDetails from '../../pages/MatkaDetails/MatkaDetails';
import MatkaSingle from '../../pages/MatkaSingle/MatkaSingle';
import MatkaDoublePatti from '../../pages/MatkaDoublePatti/MatkaDoublePatti';
import MatkaSinglePatti from '../../pages/MatkaSinglePatti/MatkaSinglePatti';
import MatkaTriplePatti from '../../pages/MatkaTriplePatti/MatkaTriplePatti';
import MatkaJodi from '../../pages/MatkaJodi/MatkaJodi';
import MatkaOddEven from '../../pages/MatkaOddEven/MatkaOddEven';
import Ledger from '../../pages/Ledger/Ledger';
// import WalletHistory from '../../pages/WalletHistory/WalletHistory';
import Sidebar from '../Sidebar/Sidebar';
import UserBidHistory from '../../pages/BetHistory/BetHistory';
import Profile from '../../pages/Profile/Profile';
import WinHistory from '../../pages/WinHistory/WinHistory';
import DepositeAmount from '../../pages/DepositeAmount/DepositeAmount';
import WithdrawAmount from '../../pages/WithdrawAmount/WithdrawAmount';
import TransferPoints from '../../pages/TransferPoints/TransferPoints';
import TransictionHistory from '../../pages/TransictionHistory/TransictionHistory';
import AdminDetails from '../../pages/Profile/AdminDetails';
import HowToPlay from '../../pages/HowToPlay/HowToPlay';
import Chart from '../../pages/Chart/Chart';
import Chartrr from '../../pages/Chartrr/Chartrr';
import DashboardMainPage from '../../pages/DashboardMainPage/DashboardMainPage';
import KolkataSingle from '../../pages/KolkataSingle/KolkataSingle';
import KolkataMatkaSingle from '../../pages/KolkataMatkaSingle/KolkataMatkaSingle';
import KolkataFatafatChart from '../../pages/KolkataFatafatChart/KolkataFatafatChart';
import Rate from '../../pages/Rate/Rate';
import BrandsList from '../../pages/BrandsList/BrandsList';
import Ledgers from '../../pages/TransictionHistory/Ledgers';
import MatkaSingleBulk from '../../pages/MatkaSingleBulk/MatkaSingleBulk';
import MatkaSinglePana from '../../pages/MatkaSinglePana/MatkaSinglePana';
import MatkaSinglePanaBulk from '../../pages/MatkaSinglePanaBulk/MatkaSinglePanaBulk';
import MatkaDoublePana from '../../pages/MatkaDoublePana/MatkaDoublePana';
import MatkaDoublePanaBulk from '../../pages/MatkaDoublePanaBulk/MatkaDoublePanaBulk';
import TriplePana from '../../pages/TriplePana/TriplePana';
import Jodi from '../../pages/Jodi/Jodi';
import RedJodi from '../../pages/RedJodi/RedJodi';
import JodiBulk from '../../pages/JodiBulk/JodiBulk';
import OddEven from '../../pages/OddEven/OddEven';
import PanaFamily from '../../pages/PanaFamily/PanaFamily';
import JodiFamily from '../../pages/JodiFamily/JodiFamily';
import TwoDigitPana from '../../pages/TwoDigitPana/TwoDigitPana';
import FullSangam from '../../pages/FullSangam/FullSangam';
import HalfSangamA from '../../pages/HalfSangamA/HalfSangamA';
import HalfSangamB from '../../pages/HalfSangamB/HalfSangamB';
import JodiListIndian from '../../pages/JodiListIndian/JodiListIndian';
import BidHistory from '../../pages/BetHistory/Component/BidHistory';
import Funds from '../../pages/Funds/Funds';
import Notification from '../../pages/Notification/Notification';
import JodiChart from '../../pages/Jodi Chart/JodiChart';
import GameResult from '../../pages/Game Result/GameResult';
import IndianJackpotResultHiastory from '../../pages/Indian jackpot/IndianJackpotResultHiastory';
import IndianJackpotBidResult from '../../pages/Indian Jackpot Bid Result/IndianJackpotBidResult';
import IndianStarlineBidHiatory from '../../pages/Indian Starline  Hsitory/IndianStarlineBidHiatory';
import IndianStarlineResultHistory from '../../pages/Indian Starline Result History/IndianStarlineResultHistory';
import AddFunds from '../../pages/Funds/Component/AddFunds';
import Withdraw from '../../pages/Withdraw/Withdraw';
import BankDetails from '../../pages/Bank Details/BankDetails';
import AddFundsHistory from '../../pages/Add Funds History/AddFundsHistory';
import TimeTable from '../../pages/Time Table/TimeTable';
import NoticeBoard from '../../pages/Notice Board/NoticeBoard';
import settings from '../../pages/settings/settings';
import jackpotChart from '../../pages/Jackpot-chart/jackpotChart';
import starlineChart from '../../pages/Starline Chart/starlineChart';
import NumberInputComponent from '../../pages/NumberInputComponent/NumberInputComponent';
import SpMotor from '../../pages/SpMotor/SpMotor';
import MatkaDetails2 from '../../pages/MatkaDetails/MatkaDetails2';
import DpMotor from '../../pages/DpMotor/DpMotor';
import WithdrawFundsHistory from '../../pages/Funds/Component/WithdrawFundsHistory';
import SPDPTP from '../../pages/SPDPTP/SPDPTP';
import DigitJodi from '../../pages/DigitJodi/DigitJodi';

function Layout(props) {
  const [open, setOpen] = useState(false);

  const [openMobile, setOpenMobile] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [opensizebar, setopensizebar] = useState(false);


  // console.log(opensizebar)

  return (
    <div className="overflow-hidden h-screen md:flex bg-black justify-center  "  >

      <div className="overflow-hidden   md:w-[25rem] mx-auto sm:w-[22rem] ">
        {/* <Header history={props.history}
          setOpen={setOpen} open={open}
          setOpenMobile={setOpenMobile}
          openMobile={openMobile}
          openModal={openModal} setOpenModal={setOpenModal}
          setopensizebar={setopensizebar} opensizebar={opensizebar}  
        /> */}
        <div className="md:flex h-screen relative z-20 justify-center overflow-hidden ">

          <div className={opensizebar ? ' absolute z-50  transition-all duration-300 ease-in-out top-0 left-0 w-full bg-black bg-opacity-80 ' : 'absolute  transition-all duration-300 ease-in-out z-50 top-0 left-0 w-0 bg-black bg-opacity-80'}>

            <Sidebar setopensizebar={setopensizebar} opensizebar={opensizebar} />
          </div>
          <div className=" min-w-full  h-full">
            <Switch>
              <Route path="/app/ac-statement" component={withRouter(AccountStatement)} />
              <Route path="/app/userhistory" component={withRouter(UserHistory)} />
              <Route path="/app/my-bets" component={withRouter(UserBidHistory)} />
              <Route path="/app/matka_ledger" component={withRouter(Ledger)} />
              <Route path="/app/wallet_history" component={withRouter(TransictionHistory)} />
              <Route path="/app/ledger" component={withRouter(Ledgers)} />
              <Route path="/app/unsetteled-bet" component={withRouter(UnsatteledBet)} />
              <Route path="/app/profit-loss" component={withRouter(ProfitAndLoss)} />
              <Route path="/app/dashboard/:brandId" component={withRouter(Dashboard)} />
              <Route path="/app/IndianJackPot" component={withRouter(JodiListIndian)} />
              <Route path="/app/bidhistory" component={withRouter(BidHistory)} />
              <Route path="/app/game-result" component={withRouter(GameResult)} />
              <Route path="/app/indian-jackpot-result" component={withRouter(IndianJackpotResultHiastory)} />
              <Route path="/app/indian-jackpot-bid-result" component={withRouter(IndianJackpotBidResult)} />
              <Route path="/app/indian-starline-history" component={withRouter(IndianStarlineBidHiatory)} />
              <Route path="/app/indian-starline-result-history" component={withRouter(IndianStarlineResultHistory)} />
              <Route path="/app/jackpot-chart" component={withRouter(jackpotChart)} />
              <Route path="/app/starline-chart" component={withRouter(starlineChart)} />
              <Route path="/app/example" component={withRouter(NumberInputComponent)} />


              <Route
                path="/app/dashboard"
                render={(routeProps) => (
                  <DashboardMainPage
                    {...routeProps}
                    history={props.history}
                    setOpen={setOpen}
                    open={open}
                    setOpenMobile={setOpenMobile}
                    openMobile={openMobile}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    setopensizebar={setopensizebar}
                    opensizebar={opensizebar}
                  />
                )} matkaDetails
              />


              <Route path="/app/BrandsList" component={withRouter(BrandsList)} />
              <Route path="/app/match" component={withRouter(MatchOdds)} />
              <Route path="/app/casino" component={withRouter(Casino)} />
              <Route path="/app/win_history" component={withRouter(WinHistory)} />
              <Route path="/app/funds" component={withRouter(Funds)} />
              <Route path="/app/notification" component={withRouter(Notification)} />

              <Route path="/app/my_profile" component={withRouter(Profile)} />
              <Route path="/app/add-fund" component={withRouter(AddFunds)} />
              <Route path="/app/withdraw-fund" component={withRouter(Withdraw)} />
              <Route path="/app/bank-details" component={withRouter(BankDetails)} />
              <Route path="/app/funds-history" component={withRouter(AddFundsHistory)} />
              <Route path="/app/withdraw-history" component={withRouter(WithdrawFundsHistory)} />

              <Route path="/app/time-table" component={withRouter(TimeTable)} />
              <Route path="/app/notice-board" component={withRouter(NoticeBoard)} />
              <Route path="/app/settings" component={withRouter(settings)} />




              <Route path="/app/adminBank" component={withRouter(AdminDetails)} />
              <Route path="/app/changepassword" component={withRouter(changepassword)} />
              <Route path="/app/howtoPlay" component={withRouter(HowToPlay)} />
              <Route path="/app/chart" component={withRouter(Chart)} />
              <Route path="/app/gameRate" component={withRouter(Rate)} />
              <Route path="/app/charts/:gameId/:gameName" component={withRouter(Chartrr)} />
              <Route path="/app/chartjodi/:gameId/:gameName" component={withRouter(JodiChart)} />

              <Route path="/app/livegames" component={withRouter(LiveGames)} />
              <Route path="/app/my-ledger" component={withRouter(MyLedger)} />
              <Route path="/app/panel_chart" component={withRouter(SattaEvents)} />
              <Route path="/app/deposite_amount" component={withRouter(DepositeAmount)} />
              <Route path="/app/withdraw" component={withRouter(WithdrawAmount)} />
              <Route path="/app/transfer_points" component={withRouter(TransferPoints)} />
              <Route path="/app/satta-events-id" component={withRouter(SattaEventId)} />
              <Route path="/app/events" component={withRouter(Events)} />
              <Route path="/app/matches/:sportId?/:seriesId?" component={withRouter(matches)} />
              <Route path="/app/cricket-details/:sportId?/:seriesId?/:match_id?/:marketId?" component={withRouter(cricketdetails)} />
              <Route path="/app/gameDetails/:market_id" component={withRouter(gameDetails)} />
              <Route path="/app/matkaDetails2/:gameName/:gameId/" component={withRouter(MatkaDetails2)} />
              <Route path="/app/matkaDetails/:gameName/:gameId/:isOpen" component={withRouter(MatkaDetails)} />



              <Route path="/app/SingleDigit/:gameName/:gameId/:marketId/:isOpen" component={withRouter(MatkaSingle)} />
              <Route path="/app/SingleDigitBulk/:gameName/:gameId/:marketId/:isOpen" component={withRouter(MatkaSingleBulk)} />
              <Route path="/app/SinglePana/:gameName/:gameId/:marketId/:isOpen" component={withRouter(MatkaSinglePana)} />
              <Route path="/app/SinglePanaBulk/:gameName/:gameId/:marketId/:isOpen" component={withRouter(MatkaSinglePanaBulk)} />
              <Route path="/app/DoublePana/:gameName/:gameId/:marketId/:isOpen" component={withRouter(MatkaDoublePana)} />
              <Route path="/app/DoublePanaBulk/:gameName/:gameId/:marketId/:isOpen" component={withRouter(MatkaDoublePanaBulk)} />
              <Route path="/app/TripplePanna/:gameName/:gameId/:marketId/:isOpen" component={withRouter(TriplePana)} />

              <Route path="/app/Jodi/:gameName/:gameId/:marketId/:isOpen" component={withRouter(Jodi)} />
              <Route path="/app/RedJodi/:gameName/:gameId/:marketId/:isOpen" component={withRouter(RedJodi)} />
              <Route path="/app/JodiBulk/:gameName/:gameId/:marketId/:isOpen" component={withRouter(JodiBulk)} />

              <Route path="/app/matkaOddEven/:gameName/:gameId/:marketId/:isOpen" component={withRouter(OddEven)} />
              <Route path="/app/sp_Motor/:gameName/:gameId/:marketId/:isOpen" component={withRouter(SpMotor)} />
              <Route path="/app/SPDPTP/:gameName/:gameId/:marketId/:isOpen" component={withRouter(SPDPTP)} />
              <Route path="/app/DpMotor/:gameName/:gameId/:marketId/:isOpen" component={withRouter(DpMotor)} />
              <Route path="/app/Pannafamily/:gameName/:gameId/:marketId/:isOpen" component={withRouter(PanaFamily)} />

              <Route path="/app/JodiFamily/:gameName/:gameId/:marketId/:isOpen" component={withRouter(JodiFamily)} />
              <Route path="/app/DigitJodi/:gameName/:gameId/:marketId/:isOpen" component={withRouter(DigitJodi)} />
              
              <Route path="/app/TwoDigitPana/:gameName/:gameId/:marketId/:isOpen" component={withRouter(TwoDigitPana)} />

              <Route path="/app/FullSangam/:gameName/:gameId/:marketId/:isOpen" component={withRouter(FullSangam)} />
              <Route path="/app/HalfSangamA/:gameName/:gameId/:marketId/:isOpen" component={withRouter(HalfSangamA)} />
              <Route path="/app/HalfSangamB/:gameName/:gameId/:marketId/:isOpen" component={withRouter(HalfSangamB)} />
              <Route path="/app/matkaJodi/:gameName/:gameId/:marketId/:isOpen" component={withRouter(MatkaJodi)} />
              
              <Route path="/app/matkaSinglePatti/:gameName/:gameId/:marketId/:isOpen" component={withRouter(MatkaSinglePatti)} />
              <Route path="/app/matkaDoublePatti/:gameName/:gameId/:marketId/:isOpen" component={withRouter(MatkaDoublePatti)} />
              <Route path="/app/matkaTriplePatti/:gameName/:gameId/:marketId/:isOpen" component={withRouter(MatkaTriplePatti)} />
                          
              {/* ------------Kolkata--------- */}
              <Route path="/app/kolkataSingle/:gameName/:gameId/:marketId/:isOpen" component={withRouter(KolkataSingle)} />
              <Route path="/app/fatafat/:BrandName/:id" component={withRouter(KolkataFatafatChart)} />
              <Route path="/app/kolkataSinglePanna/:gameName/:gameId/:marketId/:isOpen" component={withRouter(KolkataMatkaSingle)} />

            </Switch>
          </div>
        </div>
        {/* <Footer props={props} /> */}
      </div>

    </div>
  );
}

export default withRouter(Layout);
