import React, { useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useSelect } from "react-select-search";
import { starlineAction, userActions } from "../../_actions";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Loader from "../../components/Loader/Loader";
import { FaCheckCircle, FaHashtag, FaHourglassHalf, FaTags } from "react-icons/fa";
import { RiFilterFill } from "react-icons/ri";
import { type } from "@testing-library/user-event/dist/type";


function IndianStarlineBidHiatory() {
  const selector = useSelector((state) => state);
  const dispatch = useDispatch();
  const [offset, setoffset] = useState(0);
  const [size, setsize] = useState(25);
  const [page, setpage] = useState(1);
  const [index, setindex] = useState(0);
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [isOpen, setISOpen] = useState(false)
  const [openGame, setOpenGame] = useState(false);
  const [closeGame, setCloseGame] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [gameSelectId,setGameId]=useState('')

  // console.log("datatatattatatatta",selector?.users?.indianStarlinegames)

  useEffect(() => {
    let data = {
      gameId: "",
      fromDate: fromDate,
      toDate: toDate,
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: page,
      size: size,
    };
    dispatch(starlineAction.getBetsListStarLine(data));
    let gameReqData = {
      // "keyWord": "",
      pageNo:page,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: "",
      toDate: "",
      size: 200,
    };

    dispatch(userActions.getAllbrandGame(gameReqData));
  }, [page, toDate]);

  const handleChange = async (e) => {
    if (page !== 1) {
      setpage(1);
    }
    setfromDate(e.target.value);
    settoDate(e.target.value);
  };

  let handlePageClick = (data) => {
    console.log("data  ", data);
    setindex(data.selected);
    let offset = Math.ceil(data.selected * size);
    setoffset(offset);
    setpage(data.selected + 1);
  };
  const goBack = () => {
    window.history.back();
  };
  let { starline } = selector ? selector : {};
  let { getStarlineBetList, getStarlineBetTotal } = starline ? starline : {};

  const toggleFilter = () => {
    setISOpen(!isOpen)
    setSelectedValues('')
    setGameId('')
    setCloseGame('')
    setOpenGame('')
  }

  const handleOpenChange = () => {
    setOpenGame(!openGame);
  };

  // Function to handle 'Close' checkbox change
  const handleCloseChange = () => {
    setCloseGame(!closeGame);
  };


  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const valueMap = {
      win: 1,
      loss: 2,
      pending: 0,
    };

    let updatedSelectedValues = [...selectedValues];

    if (checked) {
      if (!updatedSelectedValues.includes(valueMap[name])) {
        updatedSelectedValues.push(valueMap[name]);
      }
    } else {
      updatedSelectedValues = updatedSelectedValues.filter(
        (val) => val !== valueMap[name]
      );
    }

    setSelectedValues(updatedSelectedValues);
  };
  

  const handleChangeSelect=(e)=>{
    let gameID=e.target.value
    setGameId(gameID)


  }
  const submitFilterData = () => {
    let value = '';
    if (openGame && closeGame) {
      value = '';
    } else if (openGame) {
      value = true;
    } else if (closeGame) {
      value = false;
    }

    let formData={
      gameId: gameSelectId,
      fromDate: fromDate,
      status:selectedValues,
      type:value,
      toDate: toDate,
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: page,
      size: size,
    }
    dispatch(starlineAction.getBetsListStarLine(formData));

    setISOpen(!isOpen)
    setSelectedValues('')
    setGameId('')
    setCloseGame('')
    setOpenGame('')

  };

  return (
    <>


      <Loader Loader={selector?.starline?.loading} />
      <div className="bg-white text-black min-h-screen">
        <div className="h-12 w-full border bg-[#024CAA] rounded-b-3xl flex justify-between text-white place-items-center px-4 gap-3">
          <IoArrowBackOutline
            className="text-white mb-1 text-2xl cursor-pointer"
            onClick={goBack}
          />
          <div className="  font-semibold text-white cursor-pointer uppercase">
            Starline bid History
          </div>
          <div className="flex justify-start items-center gap-2">
            <span className="text-sm">Filter by</span>
            <RiFilterFill
              className="cursor-pointer"
              onClick={toggleFilter}
            />

            {isOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                {/* Background Overlay */}
                <div
                  className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
                  onClick={toggleFilter} // Close modal when clicking outside
                ></div>

                {/* Modal Content */}
                <div className="relative w-80 bg-white border text-black border-gray-300 rounded-lg shadow-lg z-50">

                  <div className="text-center font-semibold mb-4">
                    <h4>Filter Type</h4>
                  </div>
                  <div className="border-t border-gray-300 p-4 space-y-4">
                    <div className="flex justify-center gap-4 items-center ">
                      <div className="flex items-center gap-2">
                        <label className="text-sm">Open</label>
                        <input
                          type="checkbox"
                          className="border border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
                          name="open"
                          id="openCheckbox"
                          checked={openGame}
                          onChange={handleOpenChange}
                        />
                      </div>
                      <div className="flex items-center gap-2">
                        <label className="text-sm">Close</label>
                        <input
                          type="checkbox"
                          className="border border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
                          name="close"
                          id="closeCheckbox"
                          checked={closeGame}
                          onChange={handleCloseChange}
                        />
                      </div>
                    </div>

                    <div>
                      <div className="flex justify-between">
                        <div className="flex items-center gap-2">
                          <label className="text-sm">Win</label>
                          <input
                            type="checkbox"
                            className="border border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
                            name="win"
                            id="winCheckbox"
                            onChange={handleCheckboxChange}
                          />
                        </div>
                        <div className="flex items-center gap-2">
                          <label className="text-sm">Loss</label>
                          <input
                            type="checkbox"
                            className="border border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
                            name="loss"
                            id="lossCheckbox"
                            onChange={handleCheckboxChange}
                          />
                        </div>
                        <div className="flex items-center gap-2">
                          <label className="text-sm">Pending</label>
                          <input
                            type="checkbox"
                            className="border border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
                            name="pending"
                            id="pendingCheckbox"
                            onChange={handleCheckboxChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="">
                          <label className="text-sm">Game Name</label>
                          <select
                            onChange={handleChangeSelect}
                            name="sports_id"
                            className="w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                          >
                            <option value="">All</option>
                            {selector?.users?.indianStarlinegames && selector?.users?.indianStarlinegames.length > 0
                              ? selector?.users?.indianStarlinegames?.map((element, index) => (
                                <option key={index} value={element && element._id} className="capitalize">
                                  {element && element.name}
                                </option>
                              ))
                              : null}
                          </select>
                        </div>
                    <div className="flex justify-center items-center mt-4 gap-4">
                      <button
                        className="py-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-700"
                        onClick={submitFilterData}
                      >
                        Submit
                      </button>

                      <button className="py-2 px-4 bg-red-600 text-white rounded hover:bg-red-700" onClick={toggleFilter} >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="p-4 overflow-hidden overflow-y-auto h-[90vh]">
          <div className="">
            <div className="flex flex-col gap-5  h-full ">
              {getStarlineBetList &&
                getStarlineBetList.length > 0 &&
                getStarlineBetList.map((element, index) => {
                  return (


                    <div className="max-w-sm w-full mx-auto bg-white shadow-lg rounded-lg overflow-hidden border border-gray-300">

                      <div className="bg-[#024CAA] text-white text-center py-2">
                        <p className="text-base font-semibold">
                          {moment(new Date(parseInt(element.createdAt)))
                            .utcOffset("+05:30")
                            .format("hh:mm A")}
                          ( {element?.marketId?.name || "-"}, {element?.isOpen ? "Open" : "Close"}) </p>
                      </div>


                      <div className="p-4">

                        <div className="flex justify-center place-items-center gap-4">
                          <p>Game Name:</p>
                          <p className=" capitalize">{element?.gameId?.name}</p>
                        </div>

                        <div className="mt-4 grid grid-cols-2 text-center gap-2">
                          <div>
                            <p className="text-xs text-gray-500">Point</p>
                            <p
                              className="text-lg font-semibold text-blue-600"
                            >
                              {element?.amount || "-"}
                            </p>
                          </div>
                          <div>
                            <p className="text-xs text-gray-500">Digit</p>
                            <p className="text-lg font-semibold text-blue-600">{element?.betNumber || "-"}</p>
                          </div>

                        </div>

                        {/* Transaction time */}
                        <p className="text-sm text-gray-700 mt-4 flex justify-center place-items-center gap-4">
                          <span className="text-black">Transaction Time:</span>
                          <span className=" px-2">

                            {moment(new Date(parseInt(element.createdAt)))
                              .utcOffset("+05:30")
                              .format("DD-MM-YYYY (ddd)")}
                          </span>
                        </p>

                        {/* Winning message */}
                        <p className="text-green-600 text-center mt-4">
                          {element?.betStatus === 0 && <div className="text-yellow-500">Pending</div>}
                          {element?.betStatus === 1 && <div className="text-green-800">YOu Won Rs. +{element?.winAmount}  🤩</div>}
                          {element?.betStatus === 2 && <div className="text-red-700">Better Luck Next Time!!! 😔 -{element?.lossAmount}</div>}
                        </p>
                      </div>
                    </div>

                  );
                })}

              <nav className="flex justify-end mt-4 w-full">
                {getStarlineBetTotal && getStarlineBetTotal > size ? (
                  <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={getStarlineBetTotal / size}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    pageClassName={"page-cls"}
                    activeClassName={"active"}
                  />
                ) : null}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IndianStarlineBidHiatory;
