import { userConstants } from "../_constants";
import { bet } from "../_reducers/bet.reducer";
import { userService } from "../_services";
import { alertActions } from "./";
//import { history } from '../_helpers';
export const userActions = {
  ClearUser,
  login,
  logout,
  marketAnalysisDashboard,
  childListActiveUser,
  activeSport,
  wallet_balance,
  game_profile,
  changePassword,
  games_list,
  registerValidateOtp,
  getGamesList,
  event_game_list,
  getStatement,
  event_game,
  getProfitAndLoss,
  event_fun_csno,
  event_casino,
  last_result,
  event_tennis,
  event_footbal,
  save_bet,
  save_ssn_bet,
  list_fn_match,
  save_csn_bet,
  list_bt_ssn_mk,
  get_match_score,
  single_click_update_amount,
  event_detals,
  getScore,
  event_session,
  getMarket,
  lucky7AData,
  userUpdate,
  userAccountDetails,
  getProfile,
  getClientBankDetail,
  MatchList,
  getMarketListByGameId,
  getMarketByMarketId,
  placeBet,
  getBetsList,
  transactionHistory,
  getAllSlider,
  updateUser,
  updateBankDetail,
  saveClientPayment,
  withdrawPaymentRequest,
  transferPointOneToAnother,
  getGamesByGamesIdForUser,
  getAllAppSetting,
  userRegister,
  forgotPasswordValidateOtp,
  forgotPassword,
  uploadImage,
  clearImage,
  getAdminBankDetailForUser,
  deleteUser,
  getAllBrandForAdmin,
  getBrandByGameIdForAdmin,
  clear,
  getMarketRate,
  todayBets,
  getAllbrandGame,
  getAllJodiGame,
  placebetMarketListforSimplGames,
};

function clear() {
  return {
    type: userConstants.CLEAR,
  };
}
function ClearUser() {
  return {
    type: userConstants.CLEAR_USER,
  };
}
function clearImage() {
  return {
    type: userConstants.CLEAR_IMAGE,
  };
}

function todayBets(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.todayBets(data).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(user) {
    return { type: userConstants.TODAY_BETS_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.TODAY_BETS_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.TODAY_BETS_FAILURE, error };
  }
}
function getAllbrandGame(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.getAllbrandGame(data).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };
  function request(user) {
    return { type: userConstants.GET_ALL_BG_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GET_ALL_BG_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_ALL_BG_FAILURE, error };
  }
}
function getAllJodiGame(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.getAllJodiGame(data).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };
  function request(user) {
    return { type: userConstants.GET_JODI_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GET_JODI_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_JODI_FAILURE, error };
  }
}
function getBrandByGameIdForAdmin(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.getBrandByGameIdForAdmin(data).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(user) {
    return { type: userConstants.GET_BRAND_GAME_ID_FROM_ADMIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GET_BRAND_GAME_ID_FROM_ADMIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_BRAND_GAME_ID_FROM_ADMIN_FAILURE, error };
  }
}

function getMarketRate(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.getMarketRate(data).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(user) {
    return { type: userConstants.GAME_RATE_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GAME_RATE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GAME_RATE_FAILURE, error };
  }
}
function getAllBrandForAdmin(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.getAllBrandForAdmin(data).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(user) {
    return { type: userConstants.GET_ALL_BRAND_FOR_ADMIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GET_ALL_BRAND_FOR_ADMIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_ALL_BRAND_FOR_ADMIN_FAILURE, error };
  }
}

function deleteUser(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.deleteUser(data).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(user) {
    return { type: userConstants.ADMIN_BANK_DETAILS_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.ADMIN_BANK_DETAILS_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.ADMIN_BANK_DETAILS_FAILURE, error };
  }
}
function getAdminBankDetailForUser(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.getAdminBankDetailForUser(data).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(user) {
    return { type: userConstants.ADMIN_BANK_DETAILS_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.ADMIN_BANK_DETAILS_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.ADMIN_BANK_DETAILS_FAILURE, error };
  }
}
function transferPointOneToAnother(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.transferPointOneToAnother(data).then(
      (user) => {
        dispatch(success(user));
        dispatch(userActions.getProfile());
        dispatch(alertActions.success("Point transfer successful"));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(user) {
    return { type: userConstants.TRANSFERPOINTS_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.TRANSFERPOINTS_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.TRANSFERPOINTS_FAILURE, error };
  }
}
function uploadImage(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.uploadImage(data).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(user) {
    return { type: userConstants.UPLOAD_IMAGE__REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.UPLOAD_IMAGE__SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.UPLOAD_IMAGE__FAILURE, error };
  }
}
function forgotPassword(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.forgotPassword(data).then(
      (user) => {
        dispatch(success(user));
        dispatch(alertActions.success(user?.getMarketList?.message));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(user) {
    return { type: userConstants.FORGOT_PASSWORD_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.FORGOT_PASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.FORGOT_PASSWORD_FAILURE, error };
  }
}
function userRegister(data, callback) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.userRegister(data).then(
      (user) => {
        dispatch(success(user));
        // dispatch(alertActions.success(user?.getMarketList?.message));
        // setOtpFeilds(true)
        callback();
      },
      (error) => {
        dispatch(failure(error));
        // setOtpFeilds(false)
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_USER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_USER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_USER_FAILURE, error };
  }
}
function registerValidateOtp(data, history) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.registerValidateOtp(data).then(
      (user) => {
        dispatch(success(user));
        dispatch(alertActions.success("Registered successfully"));
        history.push(`/login`);
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_OTP_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_OTP_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_OTP_FAILURE, error };
  }
}
function withdrawPaymentRequest(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.withdrawPaymentRequest(data).then(
      (user) => {
        dispatch(success(user));
        console.log(user);

        dispatch(alertActions.success("Withdraw payment request successfully"));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(user) {
    return { type: userConstants.SAVE_CLIENT_PAYMENT_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.SAVE_CLIENT_PAYMENT_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.SAVE_CLIENT_PAYMENT_FAILURE, error };
  }
}

function forgotPasswordValidateOtp(data, props) {
  console.log(props);

  return (dispatch) => {
    dispatch(request({ data }));
    userService.forgotPasswordValidateOtp(data).then(
      (user) => {
        dispatch(success(user));
        dispatch(alertActions.success("Password updated successfully"));

        props.history.push(`/login`);
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(user) {
    return { type: userConstants.FORGOT_PASSWORD_VALID_OTP_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.FORGOT_PASSWORD_VALID_OTP_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.FORGOT_PASSWORD_VALID_OTP_FAILURE, error };
  }
}
function getGamesByGamesIdForUser(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.getGamesByGamesIdForUser(data).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(user) {
    return { type: userConstants.GET_GAME_BY_GAMEID_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GET_GAME_BY_GAMEID_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_GAME_BY_GAMEID_FAILURE, error };
  }
}

function saveClientPayment(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.saveClientPayment(data).then(
      (user) => {
        dispatch(success(user));
        console.log(user);
        dispatch(userActions.getProfile());
        dispatch(userActions.getClientBankDetail());
        dispatch(userActions.clearImage());
        dispatch(alertActions.success("Wait for Admin To verify"));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(user) {
    return { type: userConstants.SAVE_CLIENT_PAYMENT_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.SAVE_CLIENT_PAYMENT_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.SAVE_CLIENT_PAYMENT_FAILURE, error };
  }
}

function updateBankDetail(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.updateBankDetail(data).then(
      (user) => {
        dispatch(success(user));
        dispatch(userActions.getProfile());
        dispatch(userActions.getClientBankDetail());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(user) {
    return { type: userConstants.UPDATE_USER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.UPDATE_USER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_USER_FAILURE, error };
  }
}
function updateUser(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.updateUser(data).then(
      (user) => {
        dispatch(success(user));
        dispatch(userActions.getProfile());
        dispatch(userActions.getClientBankDetail());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(user) {
    return { type: userConstants.UPDATE_USER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.UPDATE_USER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_USER_FAILURE, error };
  }
}
function getAllAppSetting(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.getAllAppSetting(data).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(user) {
    return { type: userConstants.GET_ALL_APP_SETING_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GET_ALL_APP_SETING_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_ALL_APP_SETING_FAILURE, error };
  }
}
function getAllSlider(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.getAllSlider(data).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(user) {
    return { type: userConstants.GET_ALL_SLIDER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GET_ALL_SLIDER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_ALL_SLIDER_FAILURE, error };
  }
}
function login(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.login(data).then(
      (user) => {
        dispatch(success(user));
        dispatch(alertActions.success("Login Successfully."));

        props.history.push(`/#/app/dashboard`);
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_FIRST_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_FIRST_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FIRST_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function getMarketListByGameId(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getMarketListByGameId(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.MARKET_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.MARKET_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.MARKET_LIST_FAILURE, error };
  }
}

function getMarketByMarketId(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getMarketByMarketId(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.GET_MARKET_BY_MARKETID_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_MARKET_BY_MARKETID_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_MARKET_BY_MARKETID_FAILURE, error };
  }
}

function getClientBankDetail(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getClientBankDetail(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.GET_BANK_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_BANK_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_BANK_FAILURE, error };
  }
}
function getProfile(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getProfile(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.GET_PROFILE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_PROFILE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_PROFILE_FAILURE, error };
  }
}

function userAccountDetails(data) {
  return (dispatch) => {
    dispatch(request());
    userService.userAccountDetails(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.USER_ACCOUNT_DETAILS_REQUEST };
  }
  function success(users) {
    return { type: userConstants.USER_ACCOUNT_DETAILS_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.USER_ACCOUNT_DETAILS_FAILURE, error };
  }
}

function marketAnalysisDashboard(data) {
  return (dispatch) => {
    dispatch(request());
    userService.marketAnalysisDashboard(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.MARKET_ANALYSIS_DASHBORD_REQUEST };
  }
  function success(users) {
    return { type: userConstants.MARKET_ANALYSIS_DASHBORD_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.MARKET_ANALYSIS_DASHBORD_FAILURE, error };
  }
}

function placeBet(data, props) {
  return (dispatch) => {
    dispatch(request());
    userService.placeBet(data).then(
      (users) => {
        dispatch(
          alertActions.success(
            "Bet Placed Successfully",
            "Your bid has been placed and confirmed. Good luck!",
          ),
        );
        props.history.goBack();
        dispatch(success(users));
        dispatch(userActions.getProfile());
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.PLACE_BET_REQUEST };
  }
  function success(users) {
    return { type: userConstants.PLACE_BET_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.PLACE_BET_FAILURE, error };
  }
}

function userUpdate(data) {
  return (dispatch) => {
    dispatch(request());
    userService.userUpdate(data).then(
      (users) => {
        dispatch(alertActions.success("Your Referance is updated."));
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.USER_UPDATE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.USER_UPDATE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.USER_UPDATE_FAILURE, error };
  }
}

function changePassword(data, eventData) {
  return (dispatch) => {
    dispatch(request());
    userService.changePassword(data).then(
      (users) => {
        let message =
          users && users.changePassword ? users.changePassword : null;
        dispatch(alertActions.success(message));
        dispatch(success(users));
        // dispatch(this.childListActiveUserCredit(eventData));
        // dispatch(this.childListUser(eventData));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.CHANGE_PASSWORD_REQUEST };
  }
  function success(users) {
    return { type: userConstants.CHANGE_PASSWORD_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.CHANGE_PASSWORD_FAILURE, error };
  }
}

function getStatement(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getStatement(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.STATEMENT_REQUEST };
  }
  function success(users) {
    return { type: userConstants.STATEMENT_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.STATEMENT_FAILURE, error };
  }
}

function getMarket(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getMarket(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.GET_MARKET_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_MARKET_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_MARKET_FAILURE, error };
  }
}

function lucky7AData(data) {
  return (dispatch) => {
    dispatch(request());
    userService.lucky7AData(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.GET_MARKET_CASINO_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_MARKET_CASINO_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_MARKET_CASINO_FAILURE, error };
  }
}

function getProfitAndLoss(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getProfitAndLoss(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.PROFIT_AND_LOSS_REQUEST };
  }
  function success(users) {
    return { type: userConstants.PROFIT_AND_LOSS_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.PROFIT_AND_LOSS_FAILURE, error };
  }
}

function MatchList(data) {
  return (dispatch) => {
    dispatch(request());
    userService.MatchList(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.MATCH_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.MATCH_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.MATCH_LIST_FAILURE, error };
  }
}

function getGamesList(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getGamesList(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.GAME_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GAME_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GAME_LIST_FAILURE, error };
  }
}

function getBetsList(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getBetsList(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.GET_BET_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_BET_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_BET_LIST_FAILURE, error };
  }
}

function transactionHistory(data) {
  return (dispatch) => {
    dispatch(request());
    userService.transactionHistory(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.GET_TRANSCTION_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_TRANSCTION_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_TRANSCTION_LIST_FAILURE, error };
  }
}

function games_list(data) {
  return (dispatch) => {
    dispatch(request());
    userService.games_list(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.GAME_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GAME_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GAME_LIST_FAILURE, error };
  }
}

function event_game_list(data) {
  return (dispatch) => {
    dispatch(request());
    userService.event_game_list(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.EVENT_GAME_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.EVENT_GAME_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.EVENT_GAME_LIST_FAILURE, error };
  }
}

function event_game(data) {
  return (dispatch) => {
    dispatch(request());
    userService.event_game(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.EVENT_GAME_REQUEST };
  }
  function success(users) {
    return { type: userConstants.EVENT_GAME_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.EVENT_GAME_FAILURE, error };
  }
}

function event_casino(data) {
  return (dispatch) => {
    dispatch(request());
    userService.event_casino(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.EVENT_CASINO_REQUEST };
  }
  function success(users) {
    return { type: userConstants.EVENT_CASINO_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.EVENT_CASINO_FAILURE, error };
  }
}

function last_result(data) {
  return (dispatch) => {
    dispatch(request());
    userService.last_result(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.LAST_RESULT_REQUEST };
  }
  function success(users) {
    return { type: userConstants.LAST_RESULT_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.LAST_RESULT_FAILURE, error };
  }
}

function event_tennis(data) {
  return (dispatch) => {
    dispatch(request());
    userService.event_tennis(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.EVENT_TENNIS_REQUEST };
  }
  function success(users) {
    return { type: userConstants.EVENT_TENNIS_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.EVENT_TENNIS_FAILURE, error };
  }
}

function event_footbal(data) {
  return (dispatch) => {
    dispatch(request());
    userService.event_footbal(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.EVENT_FOOTBAL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.EVENT_FOOTBAL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.EVENT_FOOTBAL_FAILURE, error };
  }
}

function save_bet(data, data2) {
  return (dispatch) => {
    dispatch(request());
    userService.save_bet(data).then(
      (users) => {
        dispatch(success(users));
        dispatch(this.list_bt_ssn_mk(data2));
        dispatch(alertActions.success("Bet has been saved successfully."));
        // window.reload();
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.SAVE_BET_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SAVE_BET_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SAVE_BET_FAILURE, error };
  }
}

function save_ssn_bet(data, data2) {
  return (dispatch) => {
    dispatch(request());
    userService.save_ssn_bet(data).then(
      (users) => {
        dispatch(success(users));
        dispatch(this.list_bt_ssn_mk(data2));
        dispatch(alertActions.success("Bet has been saved successfully."));
        // window.reload();
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.SAVE_SSN_BET_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SAVE_SSN_BET_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SAVE_SSN_BET_FAILURE, error };
  }
}

function list_fn_match(data) {
  return (dispatch) => {
    dispatch(request());
    userService.list_fn_match(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.LIST_FN_MATCH_REQUEST };
  }
  function success(users) {
    return { type: userConstants.LIST_FN_MATCH_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.LIST_FN_MATCH_FAILURE, error };
  }
}

function save_csn_bet(data, data2) {
  return (dispatch) => {
    dispatch(request());
    userService.save_csn_bet(data).then(
      (users) => {
        dispatch(success(users));
        dispatch(this.list_bt_ssn_mk(data2));
        dispatch(alertActions.success("Bet has been saved successfully."));
        // window.reload();
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.SAVE_CSN_BET_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SAVE_CSN_BET_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SAVE_CSN_BET_FAILURE, error };
  }
}

function list_bt_ssn_mk(data) {
  return (dispatch) => {
    dispatch(request());
    userService.list_bt_ssn_mk(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.LIST_BT_SSN_REQUEST };
  }
  function success(users) {
    return { type: userConstants.LIST_BT_SSN_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.LIST_BT_SSN_FAILURE, error };
  }
}

function get_match_score(data) {
  return (dispatch) => {
    dispatch(request());
    userService.get_match_score(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.GET_MATCH_SCORE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_MATCH_SCORE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_MATCH_SCORE_FAILURE, error };
  }
}

function event_detals(data) {
  return (dispatch) => {
    dispatch(request());
    userService.event_detals(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.EVENT_DETALS_REQUEST };
  }
  function success(users) {
    return { type: userConstants.EVENT_DETALS_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.EVENT_DETALS_FAILURE, error };
  }
}

function event_session(data) {
  return (dispatch) => {
    dispatch(request());
    userService.event_session(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.EVENT_SESSION_REQUEST };
  }
  function success(users) {
    return { type: userConstants.EVENT_SESSION_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.EVENT_SESSION_FAILURE, error };
  }
}

function getScore(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getScore(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        // dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.GET_SCORE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_SCORE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_SCORE_FAILURE, error };
  }
}

function single_click_update_amount(data) {
  return (dispatch) => {
    dispatch(request());
    userService.single_click_update_amount(data).then(
      (users) => {
        dispatch(success(users));
        dispatch(alertActions.success("Successfully updated."));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.SINGLE_CLICK_UPDATE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SINGLE_CLICK_UPDATE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SINGLE_CLICK_UPDATE_FAILURE, error };
  }
}

function event_fun_csno(data) {
  return (dispatch) => {
    dispatch(request());
    userService.event_fun_csno(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.EVENT_FUN_CSNO_REQUEST };
  }
  function success(users) {
    return { type: userConstants.EVENT_FUN_CSNO_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.EVENT_FUN_CSNO_FAILURE, error };
  }
}

function game_profile(data) {
  return (dispatch) => {
    dispatch(request());
    userService.game_profile(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.GAME_PROFILE_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GAME_PROFILE_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GAME_PROFILE_LIST_FAILURE, error };
  }
}
function wallet_balance(data) {
  return (dispatch) => {
    dispatch(request());
    userService.wallet_balance(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.WALLET_BALANCE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.WALLET_BALANCE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.WALLET_BALANCE_FAILURE, error };
  }
}

function childListActiveUser(data) {
  return (dispatch) => {
    dispatch(request());
    userService.childListActiveUser(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.CHILD_LIST_ACTIVE_USER_REQUEST };
  }
  function success(users) {
    return { type: userConstants.CHILD_LIST_ACTIVE_USER_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.CHILD_LIST_ACTIVE_USER_FAILURE, error };
  }
}

function activeSport() {
  return (dispatch) => {
    dispatch(request());
    userService.activeSport().then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: userConstants.ACTIVE_SPORT_REQUEST };
  }
  function success(users) {
    return { type: userConstants.ACTIVE_SPORT_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.ACTIVE_SPORT_FAILURE, error };
  }
}

function placebetMarketListforSimplGames(data) {
  return (dispatch) => {
    dispatch(request());
    userService.placebetMarketListforSimplGames(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return {
      type: userConstants.PLACEBET_FOR_MARKETLIST_FOR_SIMPLE_GAMES_REQUEST,
    };
  }
  function success(users) {
    return {
      type: userConstants.PLACEBET_FOR_MARKETLIST_FOR_SIMPLE_GAMES_SUCCESS,
      users,
    };
  }
  function failure(error) {
    return {
      type: userConstants.PLACEBET_FOR_MARKETLIST_FOR_SIMPLE_GAMES_FAILURE,
      error,
    };
  }
}
