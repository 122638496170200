import React, { useEffect, useState } from 'react'
import { IoArrowBackOutline } from 'react-icons/io5'
import { MdNotificationsActive } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { notificationAction } from '../../_actions';
import moment from 'moment';
import Loader from '../../components/Loader/Loader';


function Notification() {
    const selector = useSelector(state => state)
    console.log(selector?.notification?.getNotificationList);

    const dispatch = useDispatch()
    const [data, setData] = useState()
    useEffect(() => {
        let dataForNotification = {
            pageNo: '1',
            size: '100'
        }
        dispatch(notificationAction.getNotificationListByUserId(dataForNotification))
    }, [])

    const currentDate = new Date().toLocaleDateString();
    return (
        <>
        <Loader loading={selector?.notification?.loading}/>
            <div className='bg-[#EEEEEE] h-screen  w-full '>
                <div className='flex justify-between place-items-center h-12 w-full border bg-[#024CAA] rounded-b-3xl'>
                    <div className=" flex justify-start place-items-end px-4 gap-3">
                        <IoArrowBackOutline
                            className="text-white mb-1 text-2xl"
                            onClick={() => window.history.back()}
                        />
                        <div className="  font-semibold text-white cursor-pointer">
                            {" "}
                            Notification
                        </div>
                    </div>
                </div>

                <div className=' space-x-1 p-1 h-[90vh] overflow-hidden overflow-y-auto mt-4'>
                    <div className='px-2'>
                        {
                            selector?.notification?.getNotificationList &&
                                selector?.notification?.getNotificationList?.list?.length > 0 ? (
                                selector?.notification?.getNotificationList?.list?.map((ele, id) => (
                                    <>
                                        <div className='  text-black mb-2   bg-gray-50 rounded-lg shadow p-2'>
                                            <div className='flex justify-start place-items-center  '>
                                                <div className='flex justify-center place-items-center bg-[#024CAA] px-1 py-1 rounded-full'>
                                                    <MdNotificationsActive className='text-white text-xl' />
                                                </div>
                                                <div className='py-1 rounded-md mx-4'>
                                                    <p className='text-sm capitalize'>
                                                        {ele?.title ? ele?.title : "-"}
                                                    </p>
                                                    <p className='text-[12px] capitalize'>
                                                        {ele?.body ? ele?.body : "-"}
                                                    </p>
                                                </div>
                                            </div>
                                            <p className='flex justify-end  place-items-end text-[11px]'>

                                                {moment(ele?.createdAt).format('DD/MMM/YYYY')}

                                            </p>

                                        </div>

                                    </>
                                ))
                            ) : (
                                <div>

                                    No Data Found
                                </div>
                            )
                        }


                    </div>
                </div>
            </div>
        </>
    )
}

export default Notification
