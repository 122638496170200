import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from '../../../_actions';
import { IoMdWallet } from "react-icons/io";
import { IoArrowBackOutline } from 'react-icons/io5';
import { FaWhatsapp } from "react-icons/fa";
import { MdCall } from "react-icons/md";
import { AiOutlineBank } from "react-icons/ai";
import { users } from '../../../_reducers/users.reducer';
import Loader from '../../../components/Loader/Loader';


function AddFunds() {
    const selector = useSelector(state => state)
    const dispatch = useDispatch()
    const [cashValue, setCashValue] = useState('')
    const [amount, setAmount] = useState('');
    // const [utrId,setUtrId]=useState('')
    const [errorslogin, setErrorslogin] = useState({});
    const amountInputRef = useRef(null);
    const { imgUrl, loading } = users || {};

    // console.log("image__________", selector?.users?.imgUrl);

    const handleSetAmount=(value)=>{
        setAmount(value)
        setErrorslogin((prevErrors) => ({
            ...prevErrors,
            amount: "", 
          }));
    }

    const handleFile = (event) => {
        if (event.target.files && event.target.files[0]) {
            dispatch(userActions.uploadImage(event));
        } else {
            console.log("No File To Upload!");
        }

        setErrorslogin((prevErrors) => ({
            ...prevErrors,
            img: "",
        }));
    };
    const handleValidationLogin = () => {
        let errors = {};
        let formIsValid = true;

        // Validate amount
        if (!amount) {
            formIsValid = false;
            errors["amount"] = "Amount is required";
        }
        if (amount?.toString()?.includes('.')) {
            formIsValid = false;
            errors["amount"] = "Decimal is not allowed";
        }

        // Validate image
        // if (!selector?.users?.imgUrl) {
        //     formIsValid = false;
        //     errors["img"] = "Image is required";
        // }

        setErrorslogin(errors);
        return formIsValid;
    };
    const handleCashAdd = (e) => {
        e.preventDefault();
        if (handleValidationLogin()) {
            const data = {
                amount: amount,
                image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLwzhli3UiGlUsTtOAoxA_f4dKRDG9DGa99w&s',
                parentId: selector?.users?.getProfile?.parentId || null,
                deviceId:'321sf+513'
            };

            dispatch(userActions.saveClientPayment(data));


            setAmount('');

            if (amountInputRef.current) {
                amountInputRef.current.value = null;
            }
        }
    }
    const handleBack = () => {
        window.history.back()
    }
    return (
        <>
            <Loader loading={selector?.users?.loading} />
            <div className='bg-white text-black font-semibold'>
                <div className='h-12 w-full border bg-[#024CAA] rounded-b-3xl flex justify-between place-items-center px-4 gap-3'>
                    <div className='flex justify-start place-items-center gap-2'>
                        <IoArrowBackOutline className='text-white mb-1 text-lg cursor-pointer' onClick={handleBack} />
                        <div className='  font-semibold text-white cursor-pointer' >Add Funds</div>
                    </div>
                    <div className='pb-1 flex justify-start place-items-center text-white gap-1'>
                        <IoMdWallet className='text-white text-lg' /> {selector?.users?.getProfile?.wallet ? selector?.users?.getProfile?.wallet.toFixed(2) : '-'}
                    </div>
                </div>
                <div className='h-screen'>
                    <div className="flex justify-center mt-10  ">
                        <div className="w-full max-w-sm p-5 border bg-white rounded-lg shadow-md">
                            <div className="  rounded-lg mb-4 shadow-lg py-4 border">
                                <div className="text-center mb-2 bg-black text-white py-1">
                                    <div className="text-sm  font-medium capitalize">{selector?.users?.getProfile?.userName ? selector?.users?.getProfile?.userName : '-'}</div>
                                    <div className="text-xs ">{
                                        selector?.users?.getProfile?.mobNo ? selector?.users?.getProfile?.mobNo : '-'}</div>
                                </div>
                                <div className="flex justify-start items-center bg-white p-3 rounded-lg ">
                                    <div className="flex items-center">
                                        <IoMdWallet className='text-[#C11C84] text-5xl' />
                                        <div className="ml-3 text-left">
                                            <div className=" text-[#800048]">₹ {selector?.users?.getProfile?.wallet ? selector?.users?.getProfile?.wallet.toFixed(2) : '-'}</div>
                                            <div className="text-sm text-gray-500 font-normal">Current Balance</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center items-center text-center text-sm  mb-4 border-t-2 border-black ">
                                <span className='pt-1'>For Fund Query's please Call Or Whatsapp</span>
                            </div>
                            <div className="flex justify-center place-items-center gap-2 mb-4 border-b-2 border-black pb-2">
                                <button className="flex items-center px-3 py-1  rounded-full gap-1 shadow-md text-black border">
                                    <FaWhatsapp className='text-lg' />

                                    Whatsapp
                                </button>
                                <button className="flex items-center px-6 py-1 shadow-md gap-1 rounded-full text-black border">
                                    <MdCall className='text-lg' />

                                    Call
                                </button>
                            </div>
                            <div className="relative mb-4">
                                <input
                                    type="number"
                                    id="amount"
                                    className="bg-gray-50 border  px-12 border-gray-300 text-gray-900 text-sm rounded-full focus:ring-[#3e44d5] focus:border-[#3e44d5] block w-full p-2.5"
                                    placeholder="Amount"
                                    required
                                    value={amount}
                                    onChange={(e) => {
                                        setAmount(e.target.value);
                                        setErrorslogin((prevErrors) => ({
                                            ...prevErrors,
                                            amount: "",
                                        }));
                                    }}
                                />
                                {errorslogin["amount"] && (
                                    <div className='text-red-500 text-xs font-normal'>{errorslogin["amount"]}</div>
                                )}
                                <div className='absolute top-1 left-3 bg-[#024CAA] rounded-full h-8 w-8 flex justify-center place-items-center text-center'>
                                    <AiOutlineBank className='text-xl  text-white ' />
                                </div>

                            </div>
                            {/* <div className='grid grid-cols-3 gap-3 mb-2'>
                                <button className='px-3 py-1 border border-gray-300 rounded-full shadow' onClick={() => handleSetAmount(500)}>500</button>
                                <button className='px-3 py-1 border border-gray-300 rounded-full shadow'        onClick={() => handleSetAmount(1000)}>1000</button>
                                <button className='px-3 py-1 border border-gray-300 rounded-full shadow'  onClick={() => handleSetAmount(2000)}>2000</button>
                            </div> */}


                            {/* <div className="relative mb-4">
                                <input
                                    type="number"
                                    id="amount"
                                    className="bg-gray-50 border  px-12 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#3e44d5] focus:border-[#3e44d5] block w-full p-2.5"
                                    placeholder="Amount"
                                    required
                                    value={utrId}
                                    onChange={(e) => {
                                        setUtrId(e.target.value);
                                        setErrorslogin((prevErrors) => ({
                                            ...prevErrors,
                                            utrId: "",
                                        }));
                                    }}
                                />
                                {errorslogin["amount"] && (
                                    <div className='text-red-500 text-xs font-normal'>{errorslogin["amount"]}</div>
                                )}
                                <div className='absolute top-1 left-3 bg-[#800048] rounded-full h-8 w-8 flex justify-center place-items-center text-center'>
                                    <AiOutlineBank className='text-xl  text-white ' />
                                </div>

                            </div> */}
                            <div>
                                {imgUrl && <img src={imgUrl} className='h-10 w-10' />}
                                <div className="mb-5">
                                    <label htmlFor="file" className="block mb-2 text-sm font-medium text-gray-900">Upload Image<span className='text-red-600'>*</span></label>
                                    <input
                                        type="file"
                                        ref={amountInputRef}
                                        id="file"
                                        accept="image/*"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-[#3e44d5] focus:border-[#3e44d5] block w-full p-2.5"
                                        onChange={handleFile}
                                        required
                                    />
                                    {errorslogin["img"] && (
                                        <div className='text-red-500 text-xs font-normal'>{errorslogin["img"]}</div>
                                    )}
                                </div>
                            </div>

                            <div className='flex justify-center place-items-center'>
                                <button className=" py-1 px-3 font-semibold text-white bg-[#024CAA] rounded hover:bg-[#a3005e]" onClick={handleCashAdd}>Add Cash</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default AddFunds

