import React from "react";
import { connect } from "react-redux";
import { alertActions, userActions } from "../../_actions";
import moment from "moment";
import { IoCaretBackCircle } from "react-icons/io5";
import Loader from "../../components/Loader/Loader";
import { IoArrowBack } from "react-icons/io5";
import ConfirmationModel from "../../components/ConfirmationModel";
import { FaCalendarAlt } from "react-icons/fa";
import SubmitTotalData from "../Submit Total Data/SubmitTotalData";
import HeaderWallet from "../Wallet/HeaderWallet";
import CustomSlider from "../../components/SliderHeader/CustomSlider";
import BetPlaceModal from "../../components/Bet Place Modal/BetPlaceModal";

class RedJodi extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gameOne: "",
      gameTwo: "",
      gameThree: "",
      gameFour: "",
      gameItem: {},
      activeTab: 1,
      isModelOpen: false,
      arrayofBets: [],
      editStake: false,
      viewall: false,
      dataobj: {},
      fieldStake: {},
      errorStake: {},
      fieldMarket: {},
      errorMarket: {},
      selectedOption: null,
      timeLeft: "",
      currentTime: moment().format("hh:mm:ss A"),
      data: {
        limit: 10,
        pageno: 1,
      },
    };
  }
  componentDidMount() {
    this.timerInterval = setInterval(this.updateTimeLeft, 1000);
    let data = {
      marketId: this.props.match.params.marketId,
    };
    let objdata = {
      gameId: this.props.match.params.gameId,
      marketId: data.marketId,
    };
    this.props.dispatch(userActions.todayBets(objdata));

    this.props.dispatch(userActions.getMarketByMarketId(data));

    const queryParams = new URLSearchParams(this.props.location.search);
    const isJackpot = queryParams.get('isJackpot')
    const isStarLine = queryParams.get('isStarLine')

    let simpleGameData = {
      "isJackpot": isJackpot,
      "isStarLine": isStarLine
    }
    this.props.dispatch(userActions.placebetMarketListforSimplGames(simpleGameData))

    this.props.dispatch(
      userActions.getGamesByGamesIdForUser({
        gameId: this.props.match.params.gameId,
      }),
    );
    this.props.dispatch(userActions.getProfile());
  }
  componentWillUnmount() {
    clearInterval(this.timerInterval);
  }


  updateTimeLeft = () => {
    const { users } = this.props;
    const { gameData } = users;
    const { list, jodiNumber, openNumber, closeNumber } = gameData
      ? gameData
      : {};
    const { openTime, closingTime } = list ? list : {};

    const openingTime = moment()
      .hours(parseInt(openTime && openTime / 3600))
      .minutes(parseInt((openTime && openTime % 3600) / 60))
      .seconds(parseInt(openTime && openTime % 60))
      .milliseconds(0);

    const closingTimes = moment()
      .hours(parseInt(closingTime && closingTime / 3600))
      .minutes(parseInt((closingTime && closingTime % 3600) / 60))
      .seconds(parseInt(closingTime && closingTime % 60))
      .milliseconds(0);

    const currentTime = moment();
    const timeLeft = closingTimes.diff(currentTime);

    if (timeLeft <= 0) {
      clearInterval(this.timerInterval);
      this.setState({ timeLeft: "00:00:00" });
    } else {
      const duration = moment.duration(timeLeft);
      const hours = String(duration.hours()).padStart(2, "0");
      const minutes = String(duration.minutes()).padStart(2, "0");
      const seconds = String(duration.seconds()).padStart(2, "0");

      this.setState({
        timeLeft: `${hours}:${minutes}:${seconds}`,
        currentTime: currentTime.format("hh:mm:ss A"),
      });
    }
  };
  setSelectedOption = (e) => {
    this.setState({ selectedOption: e?.value });
  };
  handleStakeChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;

    let { fieldStake, errorStake } = this.state;
    fieldStake[name] = parseFloat(value) >= 0 ? value : 0;
    errorStake[name] = "data not found";
    console.log("value  ", value);

    let sum = 0;

    for (const key in fieldStake) {
      if (fieldStake.hasOwnProperty(key)) {
        sum += parseInt(fieldStake[key]);
      }
    }

    this.setState({ fieldStake, errorStake, totalPoint: sum });
  };

  handleChangeSelect = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      fieldMarket: {
        ...prevState.fieldMarket,
        [name]: value,
      },
      errorStake: {
        ...prevState.errorStake,
        [name]: "data not found",
        market: "",
        betNumber: "",
      },
    }));
  };





  setinLocal = () => {
    if (
      this.state.fieldMarket &&
      Object.keys(this.state.fieldMarket).length > 0
    ) {
      // window.localStorage.setItem("isOpen", this.state.fieldMarket["isOpen"])
      this.setState({
        viewall: true,
      });
    }
  };

  placeBet = () => {
    this.props.dispatch(
      userActions.getGamesByGamesIdForUser({
        gameId: this.props.match.params.gameId,
      }),
    );
    let { users } = this.props;
    let { marketDetails, marketLoading, getProfile, gameData,ListForSimpleGames } = users;
    let { marketListForSimpleGames } = ListForSimpleGames;

    let { list, jodiNumber, openNumber, closeNumber } = gameData
      ? gameData
      : {};
    let { openTime, closingTime } = list ? list : {};
    let { wallet } = getProfile ? getProfile : {};


    let { min, max } = marketDetails ? marketDetails : {};
    if (this.state.arrayofBets && this.state.arrayofBets.length == 0) {
      this.props.dispatch(alertActions.error("Attention", `No bets placed`));
      return;
    }
    if (this.state.arrayofBets.length !== 0) {
      for (let i = 0; i < this.state.arrayofBets.length; i++) {
        if (this.state.arrayofBets[i]?.amount < min) {
          this.props.dispatch(
            alertActions.error(
              "Attention",
              `Bet amount should be more than ${min}`,
            ),
          );
          return;
        } else if (this.state.arrayofBets[i]?.amount > max) {
          this.props.dispatch(
            alertActions.error(
              "Attention",
              `Bet amount should be less than ${max}`,
            ),
          );
          return;
        }
      }
    }

    const openingTime = moment()
      .hours(parseInt(openTime && openTime / 3600))
      .minutes(parseInt((openTime && openTime % 3600) / 60))
      .seconds(parseInt(openTime && openTime % 60))
      .milliseconds(0);

    const OpenBetTime = openingTime.format("hh:mm A");

    const closingTimes = moment()
      .hours(parseInt(closingTime && closingTime / 3600))
      .minutes(parseInt((closingTime && closingTime % 3600) / 60))
      .seconds(parseInt(closingTime && closingTime % 60))
      .milliseconds(0);

    const CloseBetTime = closingTimes.format("hh:mm A");

    const getCurrentTime = () => {
      const now = moment();
      return now.format("hh:mm:A");
    };
    const currentTimeApis = getCurrentTime();
    const isBefore = moment(currentTimeApis, "hh:mm A").isBefore(
      moment(CloseBetTime, "hh:mm A"),
    );

    if (!isBefore) {
      this.props.dispatch(
        alertActions.error(
          "Attention",
          `Bets are closed, Betting time is ${CloseBetTime}`,
        ),
      );
      return;
    }
    if (jodiNumber?.toString().length == 2) {
      this.props.dispatch(
        alertActions.error("Attention", `Result is Declared`),
      );
      return;
    }

    let { fieldStake, fieldMarket, totalPoint } = this.state;

    let bets = Object.keys(fieldStake).map((key) => ({
      betNumber: key,
      amount: parseInt(fieldStake[key]),
    }));
   

    function getIdForBetNumber(betNumber) {
      const data = marketListForSimpleGames || [];

      if (!betNumber || typeof betNumber !== "string") return null;

      const cleanedNumber = betNumber.replace("-", "");

      // Condition: Single Digit
      if (cleanedNumber.length === 1) {
        return data.find(item => item.name === "Single Digit")._id;
      }

      // Condition: Jodi
      if (cleanedNumber.length === 2) {
        return data.find(item => item.name === "Jodi")._id;
      }

      // Condition: Triple Digit
      if (cleanedNumber.length === 3) {
        const digits = cleanedNumber.split("");
        const uniqueDigits = new Set(digits);

        if (uniqueDigits.size === 1) {
          // Triple Pana
          return data.find(item => item.name === "Triple Pana")._id;
        } else if (uniqueDigits.size === 3) {
          // Single Pana
          return data.find(item => item.name === "Single Pana")._id;
        } else {
          // Double Pana
          return data.find(item => item.name === "Double Pana")._id;
        }
      }

      // Condition: Half Sangam or Full Sangam
      if (betNumber.includes("-")) {
        const parts = betNumber.split("-");
        if (parts.length === 2) {
          if (parts[0].length === 3 && parts[1].length === 1) {
            // Half Sangam (A)
            return data.find(item => item.name === "Half Sangam (A)")._id;
          } else if (parts[0].length === 3 && parts[1].length === 3) {
            // Full Sangam
            return data.find(item => item.name === "Full Sangam")._id;
          }
        }
      }

      return null;
    }

    let array = this?.state?.arrayofBets.map(e => ({
      ...e,
      parentMarketId: getIdForBetNumber(e?.betNumber)
    }));



    console.log("handleValid", min, max, this.handleValid())
    if (this.handleValid()) {
      let reqData = {
        marketId: this.props.match.params.marketId,
        gameId: this.props.match.params.gameId,
        bets: array,
        isOpen: true,
        parentId: getProfile?.parentId || null,
        upline: getProfile?.upline || [],
      };

      console.log('reqDatareqDatareqDatareqDatareqData',reqData)
      // this.props.dispatch(userActions.placeBet(reqData, this.props))

    }
  };
  handleValid = () => {
    let valid = true;
    let { fieldStake, fieldMarket } = this.state;
    let errorMessage = {};
    let bets = Object.keys(fieldStake).map((key) => ({
      betNumber: key,
      amount: parseInt(fieldStake[key]),
    }));


    if (this.state.arrayofBets && this.state.arrayofBets.length == 0) {
      valid = false;
      errorMessage["bet"] = "Bets not found";
    }
    console.log(errorMessage)
    this.setState({ errorStake: { ...errorMessage } });

    return valid;
  };

  handleChange = (e) => {
    e.preventDefault();
    let { users } = this.props;
    let { gameData } = users;
    let { list, jodiNumber, openNumber, closeNumber } = gameData
      ? gameData
      : {};
    let { max, min } = list ? list : {};
    let { name, value } = e.target;
    let { fieldStake, errorStake } = this.state;
    fieldStake[name] = Number(value);

    this.setState({ fieldStake, errorStake });
  };

  addinArray = () => {
    const { amount } = this.state.fieldStake;
    let { users } = this.props;
    let betNumber = this.state.selectedOption;
    let { gameData } = users;
    let { list, jodiNumber, openNumber, closeNumber } = gameData
      ? gameData
      : {};
    let { max, min } = list ? list : {};
    // Checking if amount is within valid range
    if (Number(amount) > max) {
      this.props.dispatch(
        alertActions.warn(`Attention`, `Max bid amount is ${max}`),
      );
      return;
    }
    if (Number(amount) < min) {
      this.props.dispatch(
        alertActions.warn("Attention", `Min bid amount is ${min}`),
      );
      return;
    }
    if (
      betNumber !== undefined &&
      amount !== undefined &&
      this.state.selectedOption !== null
    ) {
      const betIndex = this.state.arrayofBets.findIndex(
        (bet) => bet.betNumber === betNumber,
      );

      if (betIndex !== -1) {
        this.setState((prevState) => {
          const updatedBets = [...prevState.arrayofBets];
          updatedBets[betIndex] = { ...updatedBets[betIndex], amount: amount };
          return {
            arrayofBets: updatedBets,
            fieldStake: {},
            // selectedOption: null
          };
        });
      } else {
        this.setState((prevState) => ({
          arrayofBets: [
            ...prevState.arrayofBets,
            { betNumber: betNumber, amount: amount },
          ],
          fieldStake: {},
          // selectedOption: null
        }));
      }
      // this.setState({ selectedOption: null })
    } else {
    }
  };
  letOpenModel = () => {
    if (this.state.arrayofBets && this.state.arrayofBets.length > 0) {
      this.setState({ isModelOpen: true });
    } else {
      // window.alert("")
      return;
    }
  };
  letOpenModelClose = () => {
    this.setState({ isModelOpen: false });
  };

  handleNewChange = (e) => {
    this.props.dispatch(
      userActions.getGamesByGamesIdForUser({
        gameId: this.props.match.params.gameId,
      }),
    );
    let { users } = this.props;
    let { marketDetails, marketLoading, getProfile, gameData } = users;
    let { list, jodiNumber, openNumber, closeNumber } = gameData
      ? gameData
      : {};
    let { openTime, closingTime, betClosingTime } = list ? list : {};
    let { wallet } = getProfile ? getProfile : {};

    console.log("Listees", list);
    const openingTime = moment()
      .hours(parseInt(openTime && openTime / 3600))
      .minutes(parseInt((openTime && openTime % 3600) / 60))
      .seconds(parseInt(openTime && openTime % 60))
      .milliseconds(0);

    const OpenBetTime = openingTime.format("hh:mm A");

    const closingTimes = moment()
      .hours(parseInt(closingTime && closingTime / 3600))
      .minutes(parseInt((closingTime && closingTime % 3600) / 60))
      .seconds(parseInt(closingTime && closingTime % 60))
      .milliseconds(0);

    const CloseBetTime = closingTimes.format("hh:mm A");

    const getCurrentTime = () => {
      const now = moment();
      return now.format("hh:mm:A");
    };
    const currentTimeApis = getCurrentTime();
    // const isBefore = moment(currentTimeApis, "hh:mm A").isBefore(moment(CloseBetTime, "hh:mm A"));
    // ---------------------------------
    const closeCloseBidTime = moment
      .duration(betClosingTime && betClosingTime, "seconds")
      .asMinutes();
    const MinutesBeforeClosing = closingTimes
      .clone()
      .subtract(closeCloseBidTime, "minutes");
    const CloseBetMinutesBeforeClosing = MinutesBeforeClosing.format("hh:mm A");
    console.log("jsadvbjasdv", CloseBetMinutesBeforeClosing);
    const isBefore = moment(currentTimeApis, "hh:mm A").isBefore(
      moment(CloseBetMinutesBeforeClosing, "hh:mm A"),
    );
    // ---------------------------------

    if (!isBefore) {
      this.props.dispatch(
        alertActions.error(
          "Attention",
          `Bets are closed, Betting time is ${CloseBetTime}`,
        ),
      );
      return;
    }
    if (jodiNumber?.toString().length == 2) {
      this.props.dispatch(
        alertActions.error("Attention", `Result is Declared`),
      );
      return;
    }

    const { name, value } = e.target;
    const { arrayofBets } = this.state;

    if (value.length > 5) {
      return;
    }

    const index = arrayofBets.findIndex((bet) => bet.betNumber === name);

    if (value.trim() === "") {
      // Remove bet if value is empty
      const updatedBets = arrayofBets.filter((bet) => bet.betNumber !== name);
      this.setState({ arrayofBets: updatedBets });
    } else if (index !== -1) {
      // Update existing bet with new amount
      const updatedBets = [...arrayofBets];
      updatedBets[index] = { ...updatedBets[index], amount: value };
      this.setState({ arrayofBets: updatedBets });
    } else {
      // Add new bet if not found
      const newBet = { betNumber: name, amount: value };
      this.setState((prevState) => ({
        arrayofBets: [...prevState.arrayofBets, newBet],
      }));
    }
  };

  render() {
    const getCurrentTime = () => {
      const now = moment();
      return now.format("hh:mm:A");
    };
    const currentTimeApis = getCurrentTime();
    let { users } = this.props;
    const currentDate = moment();
    const formattedDate = currentDate.format("DD-MMM-YYYY");
    let {
      marketDetails,
      marketLoading,
      getProfile,
      loading,
      gameData,
      todayBet,
      loadingTick
    } = users;
    let { min, max } = marketDetails ? marketDetails : {};

    let { wallet, blockedNumbers } = getProfile ? getProfile : {};
    let { fieldStake, fieldMarket, errorStake, totalPoint } = this.state;

    let { currentTime, list, jodiNumber, openNumber, closeNumber } = gameData
      ? gameData
      : {};
    let { openTime, closingTime, betOpenTime, betClosingTime, isJodi } = list
      ? list
      : {};

    // console.log(this.state.fieldStake)

    const openingTime = moment()
      .hours(parseInt(openTime && openTime / 3600))
      .minutes(parseInt((openTime && openTime % 3600) / 60))
      .seconds(parseInt(openTime && openTime % 60))
      .milliseconds(0);

    const closingTimes = moment()
      .hours(parseInt(closingTime && closingTime / 3600))
      .minutes(parseInt((closingTime && closingTime % 3600) / 60))
      .seconds(parseInt(closingTime && closingTime % 60))
      .milliseconds(0);

    const closeCloseBidTime = moment
      .duration(betClosingTime && betClosingTime, "seconds")
      .asMinutes();
    const closeOpenBidTime = moment
      .duration(betOpenTime && betOpenTime, "seconds")
      .asMinutes();

    const MinutesBeforeClosing = closingTimes
      .clone()
      .subtract(closeCloseBidTime, "minutes");
    const MinutesBeforeOpening = openingTime
      .clone()
      .subtract(closeOpenBidTime, "minutes");

    //formate
    const CloseBetMinutesBeforeClosing = MinutesBeforeClosing.format("hh:mm A");
    const CloseBetMinutesBeforeOpening = MinutesBeforeOpening.format("hh:mm A");

    //current time
    const currentTimeDuration = moment.duration(currentTime, "seconds");
    // const currentTimeApis = moment().startOf('day').add(currentTimeDuration).format("hh:mm A");

    const isBeforeClose = moment(currentTimeApis, "hh:mm A").isBefore(
      moment(CloseBetMinutesBeforeClosing, "hh:mm A"),
    );
    const isBeforeOpen = moment(currentTimeApis, "hh:mm A").isBefore(
      moment(CloseBetMinutesBeforeOpening, "hh:mm A"),
    );

    let localStorage = window.localStorage.getItem("isOpen");
    const totalAmount =
      this.state.arrayofBets && this.state.arrayofBets.length > 0
        ? this.state.arrayofBets.reduce(
          (total, bet) => total + parseInt(bet.amount),
          0,
        )
        : 0;

    const OpenBetTime = openingTime.format("hh:mm A");
    const closeBetTime = closingTimes.format("hh:mm A");

    let selectedMarketBlock =
      blockedNumbers &&
      blockedNumbers.length > 0 &&
      blockedNumbers.filter(
        (e) => e.marketId == this.props.match.params.marketId,
      );
    let arrayToblock =
      selectedMarketBlock &&
      selectedMarketBlock.length > 0 &&
      selectedMarketBlock[0] &&
      selectedMarketBlock[0]?.numbers &&
      selectedMarketBlock[0]?.numbers?.map((e) => e?.numbers);
    let flatarrydatatoBlock =
      (arrayToblock &&
        arrayToblock.length > 0 &&
        arrayToblock.flat(Infinity)) ||
      [];
    return (
      <div className="w-full h-full relative overflow-hidden bg-[#EEEEEE]">
        <Loader loading={marketLoading} />
        <Loader loading={loadingTick} />

        <div className=" bg-[#024CAA] flex justify-between text-white items-center place-items-center text-3xl p-2 rounded-b-3xl relative">
          <div>

          <IoArrowBack
            className="  cursor-pointer"
            onClick={() => this.props.history.goBack()}
            />
            </div>
          <div className="uppercase text-base w-[70%]">
      
            <CustomSlider title={`${this.props.match.params.gameName} - RED JODI`}/>
          </div>
          <div className="text-sm"><HeaderWallet/></div>
        </div>
        <div className="w-full p-2  space-y-5">
          <div className="   w-full p rounded-lg space-y-5">
            <div className="flex  justify-center gap-2  items-center">
              <div className=" flex flex-1 flex-col justify-center  rounded bg-white shadow items-center">
                <div className=" text-sm  px-3 py-2.5 gap-2 font-bold flex justify-center items-center ">
                  <FaCalendarAlt />
                  <span>{formattedDate}</span>
                </div>
              </div>

              {(!isJodi) && <div className="flex flex-1 flex-col justify-center rounded bg-white shadow p-2 items-center">

                <div className=" bg-white rounded w-full  text-center ">{this.props.match.params.isOpen == "true" ? "OPEN" : "CLOSE"}</div>

              
              </div>}
            </div>
     
            <div className="w-full px-10">
              <div className="flex flex-wrap justify-center items-center gap-2">
                {marketDetails?.numList &&
                  marketDetails?.numList.map((e) => {
                    if(typeof e == 'object'){
                      return;
                    }
                    if (!flatarrydatatoBlock?.includes(e?.toString())) {
                      return (
                        <div
                          key={e}
                          className="flex  font-bold rounded-lg p-1 justify-between items-center w-full sm:w-auto"
                        >
                          <div className="text-white p-2 bg-[#024CAA] w-10 rounded-l-md flex-shrink-0">
                            {e}
                          </div>
                          <input
                            name={e}
                            value={
                              this.state.arrayofBets.find(
                                (bet) => bet.betNumber == e,
                              )?.amount || ""
                            }
                            onChange={this.handleNewChange}
                            className="w-full sm:w-16 h-full bg-slate-300 p-[0.5rem] rounded-r-md outline-none"
                            type="number"
                          />
                        </div>
                      );
                    }
                  })}
              </div>
            </div>

            <div className="absolute bottom-0 left-0 w-full  border-t-2 border-[#C11C84] ">

              <div className="grid grid-cols-2 items-center w-full p-1 py-3 bg-white ">
               
                <div>
                  <SubmitTotalData totalAmount={totalAmount > 0 && totalAmount ? totalAmount : '0'}
                    arrayofBets={this.state.arrayofBets && this.state.arrayofBets?.length} />
                </div>
                <button
                  onClick={() => this.letOpenModel()}
                  className="py-2 px-4 ml-4 bg-[#024CAA] hover:bg-[#A0186D] text-white font-semibold rounded-lg shadow-md transition-colors duration-200"
                >
                  Submit
                </button>
              </div>

            </div>
            {/* <div className="  w-full">
              <button
                onClick={() => this.letOpenModel()}
                className=" py-1 w-full  mt-4 bg-[#024CAA]  text-white md:text-base text-base rounded "
              >
                Submit
              </button>
            </div> */}
          </div>
          {/* <table className="min-w-full bg-[#3e44d5] text-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b border-gray-200 bg-[#3e44d5] text-white text-left text-sm font-medium">
                  Digit
                </th>
                <th className="py-2 px-4 border-b border-gray-200 bg-[#3e44d5] text-white text-left text-sm font-medium">
                  Amt
                </th>
                <th className="py-2 px-4 border-b border-gray-200 bg-[#3e44d5] text-white text-left text-sm font-medium">
                  Type
                </th>
                <th className="py-2 px-4 border-b border-gray-200 bg-[#3e44d5] text-white text-left text-sm font-medium">
                  Time
                </th>
              </tr>
            </thead>
            <tbody>
              {todayBet &&
                todayBet.length > 0 &&
                todayBet.map((item, index) => (
                  <tr
                    key={item.id}
                    className={index % 2 == 0 && "bg-white text-black"}
                  >
                    <td className="py-2 px-4 border-b border-gray-200">
                      {item?.betNumber}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200">
                      {item.amount}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200">
                      {item?.isOpen ? "Open" : "Close"}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200">
                      {new Date(item?.createdAt).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table> */}

          <ConfirmationModel
            isModelOpen={this.state.isModelOpen}
            type={this.props.match.params.isOpen == "true" ? "OPEN" : "CLOSE"}
            arrayOfBets={this.state.arrayofBets}
            walletAmount={wallet}
            letOpenModelClose={this.letOpenModelClose}
            placeBet={this.placeBet}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(RedJodi);
