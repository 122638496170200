import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSelect } from 'react-select-search'
import { userActions } from '../../_actions'
import { BsWallet } from "react-icons/bs";
import { useLocation, useHistory } from "react-router-dom";


const HeaderWallet = () => {
const history = useHistory();

    const dispatch = useDispatch()
    const selector = useSelector(state => state)

    useEffect(() => {
        dispatch(userActions.getProfile());
    }, [])

    const navigateWallet=()=>{
        history.push(`/app/add-fund`)
    }
    return (
        <div className='flex justify-start place-items-center gap-2'>
            <BsWallet className="" />
            <span className="" onClick={navigateWallet}>
               {selector?.users?.getProfile?.wallet ? selector?.users?.getProfile?.wallet.toFixed(2) : '-'}
            </span>

        </div>
    )
}

export default HeaderWallet