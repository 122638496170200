import React, { useState } from 'react';
import { IoArrowBackOutline } from 'react-icons/io5';

const Settings = () => {
    const [gameNotification, setGameNotification] = useState(true);
    const [mainNotification, setMainNotification] = useState(true);
    const [indianStarlineNotification, setIndianStarlineNotification] = useState(true);
    const [indianJackpotNotification, setIndianJackpotNotification] = useState(true);

    const handleGoBack = () => {
        window.history.back();
    };

    return (
        <div className='h-screen bg-white'>
            <div className='h-10 w-full border bg-[#024CAA] rounded-b-3xl flex justify-start place-items-end px-4 gap-3'>
                <IoArrowBackOutline className='text-white mb-1 text-lg' onClick={handleGoBack} />
                <div className='font-semibold text-white cursor-pointer'>Setting</div>
            </div>
            <section>
                <div className="mt-4 p-3">
                    <div className="bg-white p-4 rounded-lg shadow-md border text-sm mb-4 flex items-center justify-between">
                        <div>
                            <p className="font-semibold">Game Notification:</p>
                            <p className="text-xs text-gray-600">
                                User can start or stop game result notification, if you stop you will never receive game result notification.
                            </p>
                        </div>
                        <label className="inline-flex items-center cursor-pointer">
                            <input
                                type="checkbox"
                                checked={gameNotification}
                                onChange={() => setGameNotification(!gameNotification)}
                                className="sr-only peer"
                            />
                            <div className="relative w-10 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#865671] rounded-full peer dark:bg-[#925779] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:-top-[2px] after:start-[2px] after:bg-[#024CAA]  after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-[#b47d9d]"></div>

                        </label>

                    </div>

                    <div className="bg-white p-4 rounded-lg shadow-md border text-xs mb-4 flex items-center justify-between">
                        <div>
                            <p className="font-semibold">Main Notification:</p>
                            <p className="text-xs text-gray-600">
                                This setting helps users to stop or start main notifications like point Add or Withdraw and other personal notifications.
                            </p>
                        </div>
                        <label className="inline-flex items-center cursor-pointer">
                            <input
                                type="checkbox"
                                className="sr-only peer"
                                checked={mainNotification}
                                onChange={() => setMainNotification(!mainNotification)}
                            />
                            <div className="relative w-10 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#865671] rounded-full peer dark:bg-[#925779] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:-top-[2px] after:start-[2px] after:bg-[#024CAA]  after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-[#b47d9d]"></div>
                        </label>
                    </div>

                    <div className="bg-white p-4 rounded-lg shadow-md border text-xs mb-4 flex items-center justify-between">
                        <div>
                            <p className="font-semibold"> Starline Notification:</p>
                            <p className="text-xs text-gray-600">
                                You can set notification of  starline, if you want notification switch should on or you want not or salient then switch it off you will not receive any notification from  starline.
                            </p>
                        </div>
                        <label className="inline-flex items-center cursor-pointer">

                            <input
                                type="checkbox"
                                className="sr-only peer"
                                checked={indianStarlineNotification}
                                onChange={() => setIndianStarlineNotification(!indianStarlineNotification)}
                            />
                            <div className="relative w-10 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#865671] rounded-full peer dark:bg-[#925779] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:-top-[2px] after:start-[2px] after:bg-[#024CAA]  after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-[#b47d9d]"></div>
                        </label>
                    </div>

                    <div className="bg-white p-4 rounded-lg shadow-md border text-xs mb-4 flex items-center justify-between">
                        <div>
                            <p className="font-semibold">  JackPot Notification:</p>
                            <p className="text-xs text-gray-600">
                                You can set notification of  JackPot, if you want notification switch should on or you want not or <br></br> salient then switch it off you will not receive any notification from  JackPot.
                            </p>
                        </div>
                        <label className="inline-flex items-center cursor-pointer">

                            <input
                                type="checkbox"
                                className="sr-only peer"

                                checked={indianJackpotNotification}
                                onChange={() => setIndianJackpotNotification(!indianJackpotNotification)}
                            />
                            <div className="relative w-10 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#865671] rounded-full peer dark:bg-[#925779] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:-top-[2px] after:start-[2px] after:bg-[#024CAA]  after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-[#b47d9d]"></div>

                        </label>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Settings;
