import React from 'react';
// import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Loader from '../../components/Loader/Loader';

class WithdrawAmount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: '',
            file: '',
            imagePreviewUrl: '',
            error:{},
        };
        this.amountInputRef = React.createRef();
    }
componentDidMount(){
    this.props.dispatch(userActions.clearImage());
}


    handleFile = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.props.dispatch(userActions.uploadImage(event)); // Assuming uploadImage is defined in userActions
        } else {
            console.log("No File To Upload!");
        }
    };



    submit=(e)=>{
        e.preventDefault();
        let { users } = this.props
        let { imgUrl, loading } = users ? users : {}
        console.log(users)
   if(this.handleValid()){

       
       const data = {
           amount: this.state.amount,
           parentId:users?.getProfile?.parentId||null,
        }
        this.props.dispatch(userActions.withdrawPaymentRequest(data));
        this.setState({
            amount: '',
        });
        
}
    }

handleValid=()=>{
 let isValid =true
    let error={};
    if(!this.state.amount){
        isValid =false
        error.amount="Amount is required"
    }
    if(this.state.amount&&this.state.amount.includes('.')){
        isValid =false
        error.amount="Decimal is not allowed"
    }
   
    this.setState({error:error})
    return isValid;
}


    render() {
        let { users} = this.props
        let {imgUrl,loading}=users?users:{}

        return (
            <div className='bg-white h-screen'>
                <Loader loading={loading}/>
                <form className="max-w-sm mx-auto p-5">
                    <div className=' text-xl border-b-2 mb-2 '>Withdraw Amount</div>
                    <div className="mb-5">
                        <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900">Amount</label>
                        <input
                            type="number"
                            id="amount"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#3e44d5] focus:border-[#3e44d5] block w-full p-2.5"
                            placeholder="Amount"
                            required
                            value={this.state.amount} 
                            onChange={(e) => this.setState({ amount: e.target.value ,error:{}})}
                        />
                        {this.state.error&&this.state.error.amount&&<div className=' text-red-500'>{this.state.error.amount&&this.state.error.amount?this.state.error.amount:""}</div>
}                    </div>






                   {/* {imgUrl &&<img src={imgUrl} className=' h-10 w-10'/>}
                    <div className="mb-5">
                        <label htmlFor="file" className="block mb-2 text-sm font-medium text-gray-900">Upload File</label>
                        <input
                            type="file"
                            ref={this.amountInputRef}
                            id="file"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#3e44d5] focus:border-[#3e44d5] block w-full p-2.5"
                            onChange={(e) => this.handleFile(e)}
                            required
                        />
                    </div> */}
                    <button
                    onClick={(e)=>this.submit(e)}
                        type="submit"
                        className="text-white bg-[#3e44d5] hover:bg-[#3e44d5] focus:ring-4 focus:outline-none focus:ring-[#3e44d5] font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-[#3e44d5] dark:hover:bg-[#3e44d5] dark:focus:ring-[#3e44d5]"
                    >
                        Send Request
                    </button>
                </form>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { users
    } = state;
    return {
        users

    };
}
export default connect(mapStateToProps)(WithdrawAmount);


