
import React, { useEffect, useState } from 'react';
import { IoArrowBackOutline, IoCheckmarkCircleSharp } from 'react-icons/io5';

import { useDispatch, useSelector } from 'react-redux';
import { paymentAction } from '../../../_actions';
import moment from 'moment';

import Loader from '../../../components/Loader/Loader';
import ReactPaginate from 'react-paginate';

function WithdrawFundsHistory() {
    const dispatch = useDispatch();
    const selector = useSelector(state => state);
    const [size, setSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);

    const { Payment } = selector || {};
    const { getPaymentListList } = Payment || {};
    const { total, list } = getPaymentListList || {};




    useEffect(() => {
        const data = {
            fromDate: "",
            toDate: "",
            keyWord: "",
            sortBy: "",
            sortOrder: "",
            pageNo: currentPage + 1,
            size: size,
            type: "WITHDRAW",
        };
        dispatch(paymentAction.getClientPaymentList(data));
    }, [currentPage])



    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setCurrentPage(selectedPage);
    };

    return (
        <>
            <Loader loading={selector?.Payment?.loading} />
            <div className='bg-white'>
                <div className='h-12 w-full border bg-[#024CAA] rounded-b-3xl flex justify-between place-items-center px-4 gap-3'>
                    <div className='flex justify-start place-items-center gap-2'>
                        <IoArrowBackOutline className='text-white mb-1 text-2xl cursor-pointer' onClick={() => window.history.back()} />
                        <div className='font-semibold text-white cursor-pointer'>Withdraw Funds History</div>
                    </div>
                </div>
                <div className="p-4 h-screen overflow-y-auto mb-4">
                    {list && list.length > 0 ? (
                        list.map((item, index) => (
                            <div key={index} className="mb-4 w-full p-4 border rounded-lg shadow-sm bg-white">
                                <div className="flex justify-between items-center mb-2">
                                    <span className="text-sm text-gray-500">
                                        <p>
                                            {moment(item?.createdAt).format('YYYY MMM DD') ?? "N/A"}
                                        </p>
                                    </span>
                                    <span className="text-red-500 font-semibold flex items-center gap-1">

                                        <div>
                                            {item.status === 0 && (
                                                <span className="flex items-center text-yellow-500">
                                                    <svg className="mr-1" width="16" height="16" fill="currentColor" viewBox="0 0 24 24">
                                                        <circle cx="12" cy="12" r="10" />
                                                    </svg>
                                                    Pending
                                                </span>
                                            )}
                                            {item.status === 2 && (
                                                <span className="flex items-center text-red-500">
                                                    <svg className="mr-1" width="16" height="16" fill="currentColor" viewBox="0 0 24 24">
                                                        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                                        <line x1="8" y1="8" x2="16" y2="16" stroke="currentColor" strokeWidth="2" />
                                                        <line x1="16" y1="8" x2="8" y2="16" stroke="currentColor" strokeWidth="2" />
                                                    </svg>
                                                    Rejected
                                                </span>
                                            )}
                                            {item.status === 1 && (
                                                <span className="flex items-center text-green-500">
                                                    <svg className="mr-1" width="16" height="16" fill="currentColor" viewBox="0 0 24 24">
                                                        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                                        <path d="M9 12l2 2 4-4" stroke="currentColor" strokeWidth="2" fill="none" />
                                                    </svg>
                                                    Success
                                                </span>
                                            )}
                                        </div>

                                        {/* {item.status === 0 ? 'Pending' : item.status === 1 ? 'Success' : item.status === 2 ? 'Failed' : '-'} */}
                                    </span>
                                </div>
                                <div className="mb-2 flex justify-between place-items-center border-b pb-4 text-sm">
                                    <span className="text-xl font-bold"><span>₹ </span>{item?.amount || '0.00'}</span>
                                    <div>
                                        <div className="text-sm text-gray-700 mb-1">Order ID:</div>
                                        <div className="text-black text-xs">{item?._id || 'N/A'}</div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 text-sm text-gray-500 items-start">
                                    <div>
                                        <span>Request Type:</span>
                                        <p className="text-gray-700"><strong>{item.type || 'N/A'}</strong></p>
                                    </div>
                                    <div>
                                        <span>Deposit Mode:</span>
                                        <p><strong className="text-gray-700">{item.mode || 'N/A'}</strong></p>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="text-center text-gray-500">No items available</div>
                    )}

                    {total && total > size ? (
                        <nav className="relative z-0 flex mb-10 justify-end w-76">
                            <ReactPaginate
                                previousLabel={"Prev"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={Math.ceil(total / size)} // Ensure pageCount is an integer
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                pageClassName={"page-cls"}
                                activeClassName={"active"}
                            />
                        </nav>
                    ) : null}
                </div>
            </div>
        </>
    );
}

export default WithdrawFundsHistory;
