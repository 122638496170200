import React, { useEffect, useState } from 'react'
import { IoArrowBackOutline } from 'react-icons/io5'
import { useSelector, useDispatch } from 'react-redux'
import { jackportchartAction } from '../../_actions'
import moment from 'moment'
import Loader from '../../components/Loader/Loader'

function IndianJackpotResultHiastory() {
    const selector = useSelector(state => state)
    const dispatch = useDispatch()
    const currentDate = new Date().toISOString().split('T')[0];
    const [datedata, setdatedata] = useState(currentDate);

    useEffect(() => {
        let data = {
            filterDate: currentDate || "",
        };

        dispatch(jackportchartAction.calanderForJackpot(data))
    }, [currentDate]);
    const handleOnChange = (e) => {
        const selectedDate = e.target.value
        setdatedata(e.target.value);
        let data = {
            filterDate: selectedDate || "",
        };

        dispatch(jackportchartAction.calanderForJackpot(data))

    }
    const goBack = () => {
        window.history.back()
    }

    let { jackpotchart } = selector ? selector : {};
    let { getjackpotChartList, loading } = jackpotchart ? jackpotchart : {};
    let { gameResults, timingarray } = getjackpotChartList ? getjackpotChartList : {};
    return (
        <>
            <Loader loading={loading} />
            <div className='bg-white text-black min-h-screen'>
                <div className='h-12 w-full border bg-[#024CAA] rounded-b-3xl flex justify-start items-center px-4 gap-3'>
                    <IoArrowBackOutline className='text-white text-2xl cursor-pointer' onClick={goBack} />
                    <div className='font-semibold text-white cursor-pointer uppercase text-sm sm:text-base'>
                        Indian Jackpot Result History
                    </div>
                </div>

                {/* Date Picker Section */}
                <div className='flex flex-col md:flex-row justify-between items-center p-3 text-sm'>
                    <p className='text-gray-600 mb-2 md:mb-0'>Select Date</p>
                    <div>
                        <input
                            max={currentDate}
                            type='date'
                            onChange={(e) => handleOnChange(e)}
                            value={datedata}
                            className='bg-white px-3 py-1 uppercase rounded-full shadow text-xs md:text-sm'
                        />
                    </div>
                </div>

                {/* Results Section */}
                <div className="container mx-auto p-4 h-[calc(100vh-10rem)] overflow-y-auto">
                    {gameResults && gameResults.length > 0 ? gameResults?.map((item, index) => (
                        <div key={index} className="mb-4 p-4 border rounded-md shadow-md text-xs md:text-sm">
                            <div className="mb-2">
                                <strong>Date: </strong>
                                {moment(item.date).format('DD-MMM-YYYY')}
                            </div>
                            <div className="grid grid-cols-1 gap-2">
                                {item.results.map((result, idx) => (
                                    <div key={idx} className="p-2 border flex justify-between items-center rounded-md shadow-sm my-1">
                                        <div className="text-xs sm:text-sm md:text-base">
                                            {result?.gameTime}
                                        </div>
                                        <div className="text-xs sm:text-sm md:text-base">
                                            {result?.jodiNumber}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )):(<div className='text-center w-full'>No Data Found</div>)}
                </div>
            </div>
        </>

    )

}

export default IndianJackpotResultHiastory
