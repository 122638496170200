import React from "react";
import { connect } from "react-redux";
import { betActions, userActions } from "../../_actions";
import Loader from "../../components/Loader/Loader";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Sidebar from "../../components/Sidebar/Sidebar";
import { FaRegCalendar } from "react-icons/fa";
import { HiOutlineChevronRight } from "react-icons/hi2";
import { RxCountdownTimer } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";

class satteledBet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      declareMatchModal: false,
      setFmatchId: false,
      declareMatchDetails: {},
      currentCount: "Sport",
      noOfRecords: 20,
      index: 0,
      betType: 1,
      size: 10,
      optionRadioName: "MATCHED",
      endDate: "",
      startDate: "",
      sportsId: "",
      limit: "",
      offset: 0,
      pageNo: 1,
      size: 10,
    };
  }

  componentDidMount() {
    let data = {
      // "fromDate": moment(this.state.startDate).startOf('day').unix(),
      // "toDate": moment(this.state.endDate).endOf('day').unix(),
      // "keyWord": "",
      sortBy: "",
      sortOrder: "",
      pageNo: this.state.pageNo,
      size: this.state.size,
    };

    this.props.dispatch(betActions.betHistory(data));
    // this.props.dispatch(userActions.getBetsList(data));

    let gameReqData = {
      // "keyWord": "",
      pageNo: this.state.pageNo,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: "",
      toDate: "",
      size: 200,
    };

    this.props.dispatch(userActions.getGamesList(gameReqData));
  }

  getBatHistory = () => {
    let data = {
      fromDate: this.state.startDate,
      toDate: this.state.endDate,
      gameId: this.state.sportsId,
      sortBy: "",
      sortOrder: "",
      pageNo: this.state.pageNo,
      size: this.state.size,
    };

    this.props.dispatch(betActions.betHistory(data));
  };

  inputChangeStartDate = (e) => {
    this.setState({ startDate: e.target.value });
  };

  inputChangeEndDate = (e) => {
    this.setState({ endDate: e.target.value });
  };

  handleChangeSelect = (event) => {
    this.setState({ sportsId: event.target.value });
  };

  inputChangeCurrentCount = (event) => {
    this.setState({ currentCount: event });
  };

  handlePageClick = (data) => {
    console.log("data  ", data);
    this.setState({ index: data.selected });

    let offset = Math.ceil(data.selected * this.state.size);

    this.setState({ offset: offset });

    this.setState({ pageNo: data.selected + 1 }, () => {
      let pagination = {
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
        // "keyWord": "",
        gameId: this.state.sportsId,
        sortBy: "",
        sortOrder: "",
        pageNo: this.state.pageNo,
        size: this.state.size,
      };
      this.props.dispatch(betActions.betHistory(pagination));
    });
  };

  goBack = () => {
    window.history.back();
  };

  render() {
    let { bet, users } = this.props;
    let { betList, loading, betLoading, totalBet } = bet;
    let { totalGame, gameList } = users;
    const today = new Date().toISOString().split("T")[0];
    // const navigate = useNavigate();
    const handleNavigate = () => {
      this.props.history.push("/app/bidhistory");
    };

    const handleGameResultNavigate = () => {
      this.props.history.push(`/app/game-result`);
    };
    const handleStarlineResultHistory = () => {
      this.props.history.push(`/app/indian-starline-result-history`);

    }
    const handleStarlineResult = () => {
      this.props.history.push(`/app/indian-starline-history`);
    }
    const handleIndianJackpot = () => {
      this.props.history.push(`/app/indian-jackpot-result`);
    }
    const handleIndianJackpotResult = () => {
      this.props.history.push(`/app/indian-jackpot-bid-result`);

    }
    return (
      <>
        <div>
          <Loader loading={loading} />
        </div>
        <div className="bg-gray-200 w-full min-h-screen  ">
          <div className="h-12 w-full border bg-[#024CAA] rounded-b-3xl flex justify-start place-items-center px-4 gap-3">
            <div>
              <IoArrowBackOutline
                className="text-white mb-1 text-2xl cursor-pointer"
                onClick={this.goBack}
              />
            </div>
            <div className="font-semibold text-white cursor-pointer text-center ">
              My Bids
            </div>
          </div>

          <div className=" px-1 space-y-4 mx-auto ">

            <div className=" mt-10">
              <div className="max-w-full overflow-hidden overflow-y-auto">
                <div
                  className="Bid_History  shadow-md bg-gray-100 rounded-md cursor-pointer"
                  onClick={handleNavigate}
                >
                  <div className="flex justify-between place-items-center border-l-2 border-[#C11C84] py-3  ">
                    <div className="flex justify-start place-items-center gap-4 px-4">
                      <div className="">
                        <span className="group-hover:text-[#f97941] bg-[#024CAA] rounded-full flex justify-center place-items-center px-2 py-2">
                          <FaRegCalendar className="text-xl  text-white" />
                        </span>
                      </div>
                      <div className="">
                        <p className="font-semibold">Bid History</p>
                        <p className="text-gray-400 text-[12px]">
                          You can add fund to your wallet
                        </p>
                      </div>
                    </div>
                    <div className="px-2">
                      <span className="group-hover:text-[#f97941] bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1">
                        <HiOutlineChevronRight className="text-lg  text-black" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="Bid_History  shadow-md bg-gray-100 rounded-md my-2">
                  <div className="flex justify-between place-items-center border-l-2 border-[#C11C84] py-3  ">
                    <div className="flex justify-start place-items-center gap-4 px-4">
                      <div className="">
                        <span className="group-hover:text-[#f97941] bg-[#024CAA] rounded-full flex justify-center place-items-center px-2 py-2">
                          <RxCountdownTimer className="text-xl  text-white" />
                        </span>
                      </div>
                      <div className="">
                        <p className="font-semibold">Game Result</p>
                        <p className="text-gray-400 text-[12px] ">
                          You can View Your market result history{" "}
                        </p>
                      </div>
                    </div>
                    <div className="px-2">
                      <span
                        className="group-hover:text-[#f97941] bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1 cursor-pointer"
                        onClick={handleGameResultNavigate}
                      >
                        <HiOutlineChevronRight className="text-lg  text-black" />
                      </span>
                    </div>
                  </div>
                </div>{" "}
                <div className="Bid_History  shadow-md bg-gray-100 rounded-md my-2">
                  <div className="flex justify-between place-items-center border-l-2 border-[#C11C84] py-3  ">
                    <div className="flex justify-start place-items-center gap-4 px-4">
                      <div className="">
                        <span className="group-hover:text-[#f97941] bg-[#024CAA] rounded-full flex justify-center place-items-center px-2 py-2">
                          <FaRegCalendar className="text-xl  text-white" />
                        </span>
                      </div>
                      <div className="">
                        <p className="font-semibold">
                          Starline Bid History
                        </p>
                        <p className="text-gray-400 text-[12px]">
                          You can view starline history
                        </p>
                      </div>
                    </div>
                    <div className="px-2">
                      <span className="group-hover:text-[#f97941] bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1 cursor-pointer" onClick={handleStarlineResult}>
                        <HiOutlineChevronRight className="text-lg  text-black" />
                      </span>
                    </div>
                  </div>
                </div>{" "}
                <div className="Bid_History  shadow-md bg-gray-100 rounded-md ">
                  <div className="flex justify-between place-items-center border-l-2 border-[#C11C84] py-3  ">
                    <div className="flex justify-start place-items-center gap-4 px-4">
                      <div className="">
                        <span className="group-hover:text-[#f97941] bg-[#024CAA] rounded-full flex justify-center place-items-center px-2 py-2">
                          <FaRegCalendar className="text-xl  text-white" />
                        </span>
                      </div>
                      <div className="">
                        <p className="font-semibold">
                          Starline Result History
                        </p>
                        <p className="text-gray-400 text-[12px]">
                          You can view starline result
                        </p>
                      </div>
                    </div>
                    <div className="px-2">
                      <span className="group-hover:text-[#f97941] bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1 cursor-pointer" onClick={handleStarlineResultHistory}>
                        <HiOutlineChevronRight className="text-lg  text-black" />
                      </span>
                    </div>
                  </div>
                </div>{" "}
                {/* <div className="Bid_History  shadow-md bg-gray-100 rounded-md my-2 ">
                  <div className="flex justify-between place-items-center border-l-2 border-[#C11C84] py-3  ">
                    <div className="flex justify-start place-items-center gap-4 px-4">
                      <div className="">
                        <span className="group-hover:text-[#f97941] bg-[#024CAA] rounded-full flex justify-center place-items-center px-2 py-2">
                          <FaRegCalendar className="text-xl  text-white" />
                        </span>
                      </div>
                      <div className="">
                        <p className="font-semibold">
                          Jackpot Bid History
                        </p>
                        <p className="text-gray-400 text-[12px]">
                          You can view Jackpotackpot history
                        </p>
                      </div>
                    </div>
                    <div className="px-2">
                      <span className="group-hover:text-[#f97941] bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1 cursor-pointer" onClick={handleIndianJackpotResult}>
                        <HiOutlineChevronRight className="text-lg  text-black" />
                      </span>
                    </div>
                  </div>
                </div>{" "} */}
                {/* <div className="Bid_History  shadow-md bg-gray-100 rounded-md my-2">
                  <div className="flex justify-between place-items-center border-l-2 border-[#C11C84] py-3  ">
                    <div className="flex justify-start place-items-center gap-4 px-4">
                      <div className="">
                        <span className="group-hover:text-[#f97941] bg-[#024CAA] rounded-full flex justify-center place-items-center px-2 py-2">
                          <FaRegCalendar className="text-xl  text-white" />
                        </span>
                      </div>
                      <div className="">
                        <p className="font-semibold">
                          Jackpot Result History
                        </p>
                        <p className="text-gray-400 text-[12px]">
                          You can view Jackpotackpot result
                        </p>
                      </div>
                    </div>
                    <div className="px-2">
                      <span className="group-hover:text-[#f97941] bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1  cursor-pointer" onClick={handleIndianJackpot}>
                        <HiOutlineChevronRight className="text-lg  text-black" />
                      </span>
                    </div>
                  </div>
                </div> */}
                {/* <div className="inline-block min-w-full "> */}
                {/* <div className="overflow-hidden w-full "> */}
                {/* <div className='  flex flex-col  gap-5 border-2'>


                      {betList && betList.length > 0 && betList.map((element, index) => {

                        return (
                          <div className=' flex  w-full flex-col  text-xs border-gray-500 rounded-r-md border p-4'>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>S.No.</div>  {this.state.offset + index + 1}</div>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>Market</div>    {element && element.marketId && element.marketId.name ? element.marketId.name : "-"}</div>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>Game</div>{element && element.gameId && element.gameId.name ? element.gameId.name : "-"}</div>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>NUMBER</div>{element && element.betNumber ? element.betNumber : "-"}</div>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>Point</div> {element && element.amount ? element.amount : "-"}</div>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>BID TYPE</div>{element && element.isOpen ? "Open" : "Close"}</div>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>Date</div>{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("DD-MM-YYYY hh:mm A")}</div>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>Profit/Loss</div>  {element && element.betStatus == 0 && <div className=' text-yellow-500'>Pending</div>}
                              {element && element.betStatus == 1 && <div className=' text-green-800'>+{element?.winAmount}</div>}
                              {element && element.betStatus == 2 && <div className=' text-red-700'>-{element?.lossAmount}</div>}</div>
                          </div>
                        )
                      })}
                    </div> */}
                {
                  <nav className="relative z-0 flex mb-10 justify-end  w-76">
                    {/* {
                      totalBet && totalBet > 10 ?
                        <ReactPaginate
                          previousLabel={'Prev'}
                          nextLabel={'Next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={totalBet / 10}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={1}
                          onPageChange={this.handlePageClick}
                          containerClassName={'pagination'}
                          pageClassName={'page-cls'}
                          activeClassName={'active'}
                        />
                        : null} */}
                  </nav>
                }
                {/* </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users, bet } = state;
  return {
    users,
    bet,
  };
}

export default connect(mapStateToProps)(satteledBet);
