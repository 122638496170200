import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FaMobileAlt,
  FaUserAlt,
  FaLock,
  FaEyeSlash,
  FaEye,
} from "react-icons/fa";
import { userActions } from "../../_actions";
import { useHistory } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { FaRegEyeSlash } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import OtpTimer from "otp-timer";
import Timer from "otp-timer";
const Signup = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const history = useHistory();

  const [fieldslogin, setFieldsLogin] = useState({
    userNames: "",
    mobileNo: "",
    password: "",
    confirmPassword: "",
    OTP: "",
  });
  const [errorslogin, setErrorsLogin] = useState({});
  const [otpFeilds, setOtpFeilds] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsLogin((prevState) => ({ ...prevState, [name]: value }));
    setErrorsLogin((prevState) => ({ ...prevState, [name]: "" }));
  };

  const loginSubmit = () => {
    const { userNames, mobileNo, password, confirmPassword, OTP } = fieldslogin;
    const errors = {};

    // Validate inputs before OTP is sent
    // if (!otpFeilds) {
    if (!userNames.trim()) {
      errors.userNames = "Username is required";
    } else if (userNames.includes(" ")) {
      errors.userNames = "Username cannot contain spaces";
    } else if (userNames.length <= 6) {
      errors.userNames = "Username must be greater than 6 characters";
    }
    if (!mobileNo) {
      errors.mobileNo = "Mobile number is required";
    } else if (!/^\d{10}$/.test(mobileNo)) {
      errors.mobileNo = "Mobile number must be 10 digits";
    }

    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    }

    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    } else if (!/[A-Z]/.test(password)) {
      if (!confirmPassword) {
        errors.confirmPassword = "Confirm Password is required";
      } else if (password !== confirmPassword) {
        errors.confirmPassword = "Passwords do not match";
      }
      errors.password = "Password must contain at least one uppercase letter";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.password = "Password must contain at least one special character";
    }

    setErrorsLogin(errors);

    let formattedMobileNo = fieldslogin.mobileNo;
    if (Object.keys(errors).length === 0) {
      // Send OTP
      let data = {
        userName: fieldslogin.userNames,
        mobNo: formattedMobileNo,
        password: fieldslogin.password,
        confirmPassword: fieldslogin.confirmPassword,
      };
      dispatch(
        userActions.userRegister(data, () => history.push("/app/dashboard")),
      );
    }
    // } else {

    // if (!OTP) {
    //     errors.OTP = 'OTP is required';
    // } else if (!/^\d{6}$/.test(OTP)) {
    //     errors.OTP = 'OTP must be 6 digits';
    // }
    // setErrorsLogin(errors);
    // if (Object.keys(errors).length === 0) {
    //     let OtpData = {
    //         token: selector?.users?.registerUser?.userToken ? selector?.users?.registerUser?.userToken : null,
    //         otp: fieldslogin.OTP
    //     };
    //     dispatch(userActions.registerValidateOtp(OtpData, history));
    // }
    // }
  };

  const resendOtp = () => {
    const { userNames, mobileNo, password, confirmPassword, OTP } = fieldslogin;
    const errors = {};

    if (!otpFeilds) {
      if (!userNames.trim()) {
        errors.userNames = "Username is required";
      } else if (/\s/.test(userNames)) {
        errors.userNames = "Username cannot contain spaces";
      } else if (userNames.length <= 3) {
        errors.userNames = "Username must be greater than 3 characters";
      }

      if (!mobileNo) {
        errors.mobileNo = "Mobile number is required";
      } else if (!/^\d{10}$/.test(mobileNo)) {
        errors.mobileNo = "Mobile number must be 10 digits";
      }

      if (!password) {
        errors.password = "Password is required";
      } else if (password.length < 6) {
        errors.password = "Password must be at least 6 characters long";
      }

      if (!confirmPassword) {
        errors.confirmPassword = "Confirm Password is required";
      } else if (password !== confirmPassword) {
        errors.confirmPassword = "Passwords do not match";
      }

      setErrorsLogin(errors);

      let formattedMobileNo = fieldslogin.mobileNo.startsWith("91")
        ? `+${mobileNo}`
        : `+91${mobileNo}`;
      if (Object.keys(errors).length === 0) {
        // Send OTP
        let data = {
          userName: fieldslogin.userNames,
          mobNo: formattedMobileNo,
          password: fieldslogin.password,
          confirmPassword: fieldslogin.confirmPassword,
        };
        dispatch(userActions.userRegister(data, setOtpFeilds));
      }
    }
  };

  console.log("errorsloginerrorslogin", errorslogin);

  return (
    <>
      <Loader loading={selector?.users?.loading} />
      <div className="bg-black/80">
        <div className="relative">
          <div className="min-h-screen flex flex-col justify-center relative z-20">
            <div className="mx-auto w-full bg-no-repeat rounded-lg max-w-lg">
              <div className="mx-auto h-screen flex justify-center items-center flex-col bg-no-repeat py-0 rounded-xl relative overflow-hidden">
                <div className="w-full h-[30vh] bg-[#024CAA] absolute top-0">
                  <div className="border-l-4 border-white uppercase text-white m-4 ps-2 font-semibold">
                    <h2 className="text-center text-lg font-bold">MATKA BOOKIE</h2>
                  </div>
                </div>
                <div className="h-screen w-full place-items-center bg-white rounded-tl-[100px] absolute top-20">
                  <div className="mx-10 mt-20">
                    <img
                      src="/—Pngtree—sign up now_7152614.png"
                      className="w-40 h-40 mx-auto"
                      alt=""
                    />
                    <div className="w-full flex flex-col relative mb-2">
                      <input
                        type="number"
                        name="mobileNo"
                        id="mobileNo"
                        value={fieldslogin?.mobileNo || ""}
                        className="px-12 py-5 placeholder-blueGray-400 rounded-lg bg-[#EEEEEE] text-sm shadow-md w-full ease-linear transition-all duration-150"
                        placeholder="Mobile Number"
                        onChange={inputChange}
                        disabled={otpFeilds}
                      />
                      {errorslogin.mobileNo && (
                        <div className="text-red-500 text-left text-xs">
                          {errorslogin.mobileNo}
                        </div>
                      )}
                      <span className="absolute top-4 left-4 w-7 h-8 bg-[#024CAA] flex justify-center place-items-center rounded-lg">
                        <FaMobileAlt className="text-white" />
                      </span>
                    </div>
                    <div className="w-full flex flex-col relative mb-2">
                      <input
                        type="text"
                        name="userNames"
                        id="userNames"
                        value={fieldslogin?.userNames || ""}
                        className="px-12 py-5 placeholder-blueGray-400 rounded-lg bg-[#EEEEEE] text-sm shadow-md w-full ease-linear transition-all duration-150"
                        placeholder="User Names"
                        onChange={inputChange}
                        autoComplete="off"
                        disabled={otpFeilds}
                      />
                      {errorslogin.userNames && (
                        <div className="text-red-500 text-left text-xs">
                          {errorslogin.userNames}
                        </div>
                      )}
                      <span className="absolute top-4 left-4 w-7 h-8 bg-[#024CAA] flex justify-center place-items-center rounded-lg">
                        <FaUserAlt className="text-white" />
                      </span>
                    </div>
                    <div className="w-full flex flex-col relative mb-2">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        value={fieldslogin?.password || ""}
                        className="px-12 py-5 placeholder-blueGray-400 rounded-lg bg-[#EEEEEE] text-sm shadow-md w-full ease-linear transition-all duration-150"
                        placeholder="Password"
                        onChange={inputChange}
                        disabled={otpFeilds}
                      />
                      {errorslogin.password && (
                        <div className="text-red-500 text-left text-xs">
                          {errorslogin.password}
                        </div>
                      )}
                      <span className="absolute top-4 left-4 w-7 h-8 bg-[#024CAA] flex justify-center place-items-center rounded-lg">
                        <FaLock className="text-white" />
                      </span>
                      <span
                        className="absolute top-4 right-4 w-7 h-8 flex justify-center place-items-center cursor-pointer"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <FaEyeSlash className="text-gray-600" />
                        ) : (
                          <FaEye className="text-gray-600" />
                        )}
                      </span>
                    </div>
                    <div className="w-full flex flex-col relative mb-2">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        id="confirmPassword"
                        value={fieldslogin?.confirmPassword || ""}
                        className="px-12 py-5 placeholder-blueGray-400 rounded-lg bg-[#EEEEEE] text-sm shadow-md w-full ease-linear transition-all duration-150"
                        placeholder="Confirm Password"
                        onChange={inputChange}
                        disabled={otpFeilds}
                      />
                      {errorslogin.confirmPassword && (
                        <div className="text-red-500 text-left text-xs">
                          {errorslogin.confirmPassword}
                        </div>
                      )}
                      <span className="absolute top-4 left-4 w-7 h-8 bg-[#024CAA] flex justify-center place-items-center rounded-lg">
                        <FaLock className="text-white" />
                      </span>
                      <span
                        className="absolute top-4 right-4 w-7 h-8 flex justify-center place-items-center cursor-pointer"
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        {showConfirmPassword ? (
                          <FaEyeSlash className="text-gray-600" />
                        ) : (
                          <FaEye className="text-gray-600" />
                        )}
                      </span>
                    </div>
                    {otpFeilds && (
                      <div className="w-full flex flex-col relative mb-2">
                        <input
                          type="number"
                          name="OTP"
                          id="OTP"
                          value={fieldslogin?.OTP || ""}
                          className="px-12 py-5 placeholder-blueGray-400 rounded-lg bg-[#EEEEEE] text-sm shadow-md w-full ease-linear transition-all duration-150"
                          placeholder="Enter 7 Digit OTP"
                          onChange={inputChange}
                        />
                        {errorslogin.confirmPassword && (
                          <div className="text-red-500 text-left text-xs">
                            {errorslogin.confirmPassword}
                          </div>
                        )}
                        <span className="absolute top-4 left-4 w-7 h-8 bg-[#024CAA] flex justify-center place-items-center rounded-lg">
                          <FaLock className="text-white" />
                        </span>
                      </div>
                    )}

                    <div className="flex flex-col items-center">
                      <button
                        type="button"
                        onClick={loginSubmit}
                        className="flex justify-center items-center w-[80%] py-2 bg-[#024CAA] text-white font-semibold text-lg rounded-full mt-5"
                      >
                        {/* <span className="tracking-wider">{!otpFeilds ? 'Register' : 'Register & Login'}</span> */}
                        <span className="tracking-wider">Register & Login</span>
                      </button>
                      {/* {otpFeilds &&
                                                <div className="flex  flex-col w-fit p-2 rounded-lg active:scale-105 bg-[#024CAA] items-center mt-5">
                                                    <Timer
                                                        textColor={"#ffffff"}
                                                        seconds={30}
                                                        // minutes={2}
                                                        ButtonText="Resend Otp"
                                                        buttonColor="#ffffff"
                                                        background={"#e2a913"}
                                                        
                                                        resend={resendOtp}
                                                        text="Time Left :"
                                          
                                                        isDisabled={false}
                                                    // Pass the text color prop
                                                    />
                                                </div>

                                            } */}
                    </div>
                    <div className="flex justify-center pt-6">
                      <p className="pr-2">Click Here To</p>
                      <Link
                        className="text-[#e2a913] text-base font-semibold"
                        to={"/login"}
                      >
                        Login
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
