import React from "react";
import { connect } from "react-redux";
import { alertActions, userActions } from "../../_actions";
import { IoCaretBackCircle } from "react-icons/io5";
import { FaCalendarAlt } from "react-icons/fa";
import Loader from "../../components/Loader/Loader";
import moment from "moment";
import { IoArrowBack } from "react-icons/io5";
import Select from "react-select";
import { IoMdTrash } from "react-icons/io";
import ConfirmationModel from "../../components/ConfirmationModel";
import SubmitTotalData from "../Submit Total Data/SubmitTotalData";
import HeaderWallet from "../Wallet/HeaderWallet";
import CustomSlider from "../../components/SliderHeader/CustomSlider";
import BetPlaceModal from "../../components/Bet Place Modal/BetPlaceModal";
class HalfSangamB extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gameOne: "",
      gameTwo: "",
      gameThree: "",
      gameFour: "",
      gameItem: {},
      activeTab: 1,
      editStake: false,
      fieldStake: {},
      errorStake: {},
      fieldMarket: {},
      errorMarket: {},
      isModelOpen: false,
      selectMode: "em",
      option: [],
      optionss: [],
      Point: null,
      Pana: {},
      arrayofBets: [],
      timeLeft: "",
      currentTime: moment().format("hh:mm:ss A"),
      ank: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
      data: {
        limit: 10,
        pageno: 1,
      },
    };
  }
  componentDidMount() {
    this.timerInterval = setInterval(this.updateTimeLeft, 1000);
    let data = {
      marketId: this.props.match.params.marketId,
    };
    this.props.dispatch(
      userActions.getGamesByGamesIdForUser({
        gameId: this.props.match.params.gameId,
      }),
    );
    this.props.dispatch(userActions.getMarketByMarketId(data));
    this.props.dispatch(userActions.getProfile());
    let objdata = {
      gameId: this.props.match.params.gameId,
      marketId: data.marketId,
    };
    this.props.dispatch(userActions.todayBets(objdata));


    const queryParams = new URLSearchParams(this.props.location.search);
    const isJackpot = queryParams.get('isJackpot')
    const isStarLine = queryParams.get('isStarLine')

    let simpleGameData = {
      "isJackpot": isJackpot,
      "isStarLine": isStarLine
    }
    this.props.dispatch(userActions.placebetMarketListforSimplGames(simpleGameData))


  }
  componentWillUnmount(prevProps) {
    clearInterval(this.timerInterval);
  }
  componentDidUpdate(prevProps) {
    if (prevProps?.users?.marketDetails !== this.props?.users?.marketDetails) {
      this.processMarketDetails();
    }
  }

  processMarketDetails = () => {
    let { users } = this.props;
    let { marketDetails } = users;

    if (marketDetails?.numList) {
      let options =
        marketDetails.numList
          .flat(Infinity) // Flatten the array if it contains nested arrays
          .map((e) => ({ value: e, label: e })) || // Map each item to an object with value and label
        []; // Default to an empty array if numList is undefined or null

      let anks =
        this.state.ank.map((e) => ({ value: e, label: e })) || // Map each item to an object with value and label
        [];
      this.setState({ option: options, ank: anks });
    }
  };
  updateTimeLeft = () => {
    const { users } = this.props;
    const { gameData } = users;
    const { list, jodiNumber, openNumber, closeNumber } = gameData
      ? gameData
      : {};
    const { openTime, closingTime } = list ? list : {};

    const openingTime = moment()
      .hours(parseInt(openTime && openTime / 3600))
      .minutes(parseInt((openTime && openTime % 3600) / 60))
      .seconds(parseInt(openTime && openTime % 60))
      .milliseconds(0);

    const closingTimes = moment()
      .hours(parseInt(closingTime && closingTime / 3600))
      .minutes(parseInt((closingTime && closingTime % 3600) / 60))
      .seconds(parseInt(closingTime && closingTime % 60))
      .milliseconds(0);

    const currentTime = moment();
    const timeLeft = closingTimes.diff(currentTime);

    if (timeLeft <= 0) {
      clearInterval(this.timerInterval);
      this.setState({ timeLeft: "00:00:00" });
    } else {
      const duration = moment.duration(timeLeft);
      const hours = String(duration.hours()).padStart(2, "0");
      const minutes = String(duration.minutes()).padStart(2, "0");
      const seconds = String(duration.seconds()).padStart(2, "0");

      this.setState({
        timeLeft: `${hours}:${minutes}:${seconds}`,
        currentTime: currentTime.format("hh:mm:ss A"),
      });
    }
  };

  handleResponseCasino = () => {
    this.props.history.push("/app/satta-events-id");
  };

  handleSidebar = (data) => {
    console.log("datagameItemgameItemgameItem", data);
    this.setState({ gameItem: data });
  };
  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  setEditStakeOpen = () => {
    this.setState({ editStake: !this.state.editStake });
  };

  handleStakeChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let { fieldStake, errorStake } = this.state;
    fieldStake[name] = parseFloat(value) >= 0 ? value : 0;
    errorStake[name] = "data not found";
    console.log("value  ", value);

    let sum = 0;

    for (const key in fieldStake) {
      if (fieldStake.hasOwnProperty(key)) {
        sum += parseInt(fieldStake[key]);
      }
    }

    this.setState({ fieldStake, errorStake, totalPoint: sum });
  };

  handleChangeSelect = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let { fieldMarket, errorStake } = this.state;
    fieldMarket[name] = value;
    errorStake[name] = "data not found";
    this.setState({ fieldMarket, errorStake });
    this.setState({
      errorStake: {
        ...this.state.errorStake,
        market: "",
      },
    });
  };

  placeBet = () => {
    this.props.dispatch(
      userActions.getGamesByGamesIdForUser({
        gameId: this.props.match.params.gameId,
      }),
    );
    let { users } = this.props;
    let { marketDetails, getProfile, gameData, ListForSimpleGames } = users;
    let { marketListForSimpleGames } = ListForSimpleGames;

    let { list, jodiNumber } = gameData ? gameData : {};
    let { openTime, closingTime } = list ? list : {};
    let { wallet } = getProfile ? getProfile : {};

    let { min, max } = marketDetails ? marketDetails : {};
    if (this.state.arrayofBets && this.state.arrayofBets.length == 0) {
      this.props.dispatch(alertActions.error("Attention", `No bets placed`));
      return;
    }

    if (this.state.arrayofBets.length !== 0) {
      for (let i = 0; i < this.state.arrayofBets.length; i++) {
        if (this.state.arrayofBets[i]?.amount < min) {
          this.props.dispatch(
            alertActions.error(
              "Attention",
              `Bet amount should be more than ${min}`,
            ),
          );
          return;
        } else if (this.state.arrayofBets[i]?.amount > max) {
          this.props.dispatch(
            alertActions.error(
              "Attention",
              `Bet amount should be less than ${max}`,
            ),
          );
          return;
        }
      }
    }
    const openingTime = moment()
      .hours(parseInt(openTime && openTime / 3600))
      .minutes(parseInt((openTime && openTime % 3600) / 60))
      .seconds(parseInt(openTime && openTime % 60))
      .milliseconds(0);

    const OpenBetTime = openingTime.format("hh:mm A");

    const closingTimes = moment()
      .hours(parseInt(closingTime && closingTime / 3600))
      .minutes(parseInt((closingTime && closingTime % 3600) / 60))
      .seconds(parseInt(closingTime && closingTime % 60))
      .milliseconds(0);

    const CloseBetTime = closingTimes.format("hh:mm A");

    const getCurrentTime = () => {
      const now = moment();
      return now.format("hh:mm:A");
    };
    const currentTimeApis = getCurrentTime();
    const isBefore = moment(currentTimeApis, "hh:mm A").isBefore(
      moment(CloseBetTime, "hh:mm A"),
    );

    if (!isBefore) {
      this.props.dispatch(
        alertActions.error(
          "Attention",
          `Bets are closed, Betting time is ${CloseBetTime}`,
        ),
      );
      return;
    }
    if (jodiNumber?.toString().length == 2) {
      this.props.dispatch(
        alertActions.error("Attention", `Result is Declared`),
      );
      return;
    }


    function getIdForBetNumber(betNumbers) {

      let betNumber = String(betNumbers)

      const data = marketListForSimpleGames || [];

      if (!betNumber || typeof betNumber !== "string") return null;

      const cleanedNumber = betNumber.replace("-", "");

      // Condition: Single Digit
      if (cleanedNumber.length === 1) {
        return data.find(item => item.name === "Single Digit")._id;
      }

      // Condition: Jodi
      if (cleanedNumber.length === 2) {
        return data.find(item => item.name === "Jodi")._id;
      }

      // Condition: Triple Digit
      if (cleanedNumber.length === 3) {
        const digits = cleanedNumber.split("");
        const uniqueDigits = new Set(digits);

        if (uniqueDigits.size === 1) {
          // Triple Pana
          return data.find(item => item.name === "Triple Pana")._id;
        } else if (uniqueDigits.size === 3) {
          // Single Pana
          return data.find(item => item.name === "Single Pana")._id;
        } else {
          // Double Pana
          return data.find(item => item.name === "Double Pana")._id;
        }
      }

      // Condition: Half Sangam or Full Sangam
      if (betNumber.includes("-")) {
        const parts = betNumber.split("-");
        if (parts.length === 2) {
          if ((parts[0].length === 3 && parts[1].length === 1) || (parts[0].length === 1 && parts[1].length === 3)) {
            // Half Sangam (A)
            return data.find(item => item.name === "Half Sangam (A)")._id;
          } else if (parts[0].length === 3 && parts[1].length === 3) {
            // Full Sangam
            return data.find(item => item.name === "Full Sangam")._id;
          }
        }
      }

      return null;
    }

    let array = this?.state?.arrayofBets.map(e => ({
      ...e,
      parentMarketId: getIdForBetNumber(e?.betNumber)
    }));




    // console.log("asdkjlbsaldkjsabndkljsand======>")

    if (this.handleValid()) {
      let reqData = {
        marketId: this.props.match.params.marketId,
        gameId: this.props.match.params.gameId,
        bets: array,
        isOpen: true,
        parentId: getProfile?.parentId || null,
        upline: getProfile?.upline || [],
      };

      this.props.dispatch(userActions.placeBet(reqData, this.props));
    }
  };

  handleValid = () => {
    let valid = true;
    let { fieldStake, fieldMarket } = this.state;
    let errorMessage = {};
    let bets = Object.keys(fieldStake).map((key) => ({
      betNumber: key,
      amount: parseInt(fieldStake[key]),
    }));

    if (this.state.arrayofBets && this.state.arrayofBets.length == 0) {
      valid = false;
      errorMessage["bet"] = "Bets not found";
    }
    // console.log("--------------errorStake",errorMessage)
    this.setState({ errorStake: { ...errorMessage } });

    return valid;
  };

  setinLocal = () => {
    if (
      this.state.fieldMarket &&
      Object.keys(this.state.fieldMarket).length > 0
    ) {
      // window.localStorage.setItem("isOpen", this.state.fieldMarket["isOpen"])
      this.setState({
        viewall: true,
      });
    }
  };

  setSelectedOption = (e) => {
    this.setState({ selectedOption: e?.value });
  };

  handleChangeSelect = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      fieldMarket: {
        ...prevState.fieldMarket,
        [name]: value,
      },
      errorStake: {
        ...prevState.errorStake,
        [name]: "data not found",
        market: "",
        betNumber: "",
      },
    }));
    // window.localStorage.setItem("isOpen",value)
  };

  handleChange = (e) => {
    e.preventDefault();
    let { users } = this.props;
    let { gameData } = users;
    let { list, jodiNumber, openNumber, closeNumber } = gameData
      ? gameData
      : {};
    let { max, min } = list ? list : {};
    let { name, value } = e.target;
    let { fieldStake, errorStake } = this.state;
    fieldStake[name] = Number(value);

    this.setState({ fieldStake, errorStake });
  };
  addinArray = () => {
    const { amount } = this.state.fieldStake;
    let { users } = this.props;
    let betNumber = this.state.selectedOption;
    let { gameData } = users;
    let { list, jodiNumber, openNumber, closeNumber } = gameData
      ? gameData
      : {};
    let { max, min } = list ? list : {};
    // Checking if amount is within valid range
    if (Number(amount) > max) {
      this.props.dispatch(
        alertActions.warn(`Attention`, `Max bid amount is ${max}`),
      );
      return;
    }
    if (Number(amount) < min) {
      this.props.dispatch(
        alertActions.warn("Attention", `Min bid amount is ${min}`),
      );
      return;
    }
    if (
      betNumber !== undefined &&
      amount !== undefined &&
      this.state.selectedOption !== null
    ) {
      const betIndex = this.state.arrayofBets.findIndex(
        (bet) => bet.betNumber === betNumber,
      );

      if (betIndex !== -1) {
        this.setState((prevState) => {
          const updatedBets = [...prevState.arrayofBets];
          updatedBets[betIndex] = { ...updatedBets[betIndex], amount: amount };
          return {
            arrayofBets: updatedBets,
            fieldStake: {},
            // selectedOption: null
          };
        });
      } else {
        this.setState((prevState) => ({
          arrayofBets: [
            ...prevState.arrayofBets,
            { betNumber: betNumber, amount: amount },
          ],
          fieldStake: {},
          // selectedOption: null
        }));
      }
      // this.setState({ selectedOption: null })
    } else {
    }
  };
  letOpenModel = () => {
    this.setState({ isModelOpen: true });
  };
  letOpenModelClose = () => {
    this.setState({ isModelOpen: false });
  };

  handleDelete = (betNumber) => {
    this.setState((prevState) => ({
      arrayofBets: prevState.arrayofBets.filter(
        (bet) => bet.betNumber !== betNumber,
      ),
    }));
  };

  handleNewChange = (e) => {
    this.props.dispatch(
      userActions.getGamesByGamesIdForUser({
        gameId: this.props.match.params.gameId,
      }),
    );

    if (!this.state.fieldMarket["isOpen"]) {
      alert("Select Market Status");
      return;
    }
    let { users } = this.props;
    let { marketDetails, marketLoading, getProfile, gameData } = users;
    let { list, jodiNumber, openNumber, closeNumber } = gameData
      ? gameData
      : {};
    let { openTime, closingTime, betClosingTime } = list ? list : {};
    let { wallet } = getProfile ? getProfile : {};

    const openingTime = moment()
      .hours(parseInt(openTime && openTime / 3600))
      .minutes(parseInt((openTime && openTime % 3600) / 60))
      .seconds(parseInt(openTime && openTime % 60))
      .milliseconds(0);

    const OpenBetTime = openingTime.format("hh:mm A");

    const closingTimes = moment()
      .hours(parseInt(closingTime && closingTime / 3600))
      .minutes(parseInt((closingTime && closingTime % 3600) / 60))
      .seconds(parseInt(closingTime && closingTime % 60))
      .milliseconds(0);

    const CloseBetTime = closingTimes.format("hh:mm A");

    const getCurrentTime = () => {
      const now = moment();
      return now.format("hh:mm:A");
    };
    const currentTimeApis = getCurrentTime();

    const closeCloseBidTime = moment
      .duration(betClosingTime && betClosingTime, "seconds")
      .asMinutes();
    const MinutesBeforeClosing = closingTimes
      .clone()
      .subtract(closeCloseBidTime, "minutes");
    const CloseBetMinutesBeforeClosing = MinutesBeforeClosing.format("hh:mm A");
    const isBefore = moment(currentTimeApis, "hh:mm A").isBefore(
      moment(CloseBetMinutesBeforeClosing, "hh:mm A"),
    );
    // const isBefore = moment(currentTimeApis, "hh:mm A").isBefore(moment(CloseBetTime, "hh:mm A"));

    if (!isBefore) {
      this.props.dispatch(
        alertActions.error(
          "Attention",
          `Bets are closed, Betting time is ${CloseBetTime}`,
        ),
      );
      return;
    }
    if (jodiNumber?.toString().length == 2) {
      this.props.dispatch(
        alertActions.error("Attention", `Result is Declared`),
      );
      return;
    }

    const { name, value } = e.target;
    const { arrayofBets } = this.state;

    let betNumber = this.state.selectedOption;

    let { max, min } = list ? list : {};

    if (value.length > 5) {
      return;
    }

    const index = arrayofBets.findIndex((bet) => bet.betNumber === name);

    if (value.trim() === "") {
      // Remove bet if value is empty
      const updatedBets = arrayofBets.filter((bet) => bet.betNumber !== name);
      this.setState({ arrayofBets: updatedBets });
    } else if (index !== -1) {
      // Update existing bet with new amount
      const updatedBets = [...arrayofBets];
      updatedBets[index] = { ...updatedBets[index], amount: value };
      this.setState({ arrayofBets: updatedBets });
    } else {
      // Add new bet if not found
      const newBet = { betNumber: name, amount: value };
      this.setState((prevState) => ({
        arrayofBets: [...prevState.arrayofBets, newBet],
      }));
    }
  };

  handlePana = (selectedOption) => {
    // console.log(selectedOption)
    if (selectedOption) {
      this.setState({ Pana: selectedOption });
    }
  };
  handlePana2 = (selectedOption) => {
    // console.log(selectedOption)
    if (selectedOption) {
      this.setState({ Pana2: selectedOption });
    }
  };
  handlePoints = (event) => {
    const value = event.target.value;

    if (value == "") {
      this.setState({ Point: null });
    } else {
      this.setState({ Point: value });
    }
  };

  handleAdd = () => {
    const { fieldMarket, Pana2, Point, Pana, arrayofBets } = this.state;

    // Create the finalbet string
    const betValueOpen = Pana?.value && Pana?.value.toString();
    const betValueClose = Pana2?.value && Pana2?.value.toString();
    let finalbet = `${betValueClose}-${betValueOpen}`;

    if (Point && finalbet) {
      const newBet = { betNumber: finalbet, amount: Point };

      // Check if the betNumber already exists in arrayofBets
      const updatedArrayofBets = arrayofBets.some(
        (bet) => bet.betNumber === newBet.betNumber,
      )
        ? arrayofBets.map((bet) =>
          bet.betNumber === newBet.betNumber ? newBet : bet,
        )
        : [...arrayofBets, newBet]; // Add new bet if it doesn't exist

      this.setState({
        arrayofBets: updatedArrayofBets,
      });
    }
  };

  render() {
    const getCurrentTime = () => {
      const now = moment();
      return now.format("hh:mm:A");
    };
    const currentTimeApis = getCurrentTime();
    let { users } = this.props;
    let { marketDetails, marketLoading, loadingTick } = users;
    let { fieldStake, fieldMarket, errorStake, totalPoint } = this.state;

    let { loading, gameData, getProfile, todayBet } = users;
    let { wallet, blockedNumbers } = getProfile ? getProfile : {};
    const currentDate = moment();
    const formattedDate = currentDate.format("DD-MMM-YYYY");

    let { currentTime, list, jodiNumber, openNumber, closeNumber } = gameData
      ? gameData
      : {};
    let { openTime, closingTime, betOpenTime, betClosingTime } = list
      ? list
      : {};

    const openingTime = moment()
      .hours(parseInt(openTime && openTime / 3600))
      .minutes(parseInt((openTime && openTime % 3600) / 60))
      .seconds(parseInt(openTime && openTime % 60))
      .milliseconds(0);

    const closingTimes = moment()
      .hours(parseInt(closingTime && closingTime / 3600))
      .minutes(parseInt((closingTime && closingTime % 3600) / 60))
      .seconds(parseInt(closingTime && closingTime % 60))
      .milliseconds(0);

    const closeCloseBidTime = moment
      .duration(betClosingTime && betClosingTime, "seconds")
      .asMinutes();
    const closeOpenBidTime = moment
      .duration(betOpenTime && betOpenTime, "seconds")
      .asMinutes();

    const MinutesBeforeClosing = closingTimes
      .clone()
      .subtract(closeCloseBidTime, "minutes");
    const MinutesBeforeOpening = openingTime
      .clone()
      .subtract(closeOpenBidTime, "minutes");

    //formate
    const CloseBetMinutesBeforeClosing = MinutesBeforeClosing.format("hh:mm A");
    const CloseBetMinutesBeforeOpening = MinutesBeforeOpening.format("hh:mm A");

    //current time
    const currentTimeDuration = moment.duration(currentTime, "seconds");
    // const currentTimeApis = moment().startOf('day').add(currentTimeDuration).format("hh:mm A");
    // const currentTimeApis = moment.unix(currentTime).format("hh:mm A");

    const isBeforeClose = moment(currentTimeApis, "hh:mm A").isBefore(
      moment(CloseBetMinutesBeforeClosing, "hh:mm A"),
    );
    const isBeforeOpen = moment(currentTimeApis, "hh:mm A").isBefore(
      moment(CloseBetMinutesBeforeOpening, "hh:mm A"),
    );
    const totalAmount =
      this.state.arrayofBets && this.state.arrayofBets.length > 0
        ? this.state.arrayofBets.reduce(
          (total, bet) => total + parseInt(bet.amount),
          0,
        )
        : 0;
    const OpenBetTime = openingTime.format("hh:mm A");
    let { min, max } = marketDetails ? marketDetails : {};

    let selectedMarketBlock =
      blockedNumbers &&
      blockedNumbers.length > 0 &&
      blockedNumbers.filter(
        (e) => e.marketId == this.props.match.params.marketId,
      );
    let arrayToblock =
      selectedMarketBlock &&
      selectedMarketBlock.length > 0 &&
      selectedMarketBlock[0] &&
      selectedMarketBlock[0]?.numbers &&
      selectedMarketBlock[0]?.numbers?.map((e) => e?.numbers);
    let flatarrydatatoBlock =
      (arrayToblock &&
        arrayToblock.length > 0 &&
        arrayToblock.flat(Infinity)) ||
      [];

    // console.log(this.state.arrayofBets);

    return (
      <div className="w-full h-full relative overflow-y-hidden bg-gray-200">
        <Loader loading={marketLoading} />
        <Loader loading={loadingTick} />

        {/* <BetPlaceModal loading={loadingTick}/> */}
        <div className=" bg-[#024CAA] flex justify-between text-white place-items-center  text-3xl p-2 rounded-b-3xl relative">
          <div>
            <IoArrowBack
              className="  cursor-pointer "
              onClick={() => this.props.history.goBack()}
            />
          </div>
          <div className="uppercase text-base w-[70%] ">

            <CustomSlider title={`${this.props.match.params.gameName} - HALFSANGAM(B)`} />
          </div>
          <div className="text-sm"><HeaderWallet /></div>
        </div>
        {/* 
        <div className=" flex justify-between m-2 gap-2">
          <div
            className={`${
              this.state.selectMode == "em"
                ? "bg-[#024CAA] text-white"
                : "bg-[#fddbee] text-[#C11C84] border border-[#C11C84]"
            } text-center rounded p-2 flex-1`}
            onClick={() => this.setState({ selectMode: "em" })}
          >
            EASY MODE
          </div>
          <div
            className={`${
              this.state.selectMode == "sp"
                ? "bg-[#024CAA] text-white"
                : "bg-[#fddbee] text-[#C11C84] border border-[#C11C84]"
            } text-center rounded p-2 flex-1`}
            onClick={() => this.setState({ selectMode: "sp" })}
          >
            SPACIAL MODE
          </div>
        </div> */}

        <div className="w-full p-2 space-y-5">
          <div className="   w-full  rounded-lg space-y-5">
            <div className="flex  justify-center gap-2  items-center">
              <div className="flex  justify-center flex-col gap-2 w-full  items-center">
                {/* <div className=" justify-between flex items-center w-full">
                  <div className=" flex-1  text-base">
                    {" "}
                    Select Game Type :
                  </div>
                  <div className="flex flex-1 flex-col justify-center rounded-lg bg-white p-1 items-center">
                    <div className="flex items-center  w-full justify-around rounded-lg space-x-5">
                      <span className="flex w-full justify-around">
                        <label className="inline-flex items-center w-full">
                          <select
                            name="isOpen"
                            disabled={
                              (!isBeforeOpen && !isBeforeClose) ||
                              (openNumber !== null && closeNumber !== null)
                            }
                            value={
                              this.state.fieldMarket &&
                              this.state.fieldMarket["isOpen"]
                                ? this.state.fieldMarket["isOpen"]
                                : ""
                            }
                            onChange={this.handleChangeSelect}
                            className="form-select h-10  w-full text-gray-600  text-xs bg-white"
                          >
                            <option value="" disabled>
                              Select Market Status
                            </option>
                            <option
                              value="open-market"
                              disabled={openNumber !== null || !isBeforeOpen}
                            >
                              Open
                            </option>
                            <option
                              value="close-market"
                              disabled={closeNumber !== null || !isBeforeClose}
                            >
                              Close
                            </option>
                          </select>
                        </label>
                      </span>
                    </div>
                  </div>
                </div> */}

                <div className=" justify-between flex items-center w-full">
                  <div className=" flex-1  text-base">
                    {" "}
                    Select Open Ank :
                  </div>
                  <div className="flex flex-1 flex-col justify-center items-center">
                    <div className="flex items-center  w-full justify-around rounded-lg space-x-5">
                      {/* <Select
                        className=" w-full"
                        onChange={this.handlePana2}
                        options={this.state.ank}
                      /> */}
                      <Select
                        className="w-full text-center"
                        onChange={this.handlePana2}
                        options={this.state.ank}
                        placeholder='Open Ank'
                        isClearable // This allows clearing the selected option
                        onInputChange={(value, { action }) => {
                          if (action === 'input-change') {
                            // Allow only numbers while typing
                            const numericValue = value.replace(/\D/g, "");
                            return numericValue;
                          }
                          return value; // Allow backspace or clear
                        }}
                        value={this.state.selectedOption} // Bind the selected value to state
                      />


                    </div>
                  </div>
                </div>
                <div className=" justify-between flex items-center w-full">
                  <div className=" flex-1  text-base">
                    {" "}
                    Select Close Pana :
                  </div>
                  <div className="flex flex-1 flex-col justify-center  items-center">
                    <div className="flex items-center  w-full justify-around rounded-lg space-x-5">
                      <Select
                        className="w-full text-center"
                        onChange={this.handlePana}
                        options={this.state.option}
                        placeholder='Close Pana'
                        isClearable // This allows clearing the selected option
                        onInputChange={(value, { action }) => {
                          if (action === 'input-change') {
                            // Allow only numbers while typing
                            const numericValue = value.replace(/\D/g, "");
                            return numericValue;
                          }
                          return value; // Allow backspace or clear
                        }}
                        value={this.state.selectedOption} // Bind the selected value to state
                      />


                    </div>
                  </div>
                </div>

                <div className=" justify-between flex items-center w-full">
                  <div className=" flex-1  text-base">
                    {" "}
                    Enter Points :
                  </div>
                  <div className="flex flex-1 flex-col justify-center items-center">
                    <div className="flex items-center  w-full justify-around rounded-lg space-x-5">
                      <input
                        placeholder="Point"
                        type="number"
                        value={this.state.Point || ""}
                        onChange={(e) => this.handlePoints(e)}
                        className=" w-full  text-center border rounded p-1.5 border-gray-300"
                        onKeyDown={(e) => {
                          // Prevent typing a negative sign
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className=" justify-end flex items-center w-full">

                  <button
                    onClick={() => this.handleAdd()}
                    className=" w-[50%] text-white p-1.5 rounded bg-[#024CAA]"
                  >
                    Add
                  </button>

                </div>
              </div>
            </div>

            <div className="h-[57vh] overflow-hidden overflow-y-auto">
              {this.state.arrayofBets && this.state.arrayofBets && (
                <table className="min-w-full bg-white border border-gray-200">
                  <thead>
                    <tr className="border-b bg-[#024CAA] text-white border-gray-300 text-sm">
                      <th className="py-2 px-4 text-left">Half Sangam(B)</th>
                      <th className="py-2 px-4 text-left">Amount</th>
                      {/* <th className="py-2 px-4 text-left">Type</th> */}
                      <th className="py-2 px-4 text-left">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.arrayofBets &&
                      this.state.arrayofBets.length > 0 ? (
                      this.state.arrayofBets.map((bet, index) => (
                        <tr key={index} className="border-b border-gray-300">
                          <td className="py-2 px-4">{bet.betNumber}</td>
                          <td className="py-2 px-4">{bet.amount}</td>
                          {/* <td className="py-2 px-4">
                            {this.state.fieldMarket["isOpen"] == "open-market"
                              ? "Open"
                              : "Close"}
                          </td> */}
                          <td className="py-2 px-4">
                            <button
                              onClick={() => this.handleDelete(bet.betNumber)}
                              className="text-red-500  py-1 px-3 rounded"
                            >
                              <IoMdTrash />
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="py-2 px-4 text-center">
                          No bets available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>

            <div className=" absolute bottom-0 left-0 w-full border-t-2 border-[#C11C84]">
              {this.state.arrayofBets && this.state.arrayofBets && (
                <div className="grid grid-cols-2 items-center w-full bg-white p-1 py-3 -lg ">

                  <div>
                    <SubmitTotalData totalAmount={totalAmount > 0 && totalAmount ? totalAmount : '0'}
                      arrayofBets={this.state.arrayofBets && this.state.arrayofBets?.length} />
                  </div>
                  <button
                    onClick={() => this.letOpenModel()}
                    className="py-2 px-4 ml-4 bg-[#024CAA] hover:bg-[#A0186D] text-white font-semibold rounded-lg shadow-md transition-colors duration-200"
                  >
                    Submit
                  </button>
                </div>

              )}
            </div>

            {this.state.viewall && (
              <div>
                <div className="  text-black pb-14 px-1  gap-4 border-t h-[50vh] overflow-y-scroll overflow-hidden border-white pt-2  flex  flex-col">
                  <div className="pb-2  flex-col   flex  justify-center items-center gap-1">
                    {marketDetails?.numList &&
                      marketDetails.numList.map((elem, index) => {
                        return (
                          <div key={index}>
                            <div className="bg-[#3E44D5]  text-white md:text-sm text-xs  mb-1 p-2 rounded justify-center items-center flex">
                              {index} House
                            </div>
                            <div className="grid xl:grid-cols-12 lg:grid-cols-7  md:grid-cols-8 grid-cols-6 gap-1">
                              {/* <div className='grid xl:grid-cols-10 lg:grid-cols-7  md:grid-cols-8 grid-cols-5 gap-1'> */}
                              {elem &&
                                Array.isArray(elem) &&
                                elem.map((e) => {
                                  if (
                                    !flatarrydatatoBlock?.includes(
                                      e?.toString(),
                                    )
                                  ) {
                                    return (
                                      <div
                                        key={e}
                                        className="font-bold bg-[#3E44D5] rounded-lg p-2 flex flex-col m-0.5 justify-between items-center"
                                      >
                                        <div className="text-white flex justify-center items-center text-sm">
                                          {e}
                                        </div>
                                        <input
                                          name={e}
                                          value={
                                            this.state.arrayofBets.find(
                                              (bet) => bet.betNumber == e,
                                            )?.amount || ""
                                          }
                                          onChange={this.handleNewChange}
                                          className="md:w-12 w-10 md:text-sm text-xs rounded-md outline-none"
                                          type="number"
                                        />
                                      </div>
                                    );
                                  }
                                })}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* <table className="min-w-full bg-[#3e44d5] text-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b border-gray-200 bg-[#3e44d5] text-white text-left text-sm font-medium">Digit</th>
                <th className="py-2 px-4 border-b border-gray-200 bg-[#3e44d5] text-white text-left text-sm font-medium">Amt</th>
                <th className="py-2 px-4 border-b border-gray-200 bg-[#3e44d5] text-white text-left text-sm font-medium">Type</th>
                <th className="py-2 px-4 border-b border-gray-200 bg-[#3e44d5] text-white text-left text-sm font-medium">Time</th>
              </tr>
            </thead>
            <tbody>
              {todayBet && todayBet.length > 0 && todayBet.map((item, index) => (
                <tr key={item.id} className={index % 2 == 0 && "bg-white text-black"}>
                  <td className="py-2 px-4 border-b border-gray-200">{item?.betNumber}</td>
                  <td className="py-2 px-4 border-b border-gray-200">{item.amount}</td>
                  <td className="py-2 px-4 border-b border-gray-200">{item?.isOpen ? "Open" : "Close"}</td>
                  <td className="py-2 px-4 border-b border-gray-200">
                    {new Date(item?.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table> */}

          <ConfirmationModel
            type={fieldMarket["isOpen"] === "open-market" ? "Open" : "Close"}
            isModelOpen={this.state.isModelOpen}
            arrayOfBets={this.state.arrayofBets}
            walletAmount={wallet}
            letOpenModelClose={this.letOpenModelClose}
            placeBet={this.placeBet}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(HalfSangamB);
